/* eslint-disable */
import React, {  useState, useEffect } from "react";
import { connect } from "react-redux";
import { getDepartments, deleteDepartment, editDepartment, addDepartment, uploadFile, deleteFile } from "../../actions/departments";
import { getRegions } from "../../actions/users";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import RegionDepartmentsModal from "./RegionDepartmentsModal";
import styles from "./RegionDepartments.module.scss"
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose } from "react-icons/io";
import { getAllYunarmiansData, removeDataForPrint } from "../../actions/yunarmiyans";
import { createNotification } from "../../components/Notifications/Notifications";




const RegionDepartments = ({
  data,
  getDepartments,
  deleteDepartment,
  editDepartment,
  addDepartment,
  getRegions,
  regions,
  deleteFile,
  uploadFile,
  loading,
  removeDataForPrint,
  getAllYunarmiansData,
  profiles,
  history,
  accessRights,
  userRegions
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)


  useEffect(() => {
    getDepartments()
    getRegions()
  }, []);

  const openModal = (type, item) => () => {
    const readRights = accessRights.ac_read || (accessRights.ac_read_by_region && userRegions.includes(item?.regionId))
    
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(type==='ADD'||readRights)
    !readRights && type!=='ADD' && createNotification('error', 'Доступ к данному региональному отделению отсутствует')
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteDepartment = (id) => {
    deleteDepartment(id)
  }

  const acceptConfirmation = () => {
    handleDeleteDepartment(confirmation.id)
    setConfirmation(null)
  }


  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Реестр региональных отделений</div>
        <div className={styles.newsList}>
        <div className={styles.newsListHeader}>
            {(accessRights.ac_create || accessRights.ac_create_by_region) && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Отделения:</div>
            <div className={styles.superuser}>Кол-во местных отделений</div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
            {data &&
              data.map((a) => {
                const deleteRights = accessRights.ac_delete || (accessRights.ac_delete_by_region && userRegions.includes(a.regionId))
                return (
                  <div key={a.id} className={styles.tableRow}>
                    <div
                      className={styles.displayname}
                      onClick={openModal("EDIT", a)}
                    >
                      <span>
                        {a.name}
                      </span>
                    </div>
                    <div className={styles.superuser}>{a.count || 0}</div>
                    <div className={styles.actions}>
                      {deleteRights
                        ? <IoMdClose onClick={() => setConfirmation({text: a.name, id: a.id})}/>
                        : <div style={{height:'1.5rem'}}></div>
                      }
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      {confirmation && <Confirmation 
        confirmation={confirmation.text}
        acceptConfirmation={acceptConfirmation}
        rejectConfirmation={() => setConfirmation(null)}
      />}
      <RegionDepartmentsModal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        editDepartment={editDepartment}
        addDepartment={addDepartment}
        regions={regions}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        loading={loading}
        data={data}
        removeDataForPrint={removeDataForPrint}
        getAllYunarmiansData={getAllYunarmiansData}
        profiles={profiles}
        history={history}
        accessRights={accessRights}
        userRegions={userRegions}
        getDepartments={getDepartments}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.departments.region_departments,
  regions: state.users.regionList,
  loading : state.departments.loading,
  profiles: state.yunarmians.all_profiles,
  accessRights: state.auth.accessRights.find(i => i.name==='regional_departments')||{},
  userRegions: state.auth.userRegions
})

export default connect(mapStateToProps, {
  getDepartments,
  deleteDepartment,
  editDepartment,
  addDepartment,
  getRegions,
  uploadFile,
  deleteFile,
  removeDataForPrint,
  getAllYunarmiansData
})(
    RegionDepartments
);
