import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { getEquipmentCount, getEquipmentObject, deleteEquipmentObject, editEquipmentObject, addEquipmentObject, uploadImage, deleteImage, getEquipmentImage, removeEquipmentImage } from '../../actions/equipment'
import EquipmentModal from './EquipmentModal';
import styles from "./Equipment.module.css"
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import { Pagination } from '../../assets/common/Pagination/Pagination';
import Confirmation from '../../assets/common/Confirmation/Confirmation';
import { IoMdClose } from "react-icons/io";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import ItemsPerPage from '../../components/ItemsPerPage';



function Equipment({
  getEquipmentCount,
  getEquipmentObject,
  deleteEquipmentObject,
  editEquipmentObject,
  addEquipmentObject,
  uploadImage,
  deleteImage,  
  getEquipmentImage,
  removeEquipmentImage,
  pageCount,
  data,
  loading,
  accessRights,
  images,
  itemsOnPage,
  deleting=false
}) {
  
  const [numberPage, setNumberPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [sort, setSort] = useState("");


  useEffect(() => {
    getEquipmentCount()
    getEquipmentObject(numberPage+1, sort)
  }, [numberPage, itemsOnPage, sort])
  
  const openModal = (type, item) => () => {
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null)
    setIsModalOpen(false);
    removeEquipmentImage();
  };

  const handleDeleteDocument = (id) => {
    deleteEquipmentObject(id, numberPage+1)
  }
  
  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const acceptConfirmation = () => {
    handleDeleteDocument(confirmation.id)
    setConfirmation(null)
  }
  
  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

    return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Экипировка</div>
        <div className={styles.newsList}>
        <div className={styles.newsListHeader}>
        {accessRights.ac_create && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Экипировка</div>
          </div>
          <div className={styles.table}>
            {data &&
              data.map((a) => (
                <div key={a.id} className={styles.tableRow}>
                  <div
                    className={styles.displayname}
                    onClick={openModal("EDIT", a)}
                  >
                    <span>
                      {a.name}
                    </span>
                  </div>
                  <div className={styles.actions}>
                    {accessRights.ac_delete
                      ? <IoMdClose onClick={() => setConfirmation({text: a.name, id: a.id})}/>
                      : <div style={{height:'1.5rem'}}></div>
                    }
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.pagination}>
            <Pagination pageCount={pageCount} numberPage={numberPage} onPageChange={handlePageClick}/>
          </div>
          <div className={styles.controls}>
            <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
            </div>
            <ItemsPerPage/>
          </div>
        </div>
        
      </div>
      {confirmation && <Confirmation 
              confirmation={confirmation.text}
              acceptConfirmation={acceptConfirmation}
              rejectConfirmation={() => setConfirmation(null)}

      />}
      <EquipmentModal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        editObject={editEquipmentObject}
        addObject={addEquipmentObject}
        getEquipmentImage={getEquipmentImage}
        numberPage={numberPage}
        uploadImage={uploadImage}
        deleteImage={deleteImage}
        images={images}
        accessRights={accessRights}
        data={data}
        loading={loading}
        removeEquipmentImage={removeEquipmentImage}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
    pageCount: state.equipment.page_count,
    data: state.equipment.data,
    loading: state.equipment.loading,
    images: state.equipment.image,
    lastUpdate: state.schedule.lastUpdate,
    itemsOnPage: state.auth.itemsOnPage,
    accessRights: state.auth.accessRights.find(i => i.name==='equipment')||{}
  })

export default connect(mapStateToProps, {getEquipmentCount, getEquipmentObject, deleteEquipmentObject, editEquipmentObject, uploadImage, addEquipmentObject, deleteImage, getEquipmentImage, removeEquipmentImage})(Equipment)