import React, { useState, useRef } from "react";
import styles from "./Badges.module.css";
import Modal from "react-responsive-modal";
import Konva from "../../components/Konva/Konva";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import { IoIosArrowDown } from "react-icons/io";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getImages, deleteImage, updateBadge, uploadImages, createBadge, deleteBadge } from "../../actions/badges";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { BACKEND_DOMAIN, textPlugs } from "../../utils/const";
import { IoIosClose, IoIosColorWand } from "react-icons/io";
import {InputFile} from '../../assets/common/InputFile/InputFile'
import FileLabel from '../../components/FileLabel/FileLabel'
import { BiText, BiFontColor, BiBold, BiAlignLeft, BiAlignMiddle, BiAlignRight } from "react-icons/bi";
import { CompactPicker  } from 'react-color';
import { generateId } from "../../utils/generateId";
import Confirmation from '../../assets/common/Confirmation/Confirmation';


const settingGroups = [
  "Выберите фон",
  "Выберите изображения",
  "Текстовые поля",
];

const alignItems = ['left', 'center', 'right']

const sizeItems = [
  {name: '7 x 10', value: {width: '525px', height: '750px'}},
  {name: '9 x 14', value: {width: '483px', height: '750px'}}
]

export default function BadgeModal({ data, closeModal }) {
  
  const [_data, setData] = useState({...data.conf});
  const [badgeId] = useState(data.id);
  const [openBlock, setOpenBlock] = useState(0);
  const [confirmation, setConfirmation] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [saveBadge, setSaveBadge] = useState(false)
  const [badgeSizes, setBadgeSizes] = useState(data.id ? data.conf.size || {width: '525px', height: '750px'} : null)

  const dispatch = useDispatch();
  const { images, loading } = useSelector((state) => state.badges);

  useEffect(() => {
    let type;
    openBlock === 0 && (type = "background");
    openBlock === 1 && (type = "other");
    type && dispatch(getImages(type));
  }, [openBlock]);

  useEffect(() => {
    if (saveBadge && !selectedComponent) {
      saveChanges()
      setSaveBadge(false)
    }
  }, [saveBadge, selectedComponent])
  

  const handleImageClick = (clickedImage) => {
    const components = [..._data.components]
    const idsList = components.map(item => item.id)
    let newComponentsList
    if (idsList.includes(clickedImage.id)) {
      newComponentsList = components.filter(it => it.id !== clickedImage.id)
    } else {
      const newComp = {
        type: 'image',
        data: `/${clickedImage.path}/${clickedImage.name}`,
        id: clickedImage.id,
        shape: {
          x: 10,
          y: 10,
          width: 100,
          height: 100
      }}
      newComponentsList = [...components, newComp]
    }
    setData(prev => ({...prev, components: newComponentsList}))
  };

  const handlebackgroundClick = (link) => {
    setData({ ..._data, background: link });
  };

  const changeOpenedBlock = (ind) => {
    const val = ind === openBlock ? null : ind;
    setOpenBlock(val);
  };

  const renderBlock = (groupName, ind) => {
    return (
      <div
        key={ind}
        className={styles.settingsGroup}
      >
        <div
          onClick={() => changeOpenedBlock(ind)}
          className={styles.blockLabel}
        >
          {groupName}
          <IoIosArrowDown
            style={openBlock === ind ? { transform: "rotate(180deg)" } : null}
          />
        </div>
        {openBlock === ind && (
          <div className={styles.groupWrap}>{switchBlock(ind)}</div>
        )}
      </div>
    );
  };


  const switchBlock = (ind) => {
    switch (ind) {
      case 0:
        case 1:
          return (
            <ImagesField
              key={ind}
              loading={loading}
              images={images}
              type={ind}
              data={_data}
              imageClick={handleImageClick}
              backgroundClick={handlebackgroundClick}
            />
          );
      case 2:
        return <TextsField
                  data={_data}
                  setData={setData}
                  badgeSizes={badgeSizes}
               />;
      default:
        return;
    }
  };

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  const saveChanges = async () => {
    const canvas = document.getElementsByTagName('canvas')[0]
    const dataURL = canvas.toDataURL("image/png")
    const blob = dataURLtoBlob(dataURL)
    const file = new File([blob], 'myfile.png', {type:"image/png"})
    const size = badgeSizes 
    const data = { conf: {..._data, size} }
    
    if (badgeId == 0) {
      await dispatch(createBadge(data, file))
    } else {
      await dispatch(updateBadge(badgeId, data, file))
    }

    closeModal();
  }

  const hadnleDeleteBadge = (badgeId) => {
    dispatch(deleteBadge(badgeId));
    closeModal();
  }

  const acceptConfirmation = () => {
    if (confirmation.type == 'delete') {
      hadnleDeleteBadge(badgeId);
    }

    closeModal();
  }

  const outsideModalClick = () => {
    !badgeSizes
      ? closeModal()
      : setConfirmation({text: 'отменить изменения', type: 'cancel'})
  }

  return (
    <>
    <Modal
      open={data}
      onClose={outsideModalClick}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeButton,
      }}
    >
      {badgeSizes
        ? <>
            {confirmation &&
              <Confirmation 
                confirmation={confirmation.text}
                title="Вы уверены что хотите"
                acceptConfirmation={acceptConfirmation}
                rejectConfirmation={() => setConfirmation(null)}
            />}
            <div className={styles.container}>
              <div>
                {_data &&
                <Konva
                  badgeSizes={badgeSizes}
                  data={_data}
                  setData={setData}
                  selectedId={selectedComponent}
                  setSelectedId={setSelectedComponent}
                />}
                <div className={styles.buttonsBlock}>
                  <ButtonRed 
                  style={{margin:'.5rem auto', padding: '.5rem 1rem', fontSize: '1rem'}}
                  onClick={() => setSaveBadge(true)}>Сохранить</ButtonRed>
                  <ButtonRed 
                  style={{margin:'.5rem auto', padding: '.5rem 1rem', fontSize: '1rem'}}
                  onClick={() => setConfirmation({text: 'отменить изменения', type: 'cancel'})}>Отменить изменения</ButtonRed>
                  {badgeId !== 0 ? <ButtonRed 
                  style={{margin:'.5rem auto', padding: '.5rem 1rem', fontSize: '1rem'}}
                  onClick={() => setConfirmation({text: 'удалить бейдж', type: 'delete'})
                    }>Удалить</ButtonRed> : <></>}
                </div>
              </div>
              <div className={styles.settings}>{settingGroups.map(renderBlock)}</div>
            </div>
          </>
        : <div className={styles.sizeSelectBlock}>
            <h3>Выберите размер:</h3>
            {sizeItems.map(item =>
            <div onClick={() => setBadgeSizes(item.value)}>{item.name}</div>
            )}
            {/* <ButtonRed>Подтвердить</ButtonRed> */}
          </div> 
      }
    </Modal>
    </>
  );
}


const ImagesField = ({
  images,
  type,
  data,
  loading,
  imageClick,
  backgroundClick,
}) => {
  
  const [addImages, setAddImages] = useState([])
  
  const dispatch = useDispatch()

  const setImages = (e) => {
    setAddImages([...addImages, ...e.target.files])
  }

  const deleteAddedImage = (ind) => {
    const _addImages = [...addImages]
    _addImages.splice(ind, 1)
    setAddImages(_addImages)
  }

  const handleAddImages = () => {
    dispatch(uploadImages(addImages, type))
    setAddImages([])
  }

  const handleDeleteImage = (image) => (e) => {
    e.stopPropagation()
    dispatch(deleteImage(image))
  }

  return (
    <>
      {loading ? (
        <Loader top="40%" left="40%" />
      ) : (
        <>
          <div className={styles.imagesBlock}>

            {type
                ? <div
                    key={`/img/photoplace.png`}
                    style={{
                      border: `1px solid ${
                        data.components.map((comp) => comp.data).includes(`/img/photoplace.png`) ? "rgb(28 133 124)" : "#d5d2d2"
                      }`,
                      width: "150px",
                      height: "150px",
                    }}
                    onClick={() => (imageClick({
                      name: 'photoplace.png',
                      path: 'img',
                      id: 9999
                    }))}
                  >
                    <img src={`/img/photoplace.png`} alt="o_O" />
                  </div>
                : <div
                    style={{
                      border: `1px solid ${
                        data.background==='/bg_white.jpg' ? "rgb(28 133 124)" : "#d5d2d2"
                      }`,
                      width: "150px",
                      height: "200px",
                    }}
                    onClick={() => (backgroundClick('/bg_white.jpg'))}
                  >
                    <img src={`/bg_white.jpg`} alt="o_O" />
                  </div>
            }

            {images &&
              images.map((img) => {
                const link = `/${img.path}/${img.name}`;
                const bordered =
                  (type &&
                    data.components.map((comp) => comp.data).includes(link)) ||
                  data.background === link;
                return (
                  <div
                    key={link}
                    style={{
                      border: `1px solid ${
                        bordered ? "rgb(28 133 124)" : "#d5d2d2"
                      }`,
                      width: type ? "150px" : "150px",
                      height: type ? "150px" : "200px",
                    }}
                    onClick={() => (type ? imageClick(img) : backgroundClick(link))}
                  >
                    <IoIosClose onClick={handleDeleteImage(img)}/>
                    <img src={`${BACKEND_DOMAIN || ''}${link}`} alt="o_O" />
                  </div>
                );
              })}
          </div>
          <div className={styles.addImageBlock}>
              <div className={styles.addedImages}>
                {addImages.map((file, ind) =>
                <FileLabel
                  key={ind}
                  fileName={file.name}
                  fileLink={URL.createObjectURL(file)}
                  onDelete={() => deleteAddedImage(ind)}
                  imageMode
                  style={{margin: '.5rem 0 0 24px'}}
                />)}
              </div>
              <div className={styles.imagesButtonsBlock}>
                {!!addImages.length && 
                  <button onClick={handleAddImages}>
                    Загрузить
                  </button>
                }
                  <InputFile
                    title="Добавить изображения"
                    onChange={setImages}
                    accept="image/png, image/jpeg"
                    multiple
                    styles={{margin: '1rem 1rem auto ', height: '36px'}}
                  />
              </div>
          </div>
        </>
      )}
    </>
  );
};

const TextsField = ({
  data,
  setData,
  badgeSizes
}) => {
  
  const [currentText, setCurrentText] = useState(null)

  const popUpRef = React.useRef();
  
  const currentTextChange = (field, e) => {
    const _components = [...data.components]
    let newTextObject
    switch (field) {
      case 'data':
        newTextObject = {...currentText, data: e.target.value}
        break
      case 'color':
        newTextObject = {...currentText, shape: {...currentText.shape, fill: e.hex}}
        break
      case 'fontSize':
        newTextObject = {...currentText, shape: {...currentText.shape, fontSize: e.target.value}}
        break
      case 'fontWeight':
        const weight = currentText.shape.fontStyle && currentText.shape.fontStyle > 400
        newTextObject = {...currentText, shape: {...currentText.shape, fontStyle: weight ? 400 : 700}}
        break
      case 'fontStyle':
        const italicFont = currentText.shape.fontStyle === 'italic'
        newTextObject = {...currentText, shape: {...currentText.shape, fontStyle: italicFont ? 'normal' : 'italic'}}
        break
      case 'fontTransform':
        const underlined = currentText.shape.textDecoration
        newTextObject = {...currentText, shape: {...currentText.shape, textDecoration: underlined ? '' : 'underline'}}
        break
      case 'align':
        const currentAlign = currentText.shape.align || 'left'
        const newAlignIndex = (alignItems.indexOf(currentAlign)+1)%3
        newTextObject = {...currentText, shape: {...currentText.shape, align: alignItems[newAlignIndex]}}
        break
      default:
        newTextObject = {...currentText}
    }
    const index = _components.findIndex(it => it.id===currentText.id)
    _components[index] = newTextObject
    setData(prev => ({...prev, components: _components}))
    setCurrentText(newTextObject)
  }

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    }
  }, []);

  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (popUpRef.current && !path.includes(popUpRef.current)) {
      setCurrentText(null)
    }
  };

  const addNewText = () => {
    const idsList = isNaN(data.components.map(comp => comp.id)[0]) ? [1] : data.components.map(comp => comp.id);
    const newTextComponent = {
      data: 'Новый текст',
      id: generateId(idsList),
      shape: {x: 100, y: 100, fontSize: 22, fontStyle: 400},
      type: 'text'
    }
    setData(prev => ({...prev, components: [...prev.components, newTextComponent]}))
  }

  const handlePlugClick = (plugName) => {
    const compNamesList = data.components.map(comp => comp.data)
    const textData = plugName===textPlugs[0] ? plugName.split(' ').join('\n') : plugName
    let newComponents
    if (compNamesList.includes(textData)) {
      newComponents = data.components.filter(comp => comp.data !== textData)
    } else {
      const plugWidth = plugName===textPlugs[2]
                          ? 140
                          : plugName===textPlugs[0]
                            ? 210
                            : 425
      const badgeWidth = parseInt(badgeSizes.width.substring(0, badgeSizes.width.length - 2))
      const xCoord = Math.round((badgeWidth - plugWidth) / 2)
      const idsList = data.components.map(comp => comp.id)
      const newTextComponent = {
        data: textData,
        id: generateId(idsList),
        shape: {
          x: xCoord,
          y: 50,
          width: plugWidth,
          fontSize: 26,
          align: 'center'
        },
        type: 'text'
      }
      newComponents = [...data.components, newTextComponent]
    }
    setData(prev => ({...prev, components: newComponents}))
  }


  return (
    <div>
      <div className={styles.textPlugsBlock}>
        {textPlugs.map(plug => {
          const plugData = plug===textPlugs[0] ? plug.split(' ').join('\n') : plug
          const bordered = data.components.map(comp => comp.data).includes(plugData)
          return (
            <div
              onClick={() => handlePlugClick(plug)}
              style={bordered ? { opacity: '1'} : null}
            >
              {plug}
            </div>
          )
        })}
      </div>
      {data.components
        .filter(comp => comp.type==='text')
        .map(comp =>
          <div className={styles.textItem} key={comp.id}>
            <div>
              <div className={styles.controls}></div>
              <div>
                {currentText?.id===comp.id
                  ? <div ref={popUpRef}>
                      <input
                          value={currentText.data}
                          onChange={(e) => currentTextChange('data', e)}
                          className={styles.textInput}
                          disabled={textPlugs.includes(currentText.data)}
                          autoFocus
                        />
                      <div className={styles.controls_line}>
                        <span className={styles.fontSizeWrapper} title="Размер текста в пикселях">                      
                          <input 
                            value={currentText.shape.fontSize}
                            onChange={(e) => currentTextChange('fontSize', e)}
                            className={styles.fontSize}
                            type="number"
                            min="10"
                            max="100"
                          /> px
                        </span>

                        <i 
                          onClick={() => currentTextChange('fontStyle')}
                          className={styles.transformButton}
                          style={currentText.shape.fontStyle==='italic' ? { border: '2px solid crimson'} : null}
                          title="Наклонный шрифт"
                        >
                          <BiText/>
                        </i>
                        <i 
                          onClick={() => currentTextChange('fontTransform')}
                          className={styles.transformButton}
                          style={currentText.shape.textDecoration ? { border: '2px solid crimson'} : null}
                          title="Подчеркнутый шрифт"
                        >
                          <BiFontColor/>
                        </i>
                        <i 
                          onClick={() => currentTextChange('fontWeight')}
                          className={styles.transformButton}
                          style={currentText.shape.fontStyle > 400 ? { border: '2px solid crimson'} : null}
                          title="Жирный шрифт"
                        >
                          <BiBold/>
                        </i>
                        <i 
                          onClick={() => currentTextChange('align')}
                          className={styles.transformButton}
                          // style={currentText.shape.fontStyle > 400 ? { border: '2px solid crimson'} : null}
                          title="Расположение текста"
                        >
                          {currentText.shape.align==='center'
                            ? <BiAlignMiddle/>
                            : currentText.shape.align==='right'
                              ? <BiAlignRight/>
                              : <BiAlignLeft/>
                          }
                        </i>
                      </div>

                      <div className={styles.colorWrapper}>
                      <CompactPicker 
                        color={currentText.shape.fill}
                        onChangeComplete={(e) => currentTextChange('color', e) }
                      />
                    </div>
                  </div>
                  : <div className={styles.wrapperEdit}>
                      <span>{comp.data}</span>
                      <i>
                      <IoIosColorWand
                        className={styles.edit}
                        onClick={() => setCurrentText(comp)}
                      />
                      </i>
                    </div>
                }
              </div>
            </div>
            <IoIosClose />
          </div>
        )}
        {!currentText &&
        <ButtonRed
          style={{margin:'2rem auto', padding: '.5rem 1rem', fontSize: '1rem'}}
          onClick={addNewText}
        >
          Добавить текст
        </ButtonRed>}
    </div>
  )
};
