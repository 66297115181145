import React, {useState, useEffect} from 'react'
import styles from "./Stopwatch.module.css"


export function Stopwatch({ finished, setTime}) {
    

    const [timer, setTimer] = useState(null)
    
    const [currentTime, setCurrentTime] = useState({
        currentTimeMs: 0,
        currentTimeSec: 0,
        currentTimeMin: 0,
    })

    useEffect(() => {
        finished ? stop() : start()
    }, [finished])
    

    const formatTime = (val, ...rest) => {
        let value = val.toString();
        if (value.length < 2) {
          value = '0' + value;
        }
        if (rest[0] === 'ms' && value.length < 3) {
          value = '0' + value;
        }
        return value;
    };

    const start = () => {
          let interval = setInterval(() => pace(), 10);
          setTimer(interval)
    };

    const reset = () => {
        setCurrentTime({
          currentTimeMs: 0,
          currentTimeSec: 0,
          currentTimeMin: 0,
        });
    };

    const stop = () => {
        clearInterval(timer);
        setTime(`${formatTime(currentTime.currentTimeMin)}:${formatTime(currentTime.currentTimeSec)}:${formatTime(currentTime.currentTimeMs)}`)
    };

    const pace = () => {
        setCurrentTime(prev => {
            if (prev.currentTimeSec > 59) {
                return {...prev, currentTimeSec: 0, currentTimeMin: prev.currentTimeMin + 1}
            } else if (prev.currentTimeMs >= 990) {
                return {...prev, currentTimeMs: 0, currentTimeSec: prev.currentTimeSec + 1}
            } else return {...prev, currentTimeMs: prev.currentTimeMs + 10}
        })
    };
  
    return (
    <div className={styles.stopWatch}>
        <span>
        {formatTime(currentTime.currentTimeMin)}:
        {formatTime(currentTime.currentTimeSec)}:
        {formatTime(currentTime.currentTimeMs, 'ms')}
        </span>
    </div>
  )
}
