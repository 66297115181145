import React, { useState } from 'react';

import { Navigation, FreeMode, Thumbs } from 'swiper';
import { Swiper } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.css';


export function Slider({ children, sliderName = 'mySlider', autoHeight = false, popup=() => {} }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <>
        <div className='wrapperSliderEquipmentBig'>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#000',
                    '--swiper-pagination-color': '#000',
                }}
                autoHeight={autoHeight}
                spaceBetween={10}
                navigation={true}
                slidesPerGroup={1}
                slidesPerView={1}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className={`hero__big`}>
                {children}
            </Swiper>
        </div>
        
        <div className='wrapperSliderEquipmentSmall'>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#000',
                    '--swiper-pagination-color': '#000',
                }}
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                navigation={true}
                slidesPerGroup={1}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className={`hero__small`}>
                {children}
            </Swiper>
        </div>
        </>
    );
}
