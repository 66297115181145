import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import RegionGroupsModal from "./RegionGroupsModal";
import styles from "./RegionGroups.module.css";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose } from "react-icons/io";
import {
    getRegionGroups,
    addRegionGroup,
    editRegionGroup,
    deleteRegionGroup
} from '../../actions/users'
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";




const RegionGroups = ({
  getRegionGroups,
  addRegionGroup,
  editRegionGroup,
  deleteRegionGroup,
  data
}) => {
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [sort, setSort] = useState("");


    useEffect(() => {
      getRegionGroups(sort)
    }, [sort])
    

    const openModal = (type, item) => () => {
        setSelectedItem(item);
        setModalType(type);
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
      };

      const acceptConfirmation = () => {
        deleteRegionGroup(confirmation.id)
      }

      const handleSortClick = () => {
        const values = ['', 'asc', 'desc']
        const newValueIndex = (values.indexOf(sort)+1)%3
        setSort(values[newValueIndex])
      }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Группы регионов</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
            <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Параметры:</div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
          {data &&
              data.map((a) => (
                <div key={a.id} className={styles.tableRow}>
                  <div
                    className={styles.displayname}
                    onClick={openModal("EDIT", a)}
                  >
                    <span>
                      {a.group_name}
                    </span>
                  </div>
                  <div className={styles.username}>{a.groupname}</div>
                  <div className={styles.superuser}></div>
                  <div className={styles.actions}>
                    <IoMdClose
                      onClick={() =>
                        setConfirmation({ text: a.group_name, id: a.id })
                      }
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.controls}>
        <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
            </div>
        </div>
      </div>
      {confirmation && (
        <Confirmation
          confirmation={confirmation.text}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      )}
      {isModalOpen &&
      <RegionGroupsModal
        addGroup={addRegionGroup}
        editGroup={editRegionGroup} 
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
      />}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.departments.loading,
  data: state.users.regionGroups
});

export default connect(mapStateToProps, {
    getRegionGroups,
    addRegionGroup,
    editRegionGroup,
    deleteRegionGroup
})(RegionGroups);
