import React, { useEffect, useState } from 'react'
import styles from '../Profile.module.scss'
import FormInput from '../../../assets/common/FormInput/FormInput'
import FileLabel from '../../../components/FileLabel/FileLabel'
import CustomInput from '../../../components/CustomInput/CustomInput'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import { BACKEND_DOMAIN, SPORT_GRADES } from '../../../utils/const'
import Modal from 'react-responsive-modal'
import { ButtonRed } from '../../../assets/common/ButtonRed/ButtonRed'
import { IoIosClose, IoIosCreate, IoMdAdd, IoMdCreate } from 'react-icons/io'
import { useFormik } from 'formik'
import { createActivity, createSportActivity, deleteActivity, deleteSportActivity, getActivityTypes, updateActivity, updateSportActivity } from '../../../actions/yunarmiyans'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { getYearsArr } from '../../../utils/yunarmyStat'
import cn from 'classnames'
import FormSelect from '../../../assets/common/FormSelect/FormSelect'

const imageReg = /\.(jpg|jpeg|png|gif|bmp)$/i

const INITIAL_ACTIVITY_DATA = {
  name: '',
  result: '',
  year: moment().year(),
  type_id: '',
  article_files: []
}

const STATUSES = ['Участник', 'Призер', 'Победитель']

const EventsBlock = ({ data, profileId, editMode }) => {
  
  const dispatch = useDispatch()
  const { activityTypes } = useSelector(state => state.yunarmians)

  const [modal, setModal] = useState(null)  

  useEffect(() => {
    dispatch(getActivityTypes())
  }, [])
  
  const openModal = (data) => () => setModal(data)

  const deleteActivityHandler = (activId) => () => {
    dispatch(deleteActivity(activId))
  }

  // const renderFile = file => {
  //   const fileLink = `${BACKEND_DOMAIN || ''}${file.file_path}`
  //   const fileName = file.file_path.split('/').at(-1)
  //   return (
  //     <FileLabel
  //       imageMode={imageReg.test(file.file_path)}
  //       fileLink={fileLink}
  //       fileName={fileName}
  //     />
  //   )
  // }

  const renderFile = file => {
    const fileLink = `${BACKEND_DOMAIN || ''}/${file.path}/${file.name}`
    return (
      <FileLabel
        imageMode={file.file_type.includes('image')}
        fileLink={fileLink}
        fileName={file.name}
      />
    )
  }
  
  const renderActivity = (activity) => (
    <div className={styles.medicineBlock}>
      <div className={styles.dataRow}>
        <div className={styles.rowWrap}>
          <div>
            <FormSelect
              width="300px"
              title="Тип мероприятия"
              editMode={false}
              value={activity.type_id}
              options={activityTypes?.map(({id, name}) => ({name, value: id}))}
            />
          </div>
        </div>
        <div className={styles.rowWrap}>
          <div>
            <FormInput
              width="300px"
              title="Название"
              editMode={false}
              value={activity.name}
            />
          </div>
        </div>
        <div className={styles.rowWrap}>
          <div>
            <FormInput
              width="300px"
              title="Достижение"
              editMode={false}
              value={activity.result}
            />
          </div>
        </div>
        <div className={styles.gtoWrapper}>
          {activity.article_files?.map(renderFile)}
        </div>
        
        {editMode &&
          <div className={styles.iconsBlock}>
            <IoMdCreate onClick={openModal(activity)} color='#6a1919' title='Редактировать'/>
            <IoIosClose onClick={deleteActivityHandler(activity.id)} size={26} title='Удалить' color='#6a1919'/>
          </div>
        }
      </div>
    </div>
  )

  return (
    <>
      <div className={styles.medicine}>
        <div className={styles.title}>Участие в мероприятиях</div>
        {data?.map(renderActivity)}
        <EventModal
          open={!!modal}
          onClose={() => setModal(null)}
          data={modal}
          addMode={!modal?.id}
          profileId={profileId}
          activityTypes={activityTypes}
        />
      </div>
        {editMode &&
          <div onClick={openModal(INITIAL_ACTIVITY_DATA)} className={cn(styles.fileModalButton, styles.smallFileModalButton)}>
            <IoMdAdd size={30} color='#ced4da'/>
          </div>
        }
    </>
  )
}

export default EventsBlock





const EventModal = ({ open, onClose, data, addMode, profileId, activityTypes }) => {

  const modalTitle = addMode ? 'Добавление мероприятия' : 'Редактирование мероприятия'

  const dispatch = useDispatch()

  const addFiles = (e) => {
    const files = Array.from(e.target.files)
    setFieldValue('article_files', [...values.article_files, ...files])
  }

  const openFilesLoad = () => {
    const input = document.getElementById('inputEventFiles')
    input.click()
  }

  const deleteFileHandler = (index) => () => {
    const newFilesList = values.article_files.filter((it, i) => i !== index)
    setFieldValue('article_files', newFilesList)
  }


  const renderFile = (file, index) => {
    const { id, name, path } = file
    const imageMode = id ? imageReg.test(name) : file.type.includes('image') 
    const fileLink = id ? `${BACKEND_DOMAIN || ''}/${path}/${name}` : URL.createObjectURL(file)
    return (
      <FileLabel
        imageMode={imageMode}
        fileLink={fileLink}
        fileName={name}
        onDelete={deleteFileHandler(index)}
      />
    )
  }

  const addActivityHandler = async (values) => {
    const payload = {...values, profile_id: profileId}
    await dispatch(createActivity(payload))
    onClose()
  }
  
  const editActivityHandler = async ({id, ...values}) => {
    await dispatch(updateActivity(id, values, data))
    onClose()
  }

  const {
    values,
    handleChange, 
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: data || INITIAL_ACTIVITY_DATA,
    enableReinitialize: true,
    onSubmit: addMode ? addActivityHandler : editActivityHandler
  })


  return (
    <Modal
      open={open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      classNames={{modal: styles.modal}}
    >

      <h3>{modalTitle}</h3>

      <CustomSelect
        name='type_id'
        title="Тип мероприятия"
        selectedValue={values.type_id}
        options={activityTypes?.map(({id, name}) => ({name, value: id}))}
        // width='400px'
        onChange={handleChange}
      />

      <CustomInput
        name='name'
        title="Название"
        value={values.name}
        onChange={handleChange}
      />

      <CustomSelect
        name='year'
        title="Год"
        selectedValue={values.year}
        options={getYearsArr(2016).map(el => ({name:el,value:el}))}
        width='120px'
        onChange={handleChange}
      />

      <CustomSelect
        name='result'
        title="Достижение"
        selectedValue={values.result}
        options={STATUSES.map(grade => ({name: grade, value: grade}))}
        onChange={handleChange}
      />

      <div className={styles.filesBlock}>
        <p>Файлы:</p>
        <ul>
          {values.article_files?.map(renderFile)}
          <li onClick={openFilesLoad} className={styles.fileModalButton}>
            <input type='file' onChange={addFiles} id='inputEventFiles' accept='image/*, application/pdf' multiple hidden />
            <IoMdAdd size={30} color='#ced4da'/>
          </li>
        </ul>
      </div>

      <div className={styles.buttonsBlock}>
        <ButtonRed onClick={handleSubmit}>Сохранить</ButtonRed>
        <ButtonRed onClick={onClose}>Отмена</ButtonRed>
      </div>

    </Modal>
  )
}