import React from "react";
import { useDrag } from "react-dnd";
import styles from "./MapQuestion.module.css";

const Item = ({ type, name, isDropped }) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { name },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name, type]
  );

  return (
    !isDropped && (
      <div ref={drag} role="Box">
        {name}
      </div>
    )
  );
};

export { Item };
