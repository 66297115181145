import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";

import ConfirmationMode from "./ConfirmationMode/ConfirmationMode";
import styles from './RegRating.module.css'



const GeneralRatingModal = ({
  onClose,
  year,
  sortedBy,
  confirmationMode,
  getRatingValue,
  removeRatingValue,
  ratingValue,
  haveEditRight,
  setRatingValue,
  setConfirmationMode,
  loading,
  token,
  status
}) => {
  
    const [ratingValueAction, setRatingValueAction] = useState(false)

  
  return (
    <Modal
      open={confirmationMode}
      onClose={onClose}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        Подтверждение параметра
      </div>
      <div className={styles.modalBody}>
          <ConfirmationMode
            token={token}
            valueId={confirmationMode}
            year={year}
            status={status}
            sortedBy={sortedBy} 
            haveEditRight={haveEditRight}
            getRatingValue={getRatingValue}
            removeRatingValue={removeRatingValue}
            ratingValue={ratingValue}
            setRatingValue={setRatingValue}
            ratingValueAction={ratingValueAction}
            loading={loading}
            setRatingValueAction={setRatingValueAction}
            setConfirmationMode={setConfirmationMode}
          />
        <div className="settingModal__btns">
          <button
            className="newsListHeader__btn btn_grey"
            type="button"
            onClick={() => setRatingValueAction(true)}
          >
            Сохранить
          </button>
          <button
            className="newsListHeader__btn btn_grey"
            onClick={() => setConfirmationMode(null)}
            type="button"
          >
            Отменить
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default GeneralRatingModal;
