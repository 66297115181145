import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import {
  SET_REGION_RATING_LOADING,
  SET_REGION_RATING_DATA,
  ERROR,
  SET_REGION_RATING_PARAMS,
  SET_REGION_RATING_CELL_LOADING,
  SET_RATING_VALUE,
  SET_REGION_RATING_BY_ID,
  SET_REGION_RATING_CRITERION,
  SET_CONFIRM_MODE_LOADING,
  SET_ALL_REGION_RATING_PARAMS,
  SET_REGION_RATING_IS_NEW,
  SET_REGION_RATING_CRITERION_BY_YEAR
} from "./types.js";
import { createNotification } from "../components/Notifications/Notifications";
import { filterRatingData } from "../utils/yunarmyStat";

export const setRegionRatingLoading = (payload) => ({
  type: SET_REGION_RATING_LOADING,
  payload,
});

export const removeRatingValue = () => ({
  type: SET_RATING_VALUE,
  payload: null,
});

export const removeAllRegions = () => ({
  type: SET_REGION_RATING_PARAMS,
  payload: null,
});

export const remoreIsNew = () => ({
  type: SET_REGION_RATING_IS_NEW,
  payload: false,
});

export const removeRegById = () => ({
  type: SET_REGION_RATING_BY_ID,
  payload: {}
});

export const getRating_year = (year = null) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/rating_year/${year}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        
        const data = await response.json();
        dispatch({ type: SET_REGION_RATING_BY_ID, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch(setRegionRatingLoading(false));
  };

export const getRegionRating =
  (year = null, nameSort = null, type) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SET_REGION_RATING_LOADING, payload: type === 'region' ? true : false });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      let params = {};
      year && (params.year = year);
      nameSort === "name" && (params.sort_by = "name");
      const urlParams = new URLSearchParams(params).toString();

      const response = await fetch(
        `${BACKEND_URL_PREFIX}/region_rating?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        const rating = filterRatingData(data.depts_rating)
        const date = data.closed_date
        dispatch({ type: SET_REGION_RATING_DATA, payload: {rating, date} });
      } else {
        dispatch({ type: SET_REGION_RATING_DATA, payload: [] });
        const responseData = await response.json();
          createNotification(
            "error",
            responseData.error || "Ошибка"
          );
        
        dispatch({ type: ERROR, payload: response });
      }
    }
    } catch (ERR) {

    } finally {
      dispatch(setRegionRatingLoading(false));
      dispatch({ type: SET_REGION_RATING_CELL_LOADING, payload: false });
    }
  };

export const setRegionRating =
  (data, year) => async (dispatch, getState) => {
    dispatch({ type: SET_REGION_RATING_CELL_LOADING, payload: true });
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      try {
        const response = await fetch(
          `${BACKEND_URL_PREFIX}/rating_value`,
          {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
          }
        );
        if (response.ok) {
          dispatch(getRegionRating(year));
        } else {
          const responseData = await response.json();
          createNotification("error", responseData.error || "Ошибка сохранения данных");
          dispatch(getRegionRating(year));
          dispatch({ type: ERROR, payload: response });
          dispatch({ type: SET_REGION_RATING_CELL_LOADING, payload: false });
        }
      } catch (ERR) {
        dispatch({ type: SET_REGION_RATING_CELL_LOADING, payload: false });
      }
    }
  };

export const getAllRatingParams = () => async (dispatch, getState) => {
  dispatch({ type: SET_REGION_RATING_LOADING, payload: true });
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/rating_parameters_years`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
        dispatch({
        type: SET_ALL_REGION_RATING_PARAMS,
        payload: data,
      });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({ type: SET_REGION_RATING_LOADING, payload: false });
};

export const getRatingParams = (id) => async (dispatch, getState) => {
  dispatch({ type: SET_REGION_RATING_LOADING, payload: true });
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/rating_parameters_years/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),  
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({
        type: SET_REGION_RATING_PARAMS,
        payload: data,
      });
    } else {
      if (response.status === 404) {
        dispatch({
          type: SET_REGION_RATING_PARAMS,
          payload: [],
        });
      }
      dispatch({
        type: SET_REGION_RATING_IS_NEW,
        payload: true,
      });
      // createNotification( "error", "Ошибка");
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({ type: SET_REGION_RATING_LOADING, payload: false });
};

export const getRatingCriterion = (year) => async (dispatch, getState) => {
  // dispatch({ type: SET_REGION_RATING_LOADING, payload: true });
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/rating_parameters/criterion?year=${year}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_REGION_RATING_CRITERION, payload: data.rating_criterion || [] });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  // dispatch({ type: SET_REGION_RATING_LOADING, payload: false });
};

export const getRatingCriterionByYear = (id, year) => async (dispatch, getState) => {
  dispatch({ type: SET_REGION_RATING_LOADING, payload: true });
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/rating_parameters/criterion?year=${year}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_REGION_RATING_CRITERION_BY_YEAR, payload: data.rating_criterion || [] });
      dispatch(getRatingParams(id))
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({ type: SET_REGION_RATING_LOADING, payload: false });
};

export const editRatingParam = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const id = data.id;
    delete data.id;
    delete data.updated;
    delete data.data_format;
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/update_parameter_by_year/${id}`,
      {
        ...buildDefaultHeaders(getState),
        method: "put",
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      dispatch(getRatingParams(data.rate_year_id));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const updateRatingParams = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/update_rating_params`,
      {
        ...buildDefaultHeaders(getState),
        method: "put",
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      createNotification('success', `Параметры успешно обновлены`)
    }
  }
};


export const getRatingValue = (id) => async (dispatch, getState) => {
  dispatch({type: SET_CONFIRM_MODE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/rating_value/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_RATING_VALUE, payload: data});
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({type: SET_CONFIRM_MODE_LOADING, payload: false})
};

export const setRatingValue = (id, year, sortedBy, data, addFilesList=[], deleteFilesList=[]) => async (dispatch, getState) => {
  dispatch({type: SET_CONFIRM_MODE_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    await dispatch(isConfirmedChange(id, data))
    if (deleteFilesList.length) {
      for (const id of deleteFilesList) {
        await dispatch(deleteFile(id));
      }
    }
    if (addFilesList.length) {
      for (const file of addFilesList) {
        await dispatch(uploadFile(id, file));
      }
    }
    await dispatch(getRegionRating(year, sortedBy))
  }
  dispatch({type: SET_CONFIRM_MODE_LOADING, payload: false})
}

export const isConfirmedChange = (id, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const ratingValue = getState().region_rating.rating_value
    const confirmChanged = ratingValue.is_confirmed!==data.is_confirmed

    const response = await fetch(`${BACKEND_URL_PREFIX}/rating_value/${id}/confirm`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      console.log('ok')
      confirmChanged && createNotification('success', `${data.is_confirmed ? 'Подтверждено' : 'Подтверждение удалено'} успешно`)
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
}

const uploadFile = (id, file) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());

  const fd = new FormData();
  fd.append("file", file.file);
  fd.append("description", file.description);

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/rating_value/${id}/file`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
      body: fd,
    });
    if (response.ok) {
      createNotification('success', 'Файл добавлен успешно')
      console.log("ok");
    }
  }
};

const deleteFile = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/rating_value/file/${id}`, {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        console.log("ok");
        createNotification('success', 'Файл удален успешно')
      } else {
        const data = await response.json()
        createNotification('error', data.error)
      }
    } catch (ERR) {
      console.log(ERR)
    }
  }
};

export const updatePercentage = (year, sort) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch(setRegionRatingLoading(true));
    const data = { year: year }
    const response = await fetch(`${BACKEND_URL_PREFIX}/region_rating/update_children_qty`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getRegionRating(year, sort))
      createNotification('success', `Доля участников движения от общего количества детей в регионах пересчитана`)
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
}

export const addRating = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch(setRegionRatingLoading(true));
    const response = await fetch(`${BACKEND_URL_PREFIX}/add_rating_parameters_year`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getAllRatingParams())
      createNotification('success', `Успешно`)
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
}

export const updateClosedDate = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch(setRegionRatingLoading(true));
    const response = await fetch(`${BACKEND_URL_PREFIX}/rating_parameters`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', `Дата закрытия рейтинга успешно изменена`)
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
}

export const editRating = (data, id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch(setRegionRatingLoading(true));
    const response = await fetch(`${BACKEND_URL_PREFIX}/update_rating_parameters_year/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', `Успешно`)
      await dispatch(getAllRatingParams());
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
}

export const deleteRating = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/delete_rating_parameters_year/${id}`, {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        createNotification('success', 'Успешно');
        await dispatch(getAllRatingParams());
      } else {
        createNotification('error', 'Ошибка');
      }
    } catch (ERR) {
      console.log(ERR)
    }
  }
};

export const addRatingParam = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch(setRegionRatingLoading(true));
    const response = await fetch(`${BACKEND_URL_PREFIX}/add_parameter_by_year`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getRatingParams(data.rate_year_id));
      //
      createNotification('success', `Успешно`)
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
}


export const deleteRatingParam = (id, regId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/delete_parameter_by_year/${id}`, {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        createNotification('success', 'Успешно');
        await dispatch(getRatingParams(regId));
      } else {
        createNotification('error', 'Ошибка');
      }
    } catch (ERR) {
      console.log(ERR)
    }
  }
};

export const getExcelRating = (year) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/region_rating/excel?year=${year}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return url;
    }
  }
}