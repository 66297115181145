import React from 'react';
import { Field } from 'redux-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

const WrappedDatePicker = ({ input: { value, onChange }, label, className, ...props }) => (
  <div className={className}>
    {label && <div>{label}</div>}
    <DatePicker
      dateFormat="DD.MM.YYYY"
      className="date-picker"
      todayButton="Сегодня"
      selected={value ? moment(value) : null}
      onChange={onChange}
      {...props}
    />
  </div>
);

const DatePickerField = props => (
  <Field
    component={WrappedDatePicker}
    {...props}
  />
);

export default DatePickerField;

