import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { reduxForm } from "redux-form";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import ReactQuill from "react-quill";
import styles from "./Regulations.module.css";
import { IoIosCloseCircle, IoIosSearch, IoIosDocument } from "react-icons/io";
import { available, BACKEND_DOMAIN, eventLevels } from "../../utils/const";
import { createNotification } from "../../components/Notifications/Notifications";
import moment from "moment";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import DatePicker from "react-datepicker";

const RegulationsModal = ({
  type,
  numberPage,
  selectedItem,
  editObject,
  addObject,
  articles,
  searchByTitle,
  searchData,
  delSearch,
  deleteFile,
  page,
  usersList,
  getUserList,
  sUser,
  regulationAccessRights,
  ordersAccessRights,
  ...props
}) => {
  const [item, setItem] = useState(null);
  const [addFilesList, setAddFilesList] = useState([]);
  const [search, setSearch] = useState("");
  const [eventTitle, setEventTitle] = useState({ title: null, level: null });
  const [userName, setUserName] = useState(null);
  const [isChecked, setIsChecked] = useState([]);
  const [deliteList, setDeliteList] = useState([]);
  const [isShow, setIsShow] = useState(false);

  const updateRights = (page === 1 && regulationAccessRights.ac_update) || (page === 2 && ordersAccessRights.ac_update)


  const options = available.map((items) => {
    return (
      <option
        key={items.value}
        value={items.value}
        selected={selectedItem != null && selectedItem.level == items?.value}
      >
        {items.name}
      </option>
    );
  });

  useEffect(() => {
    getUserList();
    setIsChecked([]);
    setEventTitle({ title: null, level: null });
    if (type === "ADD") {
      setItem({
        article_id: "",
        doc_type: `${page == 1 ? "provision" : page == 2 ? "order" : ""}`,
        name: "",
        approving_doc: "",
        approving_doc_number: "",
        approving_doc_date: "",
        user_ids: "",
        level: "",
      });
      setAddFilesList([]);
    } else {
      selectedItem && setItem(selectedItem);
      if (selectedItem?.files) {
        setAddFilesList(selectedItem.files);
      } else {
        setAddFilesList([]);
      }
    }
  }, [selectedItem, type]);

  const addRegulationsItem = () => {
    if (
      item?.name &&
      item?.article_id &&
      (item?.approving_doc || item.doc_type === "order") &&
      item?.approving_doc_date &&
      item?.approving_doc_number &&
      item?.level &&
      item?.user_ids.length > 0
    ) {
      addObject(item, numberPage+1, page, addFilesList);
      props.onClose();
    } else {
      createNotification("error", "Все поля являются обязательными");
    }
  };

  const editRegulationsItem = () => {
    const tmp = { ...item };
    tmp.id && delete tmp.id;

    if (
      item?.name &&
      item?.article_id &&
      (item?.approving_doc || item.doc_type === "order") &&
      item?.approving_doc_date &&
      item?.approving_doc_number &&
      item?.level &&
      item?.user_ids.length > 0
    ) {
      editObject(item.id, tmp, numberPage+1, page, addFilesList);
      if (deliteList && deliteList.length && page) {
        deliteList
          .filter((el) => el !== undefined)
          .forEach((element) => {
            deleteFile(element, numberPage+1, page);
          });
      }
      props.onClose();
    } else {
      createNotification("error", "Все поля являются обязательными");
    }
  };

  const handleUploadFile = (e) => {
    if (e.target.files) {
      const files = Object.values(e.target.files);
      setAddFilesList((prev) => [...prev, ...files]);
    }
  };

  const deleteCurrentFile = (ind, id) => {
    const arr = [...addFilesList];
    arr.splice(ind, 1);
    setAddFilesList(arr);
    const arrDel = [...deliteList];
    arrDel.push(id);
    setDeliteList(arrDel);
  };

  const handleSearch = (e) => {
    setSearch((prev) => e);
  };

  const handleSetEvantTitle = (el, id, lvl) => {
    item.article_id = id;
    setEventTitle((prev) => ({
      title: el,
      level: eventLevels?.filter((_el) => _el.value == lvl)[0]?.name,
    }));
    setSearch("");
    delSearch();
    setIsShow(false);
  };

  const handleSetUserName = (el, id) => {
    setUserName((prev) => el);
  };

  const handlSelect = (e) => {
    setItem((prev) => ({ ...prev, level: e }));
  };

  const handleCheck = (id) => {
    if (isChecked.includes(id)) {
      setIsChecked(isChecked.filter((item) => item != id));
    } else {
      setIsChecked((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    if (selectedItem && selectedItem.responsible_users.length > 0) {
      let ids = selectedItem.responsible_users.map((el) => el.id);
      setItem((prev) => ({ ...prev, user_ids: ids }));
      setIsChecked(ids);
    }
    if (selectedItem && selectedItem.article) {
      setEventTitle((prev) => ({
        title: selectedItem.article.title,
        level: selectedItem.article?.level,
      }));
    }
  }, [selectedItem]);
  const handleSearchBy = () => {
    searchByTitle(search);
    setIsShow(true);
  };

  useEffect(() => {
    setItem((prev) => ({ ...prev, user_ids: isChecked }));
  }, [isChecked]);

  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      {
        <>
          <div className={styles.title}>
            {type === "ADD"
              ? `Добавление  ${
                  page === 1 ? "положения" : page === 2 ? "приказа" : ""
                }`
              : `Редактирование  ${
                  page === 1 ? "положения" : page === 2 ? "приказа" : ""
                }`}
          </div>
          <div className={styles.modalBody}>
            <div>
              <div className={styles.firstBlock}>
                <div className={styles.firstBlock__left}>
                  <div className={styles.quillWrapper}>
                    <p className={styles.Regulations__label}>
                      Название{" "}
                      {page === 1 ? " положения" : page === 2 ? " приказа" : ""}
                      :
                    </p>
                    <ReactQuill
                      value={item?.name}
                      readOnly={!updateRights}
                      onChange={(e) =>
                        setItem((prev) => ({ ...prev, name: e }))
                      }
                    />
                  </div>

                  {page === 1 ? (
                    <div style={{ marginBottom: "1rem" }}>
                      <p className={styles.Regulations__label}>
                        Утверждающий документ:
                      </p>
                      <input
                        maxlength="200"
                        value={item?.approving_doc}
                        disabled={!updateRights}
                        onChange={(e) =>
                          setItem((prev) => ({
                            ...prev,
                            approving_doc: e.target.value,
                          }))
                        }
                        className={styles.inputMod}
                      />
                    </div>
                  ) : page === 2 ? (
                    ""
                  ) : (
                    ""
                  )}

                  <p className={styles.Regulations__label}>
                    Номер{" "}
                    {page === 1
                      ? " утверждающего документа"
                      : page === 2
                      ? " приказа"
                      : ""}
                    :
                  </p>
                  <input
                    type="number"
                    value={item?.approving_doc_number}
                    disabled={!updateRights}
                    onChange={(e) =>
                      e.target.value.split("").length < 21 &&
                      setItem((prev) => ({
                        ...prev,
                        approving_doc_number: e.target.value,
                      }))
                    }
                    className={styles.inputMod}
                  />
                  <div>
                    <br />
                    <p className={styles.Regulations__label}>
                      Дата{" "}
                      {page === 1
                        ? " утверждения документа"
                        : page === 2
                        ? " приказа"
                        : ""}
                      :
                    </p>

                    <DatePicker
                      selected={
                        item?.approving_doc_date
                          ? item?.approving_doc_date.length === 10
                            ? moment(item?.approving_doc_date, "DD.MM.YYYY")
                            : moment(item?.approving_doc_date)
                          : null
                      }
                      dateFormat="DD.MM.YYYY"
                      readOnly={!updateRights}
                      locale={"ru"}
                      onChange={(e) =>
                        setItem((prev) => ({
                          ...prev,
                          approving_doc_date: e !== null ? e.format("DD.MM.YYYY") : null,
                        }))
                      }
                    />

                    <br />
                    <br />
                    <p className={styles.Regulations__label}>
                      Файл{" "}
                      {page === 1 ? " положения" : page === 2 ? " приказа" : ""}
                      :
                    </p>
                    
                    <div className={styles.addFilesList}>
                      {addFilesList &&
                        !!addFilesList.length &&
                        addFilesList.map((item, i) => (
                            <div className={styles.docBlock} key={i}>
                              <i className={styles.docIcon}>
                                <IoIosDocument />
                              </i>
                              <a href={`${BACKEND_DOMAIN || ""}/${item.path}/${item.name}`} download target="_blank" rel="noopener noreferrer" title='скачать'>
                              <div>{item.name}</div>
                              </a>
                              <i
                                className={styles.iconDelFile}
                                onClick={() => deleteCurrentFile(i, item.id)}
                              >
                                <IoIosCloseCircle />
                              </i>
                          </div>
                        ))}
                    </div>

                    <div className={styles.buttonFile}>
                      <InputFile
                        multiple
                        disabled={!updateRights}
                        accept="application/pdf,image/x-png,image/jpeg"
                        title="Выберите файл"
                        onChange={(e) => handleUploadFile(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.imageBlock}>
                  <div style={{ position: "relative", width: "100%" }}>
                    <p className={styles.Regulations__label}>Мероприятие:</p>
                    <div className={styles.searchBlock}>
                      <input
                        type="text"
                        disabled={!updateRights}
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        className={styles.inputMod2}
                      />
                      <i className={styles.searchIcon} onClick={handleSearchBy}>
                        <IoIosSearch />
                      </i>
                    </div>
                    <div className={styles.eventBlock}>
                      {searchData && isShow
                        ? searchData.map((el) => (
                            <span
                              onClick={() =>
                                handleSetEvantTitle(el.title, el.id, el.level)
                              }
                              className={styles.eventBlockItems}
                            >
                              {el.title}
                            </span>
                          ))
                        : ""}
                    </div>
                  </div>
                  <span className={styles.eventTitle}>
                    {eventTitle && eventTitle.title}
                    <span className={styles.lvl}> {eventTitle.level}</span>
                  </span>
                  <p className={styles.Regulations__label}>Доступно:</p>
                  <select
                    disabled={!updateRights}
                    onChange={(e) => handlSelect(e.target.value)}
                  >
                    {options}
                  </select>

                  <div style={{ position: "relative", width: "100%" }}>
                    <p className={styles.Regulations__label}>Ответственные:</p>
                    <div className={styles.searchBlock}>
                      <input
                        type="text"
                        value={userName}
                        disabled={!updateRights}
                        onChange={(e) => handleSetUserName(e.target.value)}
                        className={styles.inputMod3}
                      />
                    </div>

                    <div className="regionWrapper">
                      {usersList &&
                        usersList
                          .filter(
                            (user) =>
                              user?.displayname
                                .toLowerCase()
                                .includes(userName?.toLowerCase()) || !userName
                          )
                          .map((user, index) => (
                            <div
                              key={`${user.id}_${index}`}
                              className={styles.regionItem}
                            >
                              <CustomCheckbox
                                label={user.displayname}
                                disabled={!updateRights}
                                value={isChecked.includes(user.id)}
                                onChange={() => handleCheck(user.id)}
                              />
                            </div>
                          ))}
                    </div>
                    <div className={styles.wrapperNames}>
                      {isChecked
                        ? isChecked.map((el) => (
                            <span className={styles.names}>
                              {usersList.filter((_el) => _el.id === el)[0]
                                .displayname + ", "}
                            </span>
                          ))
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="settingModal__btns">
              {updateRights && (
                <button
                  className="newsListHeader__btn btn_grey"
                  type="button"
                  onClick={
                    type === "ADD" ? addRegulationsItem : editRegulationsItem
                  }
                >
                  {type === "ADD" ? "Добавить" : "Сохранить"}
                </button>
              )}
              <button
                className="newsListHeader__btn btn_grey"
                onClick={props.onClose}
                type="button"
              >
                Закрыть
              </button>
            </div>
          </div>
        </>
      }
    </Modal>
  );
};

const RegulationsModalWithForm = reduxForm({
  form: "regulations",
})(RegulationsModal);

export default connect()(RegulationsModalWithForm);
