import React from 'react'
import styles from "./CustomTextarea.module.css"



export default function CustomTextarea({
    title,
    max,
    width,
    inputWidth,
    style,
    fontSize = '18px',
    validate,
    height,
    ...props
}) {
  return (
    <div className={styles.input} style={{width: width, ...style}}>
        <div>{title}:</div>
        <textarea
            maxLength={max}
            placeholder={title}
            style={{width: inputWidth, fontSize: fontSize, height: height}}
            {...props}
          />
          {validate && <div className={styles.validate}>поле является обязательным</div>}
    </div>
  )
}