import * as types from "../actions/types";

const initialState = {
  count: null,
  page_count: null,
  data: null,
  questTasks: []
};

export default function quests (state = initialState, action) {
  switch (action.type) {
    case types.SET_QUESTS_COUNT:
      return {
        ...state,
        count: action.payload.data,
        page_count: action.payload.pageCount
      };
    case types.SET_QUESTS_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case types.SET_QUESTS_TASKS:
      return {
        ...state,
        questTasks: action.payload,
      };
    case types.REMOVE_QUEST_TASKS:
      return {
        ...state,
        questTasks: initialState.questTasks,
      };
    default:
      return state;
  }
}
