import React from "react";
import { Field } from "redux-form";
import { MdCheck } from "react-icons/md";

const style = {
  border: "2px solid #d7001b",
  width: "18px",
  height: "18px",
  minWidth: "18px",
  minHeight: "18px",
  display: "flex",
  borderRadius: "5px",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
  color: "#d7001b",
}

const WrappedCheckbox = ({
  input,
  className,
  labelClass,
  fieldStyle,
  label,
  color,
  name,
  boxLeft,
  ...props
}) => {
  return (
    <div className={className} style={fieldStyle}>
      {label && (
        <label className={`input__label ${labelClass}`} htmlFor={name}>
          {label}
        </label>
      )}
      <div>
        <label>
          <input
            type="checkbox"
            name={name}
            checked={input.value}
            {...props}
            {...input}
            style={{ display: "none" }}
          />
          <i
            style={
              {...style,
              color: color ? color : null,
              border: color ? `2px solid ${color}` : null,
              marginLeft: boxLeft ? boxLeft : null
            }
          }    
          >
            {input.value && <MdCheck size={20} />}
          </i>
        </label>
      </div>
      {/* <input type="checkbox" name={name} checked={input.value} {...props} {...input} /> */}
    </div>
  );
};

const Checkbox = (props) => {
  return <Field component={WrappedCheckbox} {...props} />;
};

export default Checkbox;
