import React from 'react'
import styles from './NoData.module.css'
import { AiOutlineStop } from "react-icons/ai";


export default function NoData({
    text = '',
    style
}) {
  return (
    <div className={styles.wrap} style={style}>
          <i><AiOutlineStop size={30}/></i>
          <div>{text}</div>
    </div>
  )
}
