import React, { useState, useEffect } from "react";
import styles from "./Layout.module.css";
import label from "../../assets/images/label.png";
import { BACKEND_DOMAIN, items, itemsItems, version } from "../../utils/const";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  loadUser,
  sso,
  signOut,
  auth,
  uploadUserImage,
  deleteUserImage,
} from "../../actions/auth";
import { getYunarmianProfile } from "../../actions/yunarmiyans";
import {
  IoMdCreate,
  IoMdDownload,
  IoIosClose,
  IoMdTrash,
} from "react-icons/io";
import Loader from "../Loader/Loader";
import avatar from "../../assets/images/avatar.png";
import { RiBook2Fill } from "react-icons/ri";
import moment from "moment";
import cn from 'classnames'


function Layout({
  children,
  history,
  signAuthAction,
  token,
  superuser,
  agsh,
  id,
  loading,
  user,
  uploadUserImage,
  deleteUserImage
}) {
  const [index, setIndex] = useState(null);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    getYunarmianProfile(id);
  }, []);

  const handleEdit = () => {
    setIsShow(!isShow);
  };

  const handleLoad = (e) => {
    uploadUserImage(id, e.target.files[0]);
    setIsShow(false);
  };

  const handleDel = () => {
    deleteUserImage(id);
    setIsShow(false);
  };

  const handleOpenItem = (i) => {
    if (index === i) {
      setIndex(null)
    } else {
      setIndex(i)
    }
  }

  window.addEventListener('resize', function(event) {
    setWidth(window.innerWidth);
  }, true);

  const renderMenuItem = ({access, name, link, children, value, sub}) => {
    
    if (access && !superuser && !agsh) return;
    return link
    ? <a
        onClick={() => setVisibleMenu(false)}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        key={name}
        className={cn(styles.iteem, {[styles.iteemSub]: sub})}
      >
        {name}
      </a>
      : children
        ? <div className={styles.childrenLinksContainer}>
            {renderLink({children, link, name, sub, value})}
            {children.map(renderMenuItem)}
          </div>
        : renderLink({children, link, name, sub, value})
  }


  const renderLink = ({children, link, name, value, sub}) => (
    <Link
      to={!children && (link || value)}
      key={name}
      onClick={() => setVisibleMenu(false)}
      className={cn(styles.iteem, {[styles.iteemSub]: sub})}
    >
      {name}
    </Link>
  )

  return (
    <div className={styles.container}>
      <div
        className={styles.header}
        style={{ backgroundImage: "url(/img/header.png)" }}
      >
        <a
          href="/Цифровой_дом_Юнармии_Инструкция_v_0_6.pdf"
          target="_blank"
          rel="noopener norefferer"
        >
          <div>
            Руководство пользователя
          </div>
          <RiBook2Fill/>
        </a>
        <Link to="/home">
          <img
            className={token ? styles.image : styles.imageAuth}
            src={label}
            alt="о_О"
          />
        </Link>
        <div className={styles.titles}>
          <div className={styles.preTitle}>
            Всероссийское военно-патриотическое общественное движение "ЮНАРМИЯ"
          </div>
          <div className={styles.title}>
            Система аналитической отчетности и ввода данных
          </div>
        </div>
        <div className={styles.content}>
          {user && (
            <div className={styles.mainContent}>
              <div className={styles.avatar}>
                <div className={styles.photoBlock}>
                  <span className={styles.editBlock} onClick={handleEdit}>
                    <i className={styles.edit}>
                      {isShow ? <IoIosClose /> : <IoMdCreate />}
                    </i>
                  </span>
                  <div className={styles.avatarBlock}>
                    {loading ? (
                      <Loader top="32%" left="35%" color="#f2c689" />
                    ) : (
                      <img
                        src={
                          user.imageurl
                            ? `${BACKEND_DOMAIN || ""}${user.imageurl}`
                            : avatar
                        }
                        alt="о_О"
                      />
                    )}
                  </div>
                  {isShow && (
                    <>
                      <div className={styles.input__wrapper}>
                        <input
                          name="file"
                          type="file"
                          id="input__file"
                          className={`${styles.input}  ${styles.input__file}`}
                          onChange={(e) => handleLoad(e)}
                          accept="image/jpeg,image/png"
                        />
                        <label
                          for="input__file"
                          className={styles.input__file_button}
                        >
                          <span className={styles.input__file_icon_wrapper}>
                            <IoMdDownload />
                          </span>
                          <span className={styles.input__file_button_text}>
                            обновить
                          </span>
                        </label>
                      </div>

                      {user.imageurl && (
                        <button
                          className={styles.button}
                          onClick={() => handleDel(id)}
                        >
                          <i className={styles.trash}>
                            <IoMdTrash />
                          </i>
                          <span className={styles.delTxt}>удалить</span>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={styles.lines}>
                <div>
                  <div>{user.displayname}</div>
                  <div>{user.position}</div>
                </div>
                {token && (
                  <div
                    className={styles.exit}
                    onClick={() => signAuthAction(history)}
                  >
                    выход
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {!!token && (
          <svg
            viewBox="0 0 100 80"
            width="40"
            height="40"
            className={styles.burgerMenu}
            onClick={() => {
              setVisibleMenu(!visibleMenu);
              setIndex(null);
            }}
          >
            <rect width="100" height="20" fill="white" fillOpacity={0.8}></rect>
            <rect
              y="30"
              width="100"
              height="20"
              fill="white"
              fillOpacity={0.8}
            ></rect>
            <rect
              y="60"
              width="100"
              height="20"
              fill="white"
              fillOpacity={0.8}
            ></rect>
          </svg>
        )}
      </div>
      {token && (
        <div className={styles.menu + " " + (!!visibleMenu && styles.active)}>
          {user && (
          <div className={styles.mobInfoBlock}>
          <div className={styles.avatar}>
                <div className={styles.photoBlockMob}>
                  <span className={styles.editBlock} onClick={handleEdit}>
                    <i className={styles.edit}>
                      {isShow ? <IoIosClose /> : <IoMdCreate />}
                    </i>
                  </span>
                  <div className={styles.avatarBlockMob}>
                    {loading ? (
                      <Loader top="32%" left="35%" color="#f2c689" />
                    ) : (
                      <img
                        src={
                          user.imageurl
                            ? `${BACKEND_DOMAIN || ""}${user.imageurl}`
                            : avatar
                        }
                        alt="о_О"
                      />
                    )}
                  </div>
                  {isShow && (
                    <>
                      <div className={styles.input__wrapper}>
                        <input
                          name="file"
                          type="file"
                          id="input__file"
                          className={`${styles.input}  ${styles.input__file}`}
                          onChange={(e) => handleLoad(e)}
                          accept="image/jpeg,image/png"
                        />
                        <label
                          for="input__file"
                          className={styles.input__file_button}
                        >
                          <span className={styles.input__file_icon_wrapper}>
                            <IoMdDownload />
                          </span>
                          <span className={styles.input__file_button_text}>
                            обновить
                          </span>
                        </label>
                      </div>

                      {user.imageurl && (
                        <button
                          className={styles.button}
                          onClick={() => handleDel(id)}
                        >
                          <i className={styles.trash}>
                            <IoMdTrash />
                          </i>
                          <span className={styles.delTxt}>удалить</span>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
          <div className={styles.mobTames}>
            <div>{user.displayname}</div>
            <div>{user.position}</div>
          </div>
          </div>
          )}
          {items.map((item, i) => (
              (!superuser && i===8) ? null :
              <div className={styles.menuItem} key={item}>
                <div
                  className={
                    i === index ? styles.item + " " + styles.active : styles.item
                  }
                  onMouseEnter={() => !visibleMenu && setIndex(i)}
                  onClick={() => handleOpenItem(i)}
                  onMouseLeave={() => !visibleMenu && setIndex(null)}
                >
                  {item === "видеоконференция" ? (
                    <a
                      href="https://sdo.yunarmy.ru/"
                      target="_blank"
                      rel="noreferrer"
                      className={styles.conf}
                    >{width >= 1200 ? 'Видеоконференция' : 'Видео-конференция'}</a>
                  ) : (
                    item
                  )}
                </div>
                {i === index && (
                  <div
                    className={styles.menuBox}
                    onMouseEnter={() => setIndex(i)}
                    onMouseLeave={() => setIndex(null)}
                    onClick={() => setIndex(null)}
                  >
                    {itemsItems[i].map(renderMenuItem)}
                  </div>
                )}
              </div>
            ))}
          {token && (
            <div
              className={styles.exitMob}
              onClick={() => signAuthAction(history)}
            >
              выход
            </div>
          )}
        </div>
      )}
      <div className={styles.wrapper}>{children}</div>
      <div className={styles.footer}>
        <div className={styles.version}>{version}</div>
        <div className={styles.footerText}>© {moment().year()} ООО Нинтегра</div>
      </div>
    </div>
  );
}

export default withRouter(
  connect(
    ({ auth: { token, user, superuser, id, loading } }) => ({
      token,
      user,
      superuser,
      agsh: user?.agsh,
      id,
      loading,
    }),
    {
      uploadUserImage,
      deleteUserImage,
      getYunarmianProfile,
      loadUser,
      sso,
      signAuthAction: signOut,
      authAction: auth,
    }
  )(Layout)
);
