import React from 'react'
import { ButtonRed } from '../ButtonRed/ButtonRed'
import styles from './Confirmation.module.css'

export default function Confirmation({
    absolute = true,
    top = "",
    left = "",
    confirmation = "",
    title = "Вы уверены что хотите удалить",
    color = '#000',
    acceptConfirmation = () => {},
    rejectConfirmation = () => {}    
}) {
  return (
    <div className={absolute ? styles.absolute : null}>
      <div className={styles.confirmation} style={absolute ? null : {position: "absolute", top : top, left : left}}>
      <div className={styles.textBlock} styles={{color: color}}>
        {title}
        {confirmation ? 
           <span> {confirmation} ?</span>
        :
        ""  
        }
        
      </div>
      <div className={styles.buttons}>
        <ButtonRed style={{backgroundColor: "rgb(117, 194, 181)", fontSize : "16px"}} onClick={() => acceptConfirmation(confirmation)}>Да</ButtonRed>
        <ButtonRed style={{backgroundColor: "rgb(117, 194, 181)", fontSize : "16px"}} onClick={() => rejectConfirmation()}>Нет</ButtonRed>
        </div> 
    </div>
    </div>
  )
}
