import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import { SET_DIGITAL_VIDEOS_COUNT, SET_DIGITAL_VIDEOS_DATA, SET_DIGITAL_LOADING, SET_DIGITAL_CATEGORY, ERROR } from "./types";
import { createNotification } from "../components/Notifications/Notifications";

export const getDigitalCount = (text) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const newsOnPage = getState().auth.itemsOnPage
    const params = {};
    text && ( params.text_title = text )
    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/digital_academy/count?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const pageCount = Math.ceil(data / newsOnPage);
      dispatch({
        type: SET_DIGITAL_VIDEOS_COUNT,
        payload: { data: data, pageCount: pageCount },
      });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getDigitalItems =
  (page=1, sort, text) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const newsOnPage = getState().auth.itemsOnPage
      const offset = page * newsOnPage - newsOnPage;
      const params = {
        offset: offset,
        limit: newsOnPage
      }
      sort && ( params.alph = sort )
      text && ( params.text_title = text )
      const urlParams = new URLSearchParams(params).toString();
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/digital_academy?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_DIGITAL_VIDEOS_DATA, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const getDigitalCategory = (page) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/digital_academy/category`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_DIGITAL_CATEGORY, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const deleteDigitalItem =
  (id, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/digital_academy/${id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getDigitalCount());
        dispatch(getDigitalItems(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const editDigitalItem = (data, page, removeFile, addFile, preview, removePreview) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const id = data.id
      delete data.id
      delete data.preview
      delete data.preview_id
      delete data.created
      delete data.imageurl
      const response = await fetch(`${BACKEND_URL_PREFIX}/digital_academy/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
    });
        if (response.ok) {
          removeFile && await dispatch(deleteFile(id, removeFile))
          addFile && await dispatch(uploadFile(id, addFile, preview))
          if (!removeFile && !addFile) {
            removePreview && await dispatch(deleteFile(id))
            preview && await dispatch(uploadPreview(id, preview))
          }  
          dispatch(getDigitalCount());
          dispatch(getDigitalItems(page));
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
  }
  
  export const addDigitalItem = (data, page, file, preview) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/digital_academy`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
          if (file) {
            const data = await response.json();
            await dispatch(uploadFile(data.id, file, preview));
          }
          dispatch(getDigitalCount());
          dispatch(getDigitalItems(page));
            
        } else {
            dispatch({ type: ERROR, payload: response });
        }
    }
  }

  export const uploadFile = (id, data, preview) => async (dispatch, getState) => {
    dispatch({type: SET_DIGITAL_LOADING, payload: true})
    await dispatch(checkAndUpdateToken())
  
    const fd = new FormData()
    fd.append('file', data.file)
  
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/digital_academy/${id}/image`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
        })
        if (response.ok) {
          createNotification('success', 'Файл успешно добавлен')
          preview && await dispatch(uploadPreview(id, preview))
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
    dispatch({type: SET_DIGITAL_LOADING, payload: false})
  }
  
  export const deleteFile = (id, video) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
        const fileType = video ? 'image' : 'preview'
        const response = await fetch(`${BACKEND_URL_PREFIX}/digital_academy/${id}/${fileType}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        })
        if (response.ok) {
          createNotification('success', 'Файл успешно удален')
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
  }

  export const uploadPreview = (id, file) => async (dispatch, getState) => {
    dispatch({type: SET_DIGITAL_LOADING, payload: true})
    await dispatch(checkAndUpdateToken())
  
    const fd = new FormData()
    fd.append('file', file)
  
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/digital_academy/${id}/preview`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
        })
        if (response.ok) {
          createNotification('success', 'Превью успешно добавлено')
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
    dispatch({type: SET_DIGITAL_LOADING, payload: false})
  }

  export const uploadBigdFile = (id, data, preview) => async (dispatch, getState) => {
    dispatch({type: SET_DIGITAL_LOADING, payload: true})
    await dispatch(checkAndUpdateToken())
  
    const fd = new FormData()
    fd.append('file', data.file)
  
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/digital_academy/${id}/image`, {
            method: "POST",
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
            body: fd,
        })
        if (response.ok) {
          createNotification('success', 'Файл успешно добавлен')
          preview && await dispatch(uploadPreview(id, preview))
        } else {
          dispatch({ type: ERROR, payload: response });
        }
    }
    dispatch({type: SET_DIGITAL_LOADING, payload: false})
  }