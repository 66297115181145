import React, { useState } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames';


const InterceptComponent = () => {
  
  const originalFetch = window.fetch;


  window.fetch = async (...args) => {
    let [resource, config] = args;
    let response = await originalFetch(resource, config);
    if (response.status === 502 || response.status === 503) setFail(true);
    // if (response.status === 200) setFail(true);
    return response;
  };
  
  const [fail, setFail] = useState(false)
  
  return (
    <div className={classNames(styles.wrapper, fail && styles.visible)}>
      <div>
        <h1>Сервис временно недоступен</h1>
        <button onClick={() => setFail(false)}>OK</button>
      </div>
    </div>
  )
}

export default InterceptComponent