import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import { ERROR, REMOVE_QUEST_TASKS, SET_QUESTS_COUNT, SET_QUESTS_DATA } from "./types.js";


export const removeQuestTasks = () => ({type: REMOVE_QUEST_TASKS})

export const getQuestsCount = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const newsOnPage = getState().auth.itemsOnPage
    const response = await fetch(`${BACKEND_URL_PREFIX}/quest/count`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const pageCount = Math.ceil(data / newsOnPage);
      dispatch({
        type: SET_QUESTS_COUNT,
        payload: { data: data, pageCount: pageCount },
      });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getQuestsObject =
  (page=1, sort) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const newsOnPage = getState().auth.itemsOnPage
      const offset = page * newsOnPage - newsOnPage;
      const params = {
        offset: offset,
        limit: newsOnPage
      }
      sort && ( params.alph = sort )
      const urlParams = new URLSearchParams(params).toString();
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/quest?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch({ type: SET_QUESTS_DATA, payload: data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteQuestsObject =
  (id, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/quest/${id}`, {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        dispatch(getQuestsCount());
        dispatch(getQuestsObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const editQuestsObject =
  (id, data, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/quest/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getQuestsObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const addQuestsObject =
  (data, numberPage) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/quest`, {
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        dispatch(getQuestsCount());
        dispatch(getQuestsObject(numberPage));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };