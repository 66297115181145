import React from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { setItemsOnPage } from '../../actions/auth'
import { itemsCount } from '../../utils/const'



export default function ItemsPerPage () {

  const dispatch = useDispatch()
  const { itemsOnPage } = useSelector(state => state.auth)

  const onChange = (e) => {
    dispatch(setItemsOnPage(e.target.value))
  }
  
  return (
    <div className={styles.itemsCount}>
      <select
        value={itemsOnPage}
        onChange={onChange}
      >
        {itemsCount.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
      <span>- строк на стр.</span>
    </div>
  )
}
