import React, { useCallback, useEffect, useState } from "react";
import styles from "./MatchQuestion.module.css";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Container } from "./Container";
import { Item } from "./Item";

export function MatchQuestion({
  question = [],
  answer = [],
  images = [],
  setOkey = () => {},
}) {
  const [_answers, setAnswers] = useState([...answer]);
  const [droppedItems, setDroppedItems] = useState([]);

  const isDropped = (name) => droppedItems.indexOf(name) > -1;

  useEffect(() => {
    const newArr = [...answer].map((item) => ({
      ...item,
      lastDroppedItem: null,
    }));
    setAnswers(newArr);
    setDroppedItems([]);
  }, [answer]);

  useEffect(() => {
    _answers.length === droppedItems.length && setOkey(true);
  }, [_answers]);

  const updateDropped = useCallback(
    (item) => {
      setDroppedItems((prev) => {
        let tmp = [...prev];
        const idx = tmp.indexOf(item.image);
        if (idx > -1) {
          tmp = tmp.filter((_, i) => i !== idx);
        } else tmp.push(item.image);
        return [...tmp];
      });
    },
    [setDroppedItems]
  );

  const handleDrop = useCallback(
    (index, item) => {
      updateDropped(item);
      setAnswers((prev) => {
          prev[index].lastDroppedItem = item.image;
        return [...prev];
      });
      if (answer[index].correctItem !== item.type) {
        setTimeout(() => {
          updateDropped(item);
          setAnswers((prev) => {
            prev[index].lastDroppedItem = null;
            return [...prev];
          });
        }, 300);
      }
  },
    [setAnswers, setDroppedItems, answer]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.wrapper}>
        <div className={styles.question}>{question}</div>
        <div className={styles.answer}>
          {_answers.map(({ item, lastDroppedItem, accepts }, idx) => (
            <Container
              item={item}
              lastDroppedItem={lastDroppedItem}
              accepts={accepts}
              onDrop={(item) => handleDrop(idx, item)}
              key={`${idx}`}
            />
          ))}
        </div>
        <div className={styles.variants}>
          {images
            .sort(() => Math.random() - 0.5)
            .map(({ type, image }, idx) => (
              <Item
                type={type}
                image={image}
                isDropped={isDropped(image)}
                key={`${idx}`}
              />
            ))}
        </div>
      </div>
    </DndProvider>
  );
}
