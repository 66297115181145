/* eslint-disable */
import React, {  useState, useEffect } from "react";
import { connect } from "react-redux";
import { getDepartments, deleteDepartment, editDepartment, addDepartment, uploadFile, deleteFile, getRegions } from "../../actions/departments";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import deleteIcon from "../../assets/images/delete.png";
import LocalDepartmentsModal from "./LocalDepartmentsModal";
import styles from "./LocalDepartments.module.css"
import CustomSelect from "../../components/CustomSelect/CustomSelect"
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose } from "react-icons/io";
import { getAllYunarmiansData, removeDataForPrint } from "../../actions/yunarmiyans";
import * as usersActions from "../../actions/users";
import { createNotification } from "../../components/Notifications/Notifications";
import { getRegionDepsOptions, getUserRegionDepsOptions } from "../../utils/getOptions";
import { RiFileExcel2Line, RiLoader3Fill } from "react-icons/ri";
import { getExcelFromTable } from "../../utils/excel";
import Select from "react-select";

const LocalDepartments = ({
  regionDep,
  localDep,
  getDepartments,
  deleteDepartment,
  editDepartment,
  addDepartment,
  uploadFile,
  deleteFile,
  loading,
  getRegions,
  id,
  imgLoad,
  getAllYunarmiansData,
  removeDataForPrint,
  profiles,
  history,
  getAllRegions,
  regions,
  accessRights,
  userRegions
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null)
  const [parentDep, setParentDep] = useState(0)
  const [regionId, setRegionId] = useState(null)

  useEffect(() => {
    getDepartments();
    getAllRegions()
  }, []);

  useEffect(() => {
    getRegions(id);
  }, [id]);

  useEffect(() => {
    if (regionDep && regionDep.length) {
    const usersRegionDep = regionDep.filter(rd =>
      userRegions.includes(rd.regionId)
    )
    setParentDep(usersRegionDep[0]?.id)
    setRegionId(usersRegionDep[0]?.regionId)
    }
  }, [regionDep])
  
  
  useEffect(() => {
    parentDep && 
    getDepartments(parentDep)
  }, [parentDep, imgLoad])  

  const openModal = (type, item) => () => {
    const readRights = accessRights.ac_read || (accessRights.ac_read_by_region && userRegions.includes(item?.regionId))
    
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(type==='ADD'||readRights)
    !readRights && type!=='ADD' && createNotification('error', 'Доступ к данному местному отделению отсутствует')
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleSelectDep = (e) => {
    const value = e.value
    const regionId = regionDep.find(rd => rd.id==value).regionId
    setParentDep(value)
    setRegionId(regionId)
  }
  
  const handleExel = () => {
    const table = document.getElementById('localDepsTable')
    getExcelFromTable(table, 'Местные отделения')
  }

  const getNormalizeRegionDep = (list) => {
    if (list && list.length) {
      const newList = list.map(rd =>({label: rd.name, value: rd.id}))
      return newList
    }
  }

  const getNormalizeUserRegionDepsOptions = (regionDepsArr, userRegions) => regionDepsArr
    ? regionDepsArr
        .filter(regionDep => userRegions.includes(regionDep.regionId))
        .map(regionDep => ({ label: regionDep.name, value: regionDep.id }))
    : []

  const excelTable = (
    <table hidden id='localDepsTable'>
      <thead>
        <tr>
          <td>Наименование</td>
          <td>Населенный пункт</td>
          <td>Адрес</td>
          <td>Руководитель</td>
          <td>Должность рук-ля</td>
          <td>Телефон рук-ля</td>
          <td>Email рук-ля</td>
          <td>Основание для создания</td>
        </tr>
      </thead>
      <tbody>
        {localDep?.map(dep => (
          <tr>
           <td>{dep.name}</td> 
           <td>{dep.city}</td> 
           <td>{dep.address}</td> 
           <td>{dep.chief}</td> 
           <td>{dep.position}</td> 
           <td>{dep.phone}</td> 
           <td>{dep.email}</td> 
           <td>{dep.decision}</td> 
          </tr>
        ))}
      </tbody>
    </table>
  )

  return (
    <>
      <div className={styles.container}>
        {excelTable}
        <div className={styles.title}>Реестр местных отделений</div>
        <div className={styles.newsList}>
        <div className={styles.newsListHeader}>
          {(accessRights.ac_create || accessRights.ac_create_by_region) && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
          {!!localDep?.length && 
            <div className={styles.downloadExcel} onClick={handleExel}>
            <i className={styles.excelLogo}><RiFileExcel2Line/></i><span>Скачать .xlsx</span>
            {/* {!!excelLoading && <i className={styles.excelLoad}><RiLoader3Fill/></i>} */}
          </div>}
          </div>
          <div style={{width: "100%", margin: "10px 0", maxWidth: '600px'}}>
            <Select
            placeholder="Выберите региональное отделение"
            options={accessRights.ac_create ? getNormalizeRegionDep(regionDep) : getNormalizeUserRegionDepsOptions(regionDep, userRegions)}
            value={accessRights.ac_create ? 
                getNormalizeRegionDep(regionDep)?.find(option => option.value === parentDep) : 
                getNormalizeUserRegionDepsOptions(regionDep, userRegions)?.find(option => option.value === parentDep)}
            onChange={handleSelectDep}
            noOptionsMessage={() => "ничего не найдено"}
            defaultValue={accessRights.ac_create ? 
              getNormalizeRegionDep(regionDep)?.[0] : 
              getNormalizeUserRegionDepsOptions(regionDep, userRegions)?.[0]}
          />
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.firstTd}>№</div>
            <div className={styles.displayname}>Наименование</div>
            <div className={styles.thirdTd}>ФИО начальника</div>
            <div className={styles.fourthTd}>Телефон</div>
            <div className={styles.superuser}>Кол-во отрядов</div>
          </div>
          <div className={styles.table}>
            {localDep?.map((a, index) => {
                const deleteRights = accessRights.ac_delete || (accessRights.ac_delete_by_region && userRegions.includes(a.regionId))
                const number =  index + 1 
                return (
                  <div key={a.id} onClick={openModal("EDIT", a)} className={styles.tableRow}>
                    <div className={styles.firstTd}>{number}</div>
                    <div className={styles.displayname}>
                      <span>{a.name}</span>
                    </div>
                    <div className={styles.thirdTd}>{a.chief}</div>
                    <div className={styles.fourthTd}>{a.phone}</div>
                    <div className={styles.superuser}>{a.count || 0}</div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <LocalDepartmentsModal
        open={isModalOpen}
        type={modalType}
        parentVal={parentDep}
        regionId={regionId}
        onClose={closeModal}
        selectedItem={selectedItem}
        editDepartment={editDepartment}
        addDepartment={addDepartment}
        parentDep={parentDep}
        deleteDepartment={deleteDepartment}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        data={localDep}
        loading={loading}
        getAllYunarmiansData={getAllYunarmiansData}
        removeDataForPrint={removeDataForPrint}
        profiles={profiles}
        history={history}
        regions={regions}
        accessRights={accessRights}
        userRegions={userRegions}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  regionDep: state.departments.region_departments,
  localDep: state.departments.local_departments,
  loading: state.departments.loading,
  agsh: state.auth.user.agsh,
  nrsh: state.auth.user.nrsh,
  superuser: state.auth.superuser,
  depId: state.auth.user.department_id,
  regs: state.departments.regs,
  id: state.auth.id,
  profiles: state.yunarmians.all_profiles,
  imgLoad: state.departments.imgLoad,
  regions: state.users.regionList,
  accessRights: state.auth.accessRights.find(i => i.name==='domestic_departments')||{},
  userRegions: state.auth.userRegions
})

const mapDispatchToProps = (dispatch) =>  ({
  getDepartments: (id) => dispatch(getDepartments(id)),
  deleteDepartment: (id, parentDep) => dispatch(deleteDepartment(id, parentDep)),
  editDepartment: (id, item, parentVal) => dispatch(editDepartment(id, item, parentVal)),
  addDepartment: (obj, parentVal, bool, file) => dispatch(addDepartment(obj, parentVal, bool, file)),
  uploadFile: (id, file, parent) => dispatch(uploadFile(id, file, parent)),
  deleteFile: (id) => dispatch(deleteFile(id)),
  getRegions: id => dispatch(getRegions(id)),
  getAllRegions: () => dispatch(usersActions.getRegions()),
  getAllYunarmiansData: (filters) => dispatch(getAllYunarmiansData(filters)),
  removeDataForPrint: () => dispatch(removeDataForPrint())
})

export default connect(mapStateToProps, mapDispatchToProps)(
    LocalDepartments
);
