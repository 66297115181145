import React, { useState } from "react";
import { April12 } from "./April12/April12";
import { February23 } from "./February23/February23";
import { June12 } from "./June12/June12";
import styles from "./Test.module.css";

const dates = ["23 февраля", "12 апреля", "9 мая", "12 июня"];


export function Test() {
  
  const [view, setView] = useState(null);
  const COMPONENT = [
    <February23 setView={(val) => setView(val)}/>,
    <April12 setView={(val) => setView(val)} />,
    <February23 setView={(val) => setView(val)} />,
    <June12 />
    ]

  return (
    <div className={styles.wrapper}>
      {view === null ? (
        <div style={{ width: "100%" }}>
          <h1>Выберите праздник, по которому вы хотели бы пройти тест:</h1>
          <div className={styles.buttons}>
            {dates.map((item, i) => (
              <div key={i} onClick={() => setView(i)}>
                {item}
              </div>
            ))}
          </div>
        </div>
      ) : (
        COMPONENT[view]
      )}
    </div>
  );
}
