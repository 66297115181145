import React from "react";
import styles from "./FormCheckbox.module.css"
import { MdCheck } from "react-icons/md";


const FormCheckbox = ({
    editMode,
    value,
    name,
    title,
    onChange
}) => {
  return (
    <div className={styles.wrapper}>
      <div>{title}</div>
      <label className={styles.checkbox}>
        <input disabled={!editMode} type="checkbox" name={name} checked={value} onChange={onChange} />
        <i className={styles.fakeBox}>{value && <MdCheck size={20}/>}</i>
      </label>
    </div>
  );
};

export default FormCheckbox;
