import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { DOC_TYPES, NAME_SCREENS } from "../../utils/const";
import {
  getDocTypes,
  getDocuments,
  deleteDocument,
  addDocument,
  editDocument,
  getFilesList,
  uploadFile,
  deleteFile,
} from "../../actions/documents";
import DocumentsModal from "./DocumentsModal.jsx";
import styles from "./Documents.module.css";
import { Pagination } from "../../assets/common/Pagination/Pagination";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import { IoMdClose } from "react-icons/io";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import ItemsPerPage from "../../components/ItemsPerPage/index.jsx";



function Documents({
  history,
  pageCount,
  documents,
  getDocuments,
  getDocTypes,
  deleteDocument,
  typesList,
  addDocument,
  editDocument,
  getFilesList,
  filesList,
  uploadFile,
  deleteFile,
  sUser,
  itemsOnPage,
  accessRights,
}) {
  
  const deleteRights = accessRights.ac_delete
  
  const [numberPage, setNumberPage] = useState(0);
  const [article, setArticle] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [filter, setFilter] = useState('')
  const [sort, setSort] = useState("");


  useEffect(() => {
    getDocTypes();
  }, []);

  useEffect(() => {
    setArticle(DOC_TYPES[history.location.pathname]);
    setNumberPage(0);
    setFilter('');
  }, [history.location.pathname]);

  useEffect(() => {
      article && getDocuments(article, numberPage, sort, filter);
  }, [article, numberPage, itemsOnPage, sort, filter]);

  const openModal = (type, item) => () => {
    setSelectedItem(item);
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteDocument = (id) => {
    deleteDocument(id, article, numberPage, sort, filter);
  };

  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const acceptConfirmation = () => {
    handleDeleteDocument(confirmation.id);
    setConfirmation(null);
  };

  const setFilterValue = e => setFilter(e.target.value)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          {NAME_SCREENS[history.location.pathname]}
        </div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
            {accessRights.ac_create && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
            <select className={styles.filterSelect} value={filter} onChange={setFilterValue}>
              <option value=''>Все</option>
              <option value='0'>Действующий</option>
              <option value='1'>Не действующий</option>
            </select>
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Документ</div>
            <div className={styles.username}></div>
            <div className={styles.superuser}>Состояние</div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
            {documents?.map(doc => {
              const { id, name, groupname, is_expired } = doc
              const status = is_expired ? 'Не действующий' : 'Действующий'
              const currentColor = is_expired ? 'colorRed' : 'colorGreen'
              return (
                <div key={id} className={styles.tableRow}>
                  <div className={styles.displayname} onClick={openModal("EDIT", doc)}>
                    <span>{name}</span>
                  </div>
                  <div className={styles.username}>{groupname}</div>
                  <div className={`${styles.superuser} ${styles[currentColor]}`}>{status}</div>
                  <div className={styles.actions}>
                    {deleteRights
                      ? <IoMdClose onClick={() => setConfirmation({ text: name, id })}/>
                      : <div style={{height:'1.5rem'}}></div>
                    }
                  </div>
                </div>
              )
            })}
          </div>
          <div className={styles.pagination}>
            <Pagination
              pageCount={pageCount}
              numberPage={numberPage}
              onPageChange={handlePageClick}
            />
          </div>
          <div className={styles.controls}>
            <div
              onClick={handleSortClick}
              className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
            </div>
          <ItemsPerPage/>
          </div>
        </div>
      </div>
      {confirmation && (
        <Confirmation
          confirmation={confirmation.text}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      )}
      <DocumentsModal
        sUser={sUser}
        history={history}
        numberPage={numberPage}
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        accessRights={accessRights}
        typesList={typesList}
        article={article}
        addDocument={addDocument}
        editDocument={editDocument}
        getFilesList={getFilesList}
        filesList={filesList}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        sort={sort}
        filter={filter}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  documents: state.documents.data,
  typesList: state.documents.doctypes,
  pageCount: state.documents.page_count,
  filesList: state.documents.files_list,
  lastUpdate: state.schedule.lastUpdate,
  sUser: state.auth.superuser,
  itemsOnPage: state.auth.itemsOnPage,
  accessRights: state.auth.accessRights.find(i => i.name==='npa')||{}
});

export default connect(mapStateToProps, {
  getDocuments,
  deleteDocument,
  getDocTypes,
  addDocument,
  editDocument,
  getFilesList,
  uploadFile,
  deleteFile,
})(Documents);
