import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import deleteIcon from "../../assets/images/delete.png";
import { BACKEND_DOMAIN, BACKEND_URL_PREFIX } from "../../utils/const";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import styles from "./MethodicMaterials.module.css";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { IoIosCloseCircle } from "react-icons/io";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";




const MethodicMaterialsModal = ({
  type,
  selectedItem,
  addMaterial,
  editMaterial,
  groups,
  getFilesList,
  filesList,
  accessRights,
  uploadFile,
  deleteFile,
  ...props
}) => {
  
  const updateRights = type === "ADD" || accessRights.ac_update
  
  const [item, setItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [_filesList, setFilesList] = useState([...filesList])
  const [addFilesList, setAddFilesList] = useState([])

  useEffect(() => {
    setFilesList(filesList)
  }, [filesList])
  
  useEffect(() => {
    if (type === "ADD") {
      setItem({ name: "", group: "0" });
    } else {
      if (selectedItem) {
        setItem({...selectedItem, deletedFiles: []});
        getFilesList(selectedItem.id);
      }
    }
  }, [selectedItem]);


  const addMaterialItem = () => {
    addMaterial({ name: item.name, group: item.group, for_kids: item.for_kids }, addFilesList);
    props.onClose();
  };

  const editMaterialItem = () => {
    editMaterial(item.id, item, addFilesList);
    props.onClose();
  };

  const handleUploadFile = (e) => {
    if (e.target.files) {
      const files = Object.values(e.target.files)
      setAddFilesList(files)
    }
  };

  const handleDeleteFile = (ind) => {
    const name = _filesList[ind].file
    setConfirmation({name: name, index: ind});
  };

  const acceptConfirmation = (name) => {
    const arr = [..._filesList]
    arr.splice(confirmation.index, 1)
    setItem(prev => ({...prev, deletedFiles: [...prev.deletedFiles, name]}))
    setFilesList(arr)
    setConfirmation(null)
  };

  const rejectConfirmation = () => {
    setConfirmation(null)
  };


  const deleteCurrentFile = (ind) => {
    const arr = [...addFilesList]
    arr.splice(ind, 1)
    setAddFilesList(arr)
  }

  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation.name}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <div className={styles.title}>
        {type === "ADD" ? "Добавление материала" : "Редактирование материала"}
      </div>
      {item && (
        <div className={styles.modalBody}>
          <div className={styles.modalMain}>
            <CustomInput
              value={item.name}
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, name: e.target.value }))
              }
              title="Название"
            />
            <CustomSelect
              title="Группа методического материала"
              disabled={!updateRights}
              onChange={(e) =>
                setItem((prev) => ({ ...prev, group: e.target.value }))
              }
              selectedValue={item.group}
              options={
                groups && groups.map((it) => ({ name: it.name, value: it.id }))
              }
            />
            <CustomCheckbox
                label={'Отображать в мобильном приложении для детей'}
                value={item?.for_kids}
                disabled={!updateRights}
                onChange={() => setItem(prev => ({...prev, for_kids: !prev.for_kids}))}
                />
              <div className={styles.filesBlock}>
                {type!=="ADD" && filesList && (
                  <div className={styles.filesList}>
                    {_filesList.map((it,i) => (
                      <div key={i} className={styles.fileItem}>
                        <a
                          href={`${BACKEND_DOMAIN || ""}/files/teachingmaterial/${item.id}/${it.file}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          {it.file}
                        </a>
                        <div
                          className={styles.imgBlock}
                          onClick={() => handleDeleteFile(i)}
                        >
                          <img src={deleteIcon} alt="o_O" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div className={styles.addFilesList}>
                      {
                        !!addFilesList.length && addFilesList.map((item, i) => <div key={i}>
                          <span>{item.name}</span>
                          <span onClick={() => deleteCurrentFile(i)}><IoIosCloseCircle size={16}/></span>
                        </div>)
                      }
                </div>
                <div className={styles.inputBlock}>
                  <InputFile
                    multiple
                    title="Выберите файл для загрузки"
                    onChange={(e) => handleUploadFile(e)}
                    disabled={!updateRights}
                  />
                </div>
              </div>
          </div>
          <div className={styles.modalButtons}>
            {updateRights &&
            <ButtonRed
              onClick={type === "ADD" ? addMaterialItem : editMaterialItem}
            >
              {type === "ADD" ? "Добавить" : "Сохранить"}
            </ButtonRed>}
            <ButtonRed onClick={props.onClose}>Закрыть</ButtonRed>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MethodicMaterialsModal;
