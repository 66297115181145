import React, {useState, useEffect} from 'react'
import styles from "./About.module.css";
import { connect } from "react-redux";
import {getAboutUnarmians, editAboutUnarmians, uploadPhotoAboutUnarmians, deletePhotoAboutUnarmians} from '../../actions/about';
import Loader from '../../components/Loader/Loader';
import {useHistory} from 'react-router-dom';
import { Quill } from '../../components/Quill/index';
import {Slider} from './Slider';
import { InputFile } from "../../assets/common/InputFile/InputFile"
import { IoMdTrash, IoIosPaper } from 'react-icons/io';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.css';
import { BACKEND_DOMAIN } from '../../utils/const';

const About = ({
    getAboutUnarmians,
    data,
    loading,
    editAboutUnarmians,
    uploadPhotoAboutUnarmians,
    deletePhotoAboutUnarmians,
    photos = []
}) => {
    const [item, setItem] = useState(null);
    const history = useHistory();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [toDeleteImages, setToDeleteImage] = useState([])
    const [imagesPreviews, setImagesPreviews] = useState([])

    const deletePhoto = (photo) => {
        if (!/^blob/g.test(photo)) {
          setToDeleteImage(prev => [...prev, photo])
        } else {
          const file = imagesPreviews.find(el => el.url === photo)
    
          URL.revokeObjectURL(file)
    
          setSelectedFiles(selectedFiles.filter(el => el.url !== photo))
        }
    
        setImagesPreviews(imagesPreviews.filter(name => name !== photo))
      };
    
      const renderPhotos = (source) => {
        return source.map((photo) => {
          return (
            <SwiperSlide key={photo}>
              <div className={`photoWrapper`}>
                <i className={styles.deletePhoto} onClick={() => deletePhoto(photo)}>
                  <IoMdTrash />
                </i>
                <img
                  src={photo}
                  alt="фото"
                  className={styles.imgUploder}
                />
              </div>
            </SwiperSlide>
          )
        })
      }

      
  const handleUploadImage = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map(file => ({ file, url: URL.createObjectURL(file) }));
      setSelectedFiles(prev => [...prev, ...filesArray]);
      setImagesPreviews(prev => [...prev, ...filesArray.map(el => el.url)])
    }
  };

    useEffect(() => {
      getAboutUnarmians();
      return () => {
        setItem(null);
      }
    }, [])
    

    useEffect(() => {
      setToDeleteImage([]);
      setSelectedFiles([]);
      setImagesPreviews([]);
      setItem(prev => ({...prev, title: data?.about[0]?.title, description: data?.about[0]?.description}));
        if (Array.isArray(data && data.about[0].images_list)) {
          setImagesPreviews(data.about[0].images.map(el => `${BACKEND_DOMAIN || ""}/${el.path}/${el.name}`))
        }
    }, [data])
    
    const handleClose = () => {
    history.goBack();
    setItem(null);
    } 

    const handlePost = () => {
        editAboutUnarmians(item);
        if (selectedFiles.length > 0) {
          selectedFiles.forEach(el => uploadPhotoAboutUnarmians({ file: el.file }));
        } 

        if (toDeleteImages.length > 0 && data && data.about[0].images_list) {
          const deleteArray = toDeleteImages.map(el => data.about[0].images.find(item => el.split('/')[el.split('/').length - 1] == item.name).id)
          deleteArray.forEach(el => deletePhotoAboutUnarmians(el));
        }
    }


    return (
    <div className={styles.aboutWrapper}>
        <p className={styles.aboutTitle}>О Юнармии. Редактирование.</p>
        {
            loading ?
            <Loader top="50%" left="50%" />
            :
            <div className={styles.quillWrapper}>
            <div className={styles.inputBlock}>
                <span className={styles.title}>Заголовок</span>
                <input type="text" className={styles.input} value={item?.title || ''}  onChange={e => setItem(prev => ({...prev, title: e.target.value}))}/>
            </div>
    
            <span className={styles.title}>Описание</span>
                    <Quill
                        uniq={1}
                        value={item?.description || ''}
                        onChange={e => setItem(prev => ({...prev, description: e}))}
                    />
            
            <span className={styles.titleSlider}>Слайдер</span>
            <div className={styles.wrapperSliderAbout}>
                  {
                    !loading ? 
                    (imagesPreviews && imagesPreviews.length > 0 ? 
                    <>
                    <Slider>{imagesPreviews.length > 0 && renderPhotos(imagesPreviews)}</Slider>
                    <div className={styles.wrapperBut}>
                    <InputFile
                        multiple
                        title="Выберите изображение"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => handleUploadImage(e)}
                    />
                    </div>
                    </>
                    :
                    <div>
                        <i className={styles.paper}><IoIosPaper/></i>
                         <InputFile
                            multiple
                            title="Выберите изображение"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => handleUploadImage(e)}
                          />
                    </div>
                    )
                    :
                    <div className={styles.load}><Loader top="30px" left="40%"/></div>
                    }
                  
                <div className={styles.wrapperSliderEquipmentSmall}>
                </div>
              </div>

            <div className={styles.buttons}>
                <button className={styles.button} onClick={handlePost}>Сохранить</button>
                <button className={styles.button} onClick={handleClose}>Закрыть</button>
            </div>
            </div>
        }
        
    </div>
  )
}

const mapStateToProps = (state) => ({
    data: state.about.about,
    loading: state.about.loading,
  });

export default connect(mapStateToProps, {getAboutUnarmians, editAboutUnarmians, uploadPhotoAboutUnarmians, deletePhotoAboutUnarmians})(About);