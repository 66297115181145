import React from 'react'
import plug from "../../assets/images/plug.png"



export default function Plug() {
  return (
    <div style={{marginTop: "50px"}}>
        <img src={plug} alt="o_O" style={{opacity: 0.7}}/>
    </div>
  )
}
