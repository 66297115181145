import React, { useState, useEffect } from "react";
import { filters, yunarmiansOnPageList } from "../../utils/const";
import styles from "./Unarmiyans.module.css";
import { connect } from "react-redux";
import {
  getYunarmiansCount,
  getYunarmiansData,
  getAllYunarmiansData,
  getFilteredGroup,
  getFilteredLocalDep,
  removeFilteredGroup,
  removeFilteredLocalDep,
  setFilteredGroups,
  deleteFilteredGroups,
  setNumberPage,
  clearUnarmiansData,
  removeDataForPrint,
  editYunarmianGroup,
  getTroops,
  rotateDocumentImage,
  getDeps,
  massiveSendDocuments,
  massiveSendCustomDocument,
  getCountry,
  removeFilteredGroupCountry,
  deleteProfile
} from "../../actions/yunarmiyans";
import { getDepartments } from "../../actions/departments";
import { Pagination } from "../../assets/common/Pagination/Pagination";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import FilterItem from "./FilterItem/FilterItem";
import { IoIosSearch, IoMdClose } from "react-icons/io";
import { MdViewModule, MdViewHeadline } from "react-icons/md";
import Loader from "../../components/Loader/Loader";
import CurrentProfilesList from "./CurrentProfilesList";
import { createNotification } from "../../components/Notifications/Notifications";
import DistributionModal from "../../components/DistributionModal/DistributionModal";
import { access } from "fs";
import Confirmation from "../../assets/common/Confirmation/Confirmation";

function Unarmiyans({
  getYunarmiansData,
  getAllYunarmiansData,
  getFilteredGroup,
  getFilteredLocalDep,
  removeFilteredGroup,
  deleteFilteredGroups,
  removeFilteredLocalDep,
  removeDataForPrint,
  clearUnarmiansData,
  setNumberPage,
  deleteProfile,
  numberPage,
  pageCount,
  usersCount,
  data,
  filterRes,
  filteredGroups,
  departments,
  setFilteredGroups,
  getDepartments,
  history,
  allProfiles,
  superuser,
  loading,
  authData,
  modalLoading,
  pdfLoading,
  isBack,
  editYunarmianGroup,
  rotateDocumentImage,
  massiveSendCustomDocument,
  getTroops,
  massiveSendDocuments,
  troopList,
  depsList,
  getDeps,
  country,
  getCountry,
  removeFilteredGroupCountry,
  accessRights,
  userRegions
}) {

  const createRights = accessRights.ac_create || accessRights.ac_create_by_region 
  const updateRights = accessRights.ac_update || accessRights.ac_update_by_region 


  const [active, setActive] = useState([]);
  const [listView, setListView] = useState(false);
  const [ageList, setAgeList] = useState(filteredGroups.age);
  const [regList, setRegList] = useState([]);
  const [currentProfiles, setCurrentProfiles] = useState([]);
  const [print, setPrint] = useState(false);
  const [groupChanges, setGroupChanges] = useState(false);
  const [massiveSend, setMassiveSend] = useState(false)
  const [massiveCustomSend, setMassiveCustomSend] = useState(false)
  const [byCountry, setByCountry] = useState(false);
  const [newfilter, setNewfilter] = useState(null);
  const [abroad, setAbroad] = useState(false);
  const [confirmation, setConfirmation] = useState(null)


  useEffect(() => {
    if (!byCountry) {
      setNewfilter(filters.filter(el => el.group != 'country'));
    } else {
      setNewfilter(filters);
    }
  }, [byCountry, country])

  useEffect(() => {
    getDepartments();
    getCountry();
    // return () => {
    //   deleteFilteredGroups();
    // }
  }, []);

  useEffect(() => {
    if (massiveSend && allProfiles) {
      const profiles = allProfiles.map(profile => profile.id)
      massiveSendDocuments(profiles)
      setMassiveSend(false)
    }
  }, [massiveSend, allProfiles])
  

  useEffect(() => {
    if (isBack) {
      getYunarmiansData(numberPage+1, filteredGroups, listView);
    }
  }, [isBack]);

  const getData = (bool) => {
    setNumberPage(0);
    setListView(bool);
    getYunarmiansData(1, filteredGroups, bool);
  };

  const handleAge = (e, value) => {
    if (ageList.includes(String(value))) {
      setAgeList(ageList.filter((item) => item != value));
    } else {
      setAgeList((prev) => [...prev, value]);
    }
  };

  const handleReg = (e, value) => {
    if (regList.includes(value)) {
      setRegList(regList.filter((item) => item != value));
    } else {
      setRegList((prev) => [...prev, value]);
    }
  };

  useEffect(() => {
    setFilteredGroups({ ...filteredGroups, age: ageList });
  }, [ageList]);

  useEffect(() => {
    setFilteredGroups({ ...filteredGroups, regional_department_ids: regList });
  }, [regList]);

  const handlePageClick = (data) => {
    setNumberPage(data.selected);
    getYunarmiansData(data.selected + 1, filteredGroups, listView);
  };

  const acceptConfirm = () => {
    deleteProfileHandler(confirmation)
    setConfirmation(null)
  }

  const rejectConfirm = () => {
    setConfirmation(null)
  }

  const haveReadRights = (profile) => accessRights.ac_read || (accessRights.ac_read_by_region && userRegions.includes(profile.regionId))

  const toggleActive = (index) => {
    if (active.includes(index)) {
      let newArr = active.filter(function (f) {
        return f !== index;
      });
      setActive(newArr);
    } else {
      let newArr = [...active, index];
      setActive(newArr);
    }
  };

  const toggleCheckboxes = (name) => {    
    const newVal = !filteredGroups[name];
    const obj = { ...filteredGroups };
    obj[name] = newVal;
    setFilteredGroups(obj)
  };

  const fetchFilteredProfiles = () => {
    setNumberPage(0);
    getYunarmiansData(1, filteredGroups, listView);
  };

  const resetFilters = () => {
    setAbroad(false);
    setByCountry(false);
    deleteFilteredGroups();
    setAgeList([]);
    setRegList([]);
    clearUnarmiansData();
  };

  const deleteProfileHandler = async (id) => {
    await deleteProfile(id)
    getYunarmiansData(numberPage + 1, filteredGroups, listView);
  }

  const handleChangeInputFilter = (e) => {
    const obj = { ...filteredGroups };
    obj[e.target.name] = e.target.value;
    setFilteredGroups(obj);
  };

  const handleAddCurrentProfile = (profile) => {
    if (!currentProfiles.includes(profile)) {
      setCurrentProfiles((prev) => [...prev, profile]);
    }
  };

  const handleDeleteCurrentProfile = (ind) => {
    const arr = [...currentProfiles];
    arr.splice(ind, 1);
    setCurrentProfiles(arr);
  };

  const printAllProfiles = () => {
    getAllYunarmiansData();
    setPrint(true);
  };

  const changeAllProfiles = () => {
    if (usersCount < 4000) {
      getAllYunarmiansData();
      setGroupChanges(true);
      getDeps(authData.user.department_id);
      getTroops(authData.user.department_id);
    } else {
      createNotification("error", "Список слишком большой, выберите меньшее количество профилей")
    }
  };

  const documentsForAllProfiles = async (custom) => {
    if (usersCount < 1000) {
      await getAllYunarmiansData();
      custom ? setMassiveCustomSend(true) : setMassiveSend(true)
    } else {
      createNotification("error", "Список слишком большой, выберите меньшее количество профилей")
    }
  }

  return (
    <div className={styles.wrapper}>
      {confirmation && <Confirmation acceptConfirmation={acceptConfirm} rejectConfirmation={rejectConfirm} title='Вы уверены, что хотите удалить профиль?'/>}
      {(currentProfiles.length || groupChanges || print) &&
      <CurrentProfilesList
        departments={departments}
        print={print}
        setPrint={setPrint}
        groupChanges={groupChanges}
        setGroupChanges={setGroupChanges}
        allProfiles={allProfiles}
        currentProfiles={currentProfiles}
        setCurrentProfiles={setCurrentProfiles}
        handleDeleteCurrentProfile={handleDeleteCurrentProfile}
        authData={authData}
        removeDataForPrint={removeDataForPrint}
        editYunarmianGroup={editYunarmianGroup}
        updateRights={updateRights}
        getDeps={getDeps}
        massiveSendDocuments={massiveSendDocuments}
        depsList={depsList}
        getTroops={getTroops}
        troopList={troopList}
        page={numberPage+1}
        loading={pdfLoading}
      />}

      {massiveCustomSend &&
      <DistributionModal
        allProfiles={allProfiles}
        massiveSendCustomDocument={massiveSendCustomDocument}
        modalLoading={modalLoading}
        onClose={() => setMassiveCustomSend(false)}
      />}
      <div className={styles.filters}>
        <div className={styles.filterTitle}>
          Фильтр профилей
          <button
            onClick={fetchFilteredProfiles}
            className={`${styles.button__search} ${styles.filtersHeaderSearch}`}
          >
            <IoIosSearch size={22} />
            Искать
          </button>
        </div>
        <div className={styles.filtersList}>
          {newfilter?.map((it, i) => {
            return (
              <FilterItem
                actived={
                  (filteredGroups[it.group] &&
                    filteredGroups[it.group] != null &&
                    filteredGroups[it.group] != "") ||
                  (it.group === "age" && filteredGroups["is_adult"])
                    ? true
                    : ""
                }
                ageList={ageList}
                handleAge={handleAge}
                item={it}
                i={i}
                active={active}
                toggleActive={toggleActive}
                getFilteredGroup={getFilteredGroup}
                getFilteredLocalDep={getFilteredLocalDep}
                removeFilteredGroup={removeFilteredGroup}
                departments={departments}
                handleReg={handleReg}
                regList={regList}
                setRegList={setRegList}
                removeFilteredLocalDep={removeFilteredLocalDep}
                filterRes={filterRes}
                filteredGroups={filteredGroups}
                handleChangeInputFilter={handleChangeInputFilter}
                setFilteredGroups={setFilteredGroups}
                country={country}
                removeFilteredGroupCountry={removeFilteredGroupCountry}
                byCountry={byCountry}
                setByCountry={setByCountry}
                setActive={setActive}
                abroad={abroad} 
                setAbroad={setAbroad}
              />
            )
          })}
          <div className={`${styles.checkbox} ${styles.checkboxFirst}`}>
            <label>
              <input
                type="checkbox"
                checked={filteredGroups.noemail}
                onClick={() => toggleCheckboxes("noemail")}
              />
              <div>Нет E-mail</div>
            </label>
          </div>
          <div className={styles.checkbox}>
            <label>
              <input
                type="checkbox"
                checked={filteredGroups.nophone}
                onClick={() => toggleCheckboxes("nophone")}
              />
              <div>Нет телефона</div>
            </label>
          </div>
          <div className={styles.checkbox}>
            <label>
              <input
                type="checkbox"
                checked={filteredGroups.noNotif}
                onClick={() => toggleCheckboxes("noNotif")}
              />
              <div>Без первичного информирования</div>
            </label>
          </div>

          <div className={styles.checkbox}>
            <label>
              <input
                type="checkbox"
                checked={filteredGroups.noCert}
                onClick={() => toggleCheckboxes("noCert")}
              />
              <div>Не выдана книжка</div>
            </label>
          </div>

          <div className={styles.checkbox}>
            <label>
              <input
                type="checkbox"
                checked={filteredGroups.created_by_mobile}
                onClick={() => toggleCheckboxes("created_by_mobile")}
              />
              <div>Создано из мобильного приложения</div>
            </label>
          </div>

          <div className={styles.checkbox}>
            <label>
              <input
                type="checkbox"
                checked={filteredGroups.has_mobile}
                onClick={() => toggleCheckboxes("has_mobile")}
              />
              <div>Установлено МП</div>
            </label>
          </div>

          <div className={styles.checkbox}>
            <label>
              <input
                type="checkbox"
                checked={filteredGroups.no_mobile }
                onClick={() => toggleCheckboxes("no_mobile")}
              />
              <div>Без МП</div>
            </label>
          </div>

          <div className={styles.checkbox}>
            <label>
              <input
                type="checkbox"
                checked={filteredGroups.month_no_use_mobile}
                onClick={() => toggleCheckboxes("month_no_use_mobile")}
              />
              <div>Давно не пользовался МП (30 дней и более)</div>
            </label>
          </div>
        </div>
        <div className={styles.filterFooter}>
          <div>Найдено профилей: {usersCount}</div>
          <div className={styles.searchButtons}>
            <button onClick={resetFilters} className={styles.button__reset}>
              <IoMdClose size={22} />
              Сбросить фильтры
            </button>
            <button
              onClick={fetchFilteredProfiles}
              className={styles.button__search}
            >
              <IoIosSearch size={22} />
              Искать
            </button>
          </div>
        </div>
        {createRights &&
        <button
          onClick={() => history.push("/import")}
          className={styles.importBtn}
        >
          Импорт
        </button>}
        {createRights &&
        <button
          onClick={() => history.push("/profile/add")}
          className={styles.importBtn}
        >
          Добавить профиль
        </button>}
      </div>
      {loading ? (
        <Loader top="30vh" left="55vw" />
      ) : (
        data && (
          <div className={styles.profilesBlock}>
            <div className={styles.viewBlock}>
              <div>
                <div
                  className={styles.viewModeButton}
                  style={
                    listView
                      ? {
                          color: "rgb(92, 16, 16)",
                          borderColor: "rgb(92, 16, 16)",
                        }
                      : null
                  }
                  onClick={() => getData(true)}
                >
                  <MdViewHeadline />
                </div>
                <div
                  className={styles.viewModeButton}
                  style={
                    !listView
                      ? {
                          color: "rgb(92, 16, 16)",
                          borderColor: "rgb(92, 16, 16)",
                        }
                      : null
                  }
                  onClick={() => getData(false)}
                >
                  <MdViewModule />
                </div>
              </div>
              <div>
              {pdfLoading ? (
                <Loader top="-20px" left="84%" />
              ) : (
                <div>
                  {!!usersCount && 
                  usersCount<200 &&
                  <button
                    disabled={pdfLoading}
                    onClick={() => documentsForAllProfiles()}
                    className={styles.importBtnUn}
                  >
                  Отправить пакет документов на вступление
                  </button>}
                  {!!usersCount && 
                  usersCount<1000 &&
                  <button
                    disabled={pdfLoading}
                    onClick={() => documentsForAllProfiles('custom')}
                    className={styles.importBtnUn}
                  >
                  Отправить письмо
                  </button>}
                  <button
                    disabled={pdfLoading}
                    onClick={printAllProfiles}
                    className={styles.importBtnUn}
                  >
                    Скачать Pdf
                  </button>
                  <button
                    disabled={pdfLoading}
                    onClick={changeAllProfiles}
                    className={styles.importBtnUn}
                  >Применить к группе</button>
                </div>
              )}
              </div>
            </div>
            <div className={styles.pagination}>
              <Pagination
                pageCount={pageCount}
                numberPage={numberPage}
                onPageChange={handlePageClick}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              {listView ? (
                <div className={styles.profilesListList}>
                  {data.map((item, i) => (
                      <a
                        href={"/profile/" + item.id}
                        target='_blank'
                        rel="noopener noreferrer"
                        onClick={e => !haveReadRights(data) && e.preventDefault()}
                        >
                        <div
                          className={styles.profilesListListItem}
                        >
                          <div>{numberPage * yunarmiansOnPageList + i + 1}</div>
                          <div>{`${item.lastName} ${item.firstName} ${item.fathersName}`}</div>
                          <div>{item.personalId}</div>
                        </div>
                      </a>
                  ))}
                </div>
              ) : (
                <div className={styles.profilesList}>
                  {data.map((item) => (
                    <ProfileCard
                      data={item}
                      haveReadRights={haveReadRights}
                      superuser={superuser}
                      handleAddCurrentProfile={handleAddCurrentProfile}
                      deleteProfileHandler={(id) => setConfirmation(id)}
                      rotateDocumentImage={rotateDocumentImage}
                    />
                  ))}
                </div>
              )}
              <div className={styles.pagination}>
                <Pagination
                  pageCount={pageCount}
                  numberPage={numberPage}
                  onPageChange={handlePageClick}
                />
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  usersCount: state.yunarmians.count,
  pageCount: state.yunarmians.page_count,
  data: state.yunarmians.data,
  allProfiles: state.yunarmians.all_profiles,
  filterRes: state.yunarmians.filters,
  filteredGroups: state.yunarmians.filtered,
  numberPage: state.yunarmians.page_number,
  loading: state.yunarmians.loading,
  pdfLoading: state.yunarmians.pdf_loading,
  authData: state.auth,
  departments: state.departments.region_departments,
  isBack: state.yunarmians.isBack,
  troopList: state.yunarmians.troopList,
  depsList: state.yunarmians.depsList,
  modalLoading: state.yunarmians.modal_loading,
  country: state.yunarmians.country,
  accessRights: state.auth.accessRights.find(i => i.name==='unaprofile')||{},
  userRegions: state.auth.userRegions,
  superuser: state.auth.superuser
});

export default connect(mapStateToProps, {
  getYunarmiansCount,
  getYunarmiansData,
  getAllYunarmiansData,
  getFilteredGroup,
  removeFilteredGroup,
  removeFilteredLocalDep,
  getFilteredLocalDep,
  getDepartments,
  setFilteredGroups,
  deleteProfile,
  setNumberPage,
  clearUnarmiansData,
  removeDataForPrint,
  editYunarmianGroup,
  massiveSendDocuments,
  massiveSendCustomDocument,
  deleteFilteredGroups,
  rotateDocumentImage,
  getTroops,
  getDeps,
  getCountry,
  removeFilteredGroupCountry
})(Unarmiyans);
