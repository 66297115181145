import React, { useState, useEffect } from "react";
import { OptionsQuestion } from "../../../components/OptionsQuestion/OptionsQuestion";
import { MatchQuestion } from "../../../components/MatchQuestion/MatchQuestion";
import { MapQuestion } from "../../../components/MapQuestion/MapQuestion";
import styles from "./February23.module.css";

import major from "../../../assets/images/test/major.png";

import { AnswerQuestions, AnswerAnswers, regionItems } from "./DragQuestions";
import { Stopwatch } from "../../../components/Stopwatch/Stopwatch";

import img14_1 from "../../../assets/images/test/14_1.png"
import img14_2 from "../../../assets/images/test/14_2.png"
import img14_3 from "../../../assets/images/test/14_3.png"

const questions = [
  "Какое воинское звание относится к старшему офицерскому составу?",
  "Сколько человек прошло в составе пеших парадных расчетов на Параде Победы на Красной Площади 9 Мая 2021 года?",
  "Разместите название военных округов на карте",
  "В структуру Вооруженных сил Российской Федерации входят 3 вида войск и 2 отдельных рода войск. Соотнесите названия и эмблемы, указанные ниже:",
  "Определите звания ВС РФ",
  "Определите род войск ВС РФ",
  "Укажите воинское звание Главнокомандующего Вооруженными Силами Российской Федерации",
  "Кто командовал Парадом Победы на Красной Площади 24 июня 1945 года",
  "Из каких флотов состоит ВМФ России?",
  "Самый многочисленный по боевому составу вид вооружённых сил",
  "Впервые дата 23 февраля стала праздничной̆ в 1919 году. Тогда ее назвали Днем Красного подарка и приурочили к годовщине боев первых красноармейских частей̆ с немецкими войсками под Псковом и Нарвой. В целом же история праздника восходит ко дню создания Рабоче-крестьянской Красной армии — 28 января 1918-го. В тот день Совнарком издал соответствующий декрет. В каком году 23 февраля стало носить нынешнее название — День защитника Отечества?",
  "Представители какого подразделения входят в состав постоянного почетного караула у Вечного огня на могиле Неизвестного солдата в Москве:",
  "Соотнесите верно название и изображение вооружения",
  "Кому из военачальников принадлежит крылатая фраза «Дисциплина – мать победы»",
  "Выберите из предложенных ниже изображений эмблему Министерства обороны Российской Федерации"
];

const descriptions = [
  "К старшему офицерскому составу относится звание майора: ",
  "9 мая 2021 года в Параде Победы на Красной Площади в составе пеших парадных расчетов прошло 12 018 человек, включая 253 человека парадного расчета «ЮНАРМИИ» ",
  "Неплохо-неплохо",
  "Отличное знание эмблем",
  "Ты хорошо разбираешься в званиях",
  "М-даа, отличный резулитат",
  "Полковник. Главнокомандующим Вооруженных Сил Российской Федерации является Президент Российской Федерации Владимир Владимирович Путин, воинское звание которого – полковник.",
  "Ну конечно же это Константин Константинович Рокоссовский",
  "Неплохо-неплохо",
  "Так точно, это сухопутные войска",
  "Праздник был переименован в 1995г.",
  "Ну конечно же пезидентского полка",
  "Отличные познания в военной технике",
  "Основным залогом успеха любого начинания, победы, сражения, Александр Васильевич Суворов считал дисциплину. Он вырабатывал у себя дисциплину, учил дисциплине своих солдат. В его науке «Умение побеждать», которую он создавал, опираясь на собственный опыт, включает обязательные дисциплинарные нормы и правила поведения военнослужащих.",
  "Тест окончен"
];

const descriptionImg = [ major ]

const correct = [0, 1, 1, 2, 0, 0, 1, 2, 0, 0];

export function February23({
  setView
}) {
  
  const [answers, setAnswers] = useState([
    ["Майор", "Старший лейтенант", "Капитан", "Генерал-майор"],
    ["8 564", "12 018", "6 367", "10 971"],
    AnswerQuestions[0],
    AnswerQuestions[1],
    AnswerQuestions[2],
    AnswerQuestions[3],
    ["Генерал-полковник", "Полковник", "Маршал"],
    ["Г.К.Жуков", "И.В.Сталин", "К.К.Рокоссовский"],
    ["Северного, Тихоокеанского, Балтийского и Черноморского", "Северного, Южного, Тихоокеанского и Восточного", "Тихоокеанского, Атлантического и Ледовитого"],
    ["Сухопутные войска", "Воздушно-космические силы", "Военно-Морской Флот"],
    ["2002", "1995", "1997"],
    ["Преображенского полка", "Роты почетного караула", "Президентского полка"],
    AnswerQuestions[4],
    ["А.В. Суворов", "Ф.Ф. Ушаков", "М.И. Кутузов"],
    [img14_1, img14_2, img14_3]
  ]);
  
  const [answersImage, setAnswersImage] = useState([...AnswerAnswers]);
  const [items] = useState([...regionItems])

  const [question, setQuestion] = useState(0);
  const [okey, setOkey] = useState(false);

  const [finished, setFinished] = useState(false)
  const [time, setTime] = useState(null)

  const COMPONENT = [
    <OptionsQuestion
      question={questions[0]}
      answer={answers[0]}
      correct={correct[0]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
      question={questions[1]}
      answer={answers[1]}
      correct={correct[1]}
      setOkey={(val) => setOkey(val)}
    />,
    <MapQuestion
      question={questions[2]}
      answer={answers[2]}
      items={items}
      setOkey={(val) => setOkey(val)}
    />,
    <MatchQuestion
      question={questions[3]}
      answer={answers[3]}
      images={answersImage[0]}
      setOkey={(val) => setOkey(val)}
    />,
    <MatchQuestion
      question={questions[4]}
      answer={answers[4]}
      images={answersImage[1]}
      setOkey={(val) => setOkey(val)}
    />,
    <MatchQuestion
      question={questions[5]}
      answer={answers[5]}
      images={answersImage[2]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
      question={questions[6]}
      answer={answers[6]}
      correct={correct[2]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
      question={questions[7]}
      answer={answers[7]}
      correct={correct[3]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
      question={questions[8]}
      answer={answers[8]}
      correct={correct[4]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
      question={questions[9]}
      answer={answers[9]}
      correct={correct[5]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
      question={questions[10]}
      answer={answers[10]}
      correct={correct[6]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
      question={questions[11]}
      answer={answers[11]}
      correct={correct[7]}
      setOkey={(val) => setOkey(val)}
    />,
    <MatchQuestion
      question={questions[12]}
      answer={answers[12]}
      images={answersImage[3]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
      question={questions[13]}
      answer={answers[13]}
      correct={correct[8]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
      question={questions[14]}  
      answerImg={answers[14]}
      correct={correct[9]}
      setOkey={(val) => setOkey(val)}
    />,
  ]

  useEffect(() => {
    setOkey(false)
  }, [question])

  useEffect(() => {
  if (question===questions.length-1 && okey) {
      setFinished(true)
    }
  }, [okey])
  

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
      <div className={styles.navigation}>
        {/* <button
          className={styles.left}
          disabled={question === 0}
          onClick={() => setQuestion(question - 1)}
        >
          Назад
        </button> */}
        <div className={styles.number}>{`${question + 1}/${
          questions.length
        }`}</div>
        {/* <button
          className={styles.right}
          disabled={question === questions.length - 1}
          onClick={() => setQuestion(question + 1)}
        >
          Вперед
        </button> */}
      </div>
        <h1>23 февраля - День защитника Отечества</h1>
        <Stopwatch  finished={finished} setTime={(val) => setTime(val)} />
      </div>
      <div style={{ width: "100%" }}>{COMPONENT[question]}</div>
      {!!okey && (
        <div className={styles.descriptionWrap}>
          <div className={styles.description}>
            <h3>Верно!</h3>
            <div>{descriptions[question]}</div>
            {question===questions.length-1 && <div className={styles.time}>{`Ваше время: ${time}`}</div>}
            { descriptionImg[question] && <img src={descriptionImg[question]} alt="o_O" />}
            {question===questions.length-1 ?
              <button onClick={() => setView(null)}>ОК</button>
              : <button onClick={() => setQuestion(question+1)}>Следующий вопрос</button>
            }
          </div>
        </div>
      )}
    </div>
  );
}
