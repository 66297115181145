import styles from "./InputFile.module.css"


export const InputFile = ({
    type="file",
    accept="",
    onChange = () => {},
    style = {},
    title = "",
    multiple,
    ...props
}) => {
    return (
        <label className={styles.inputFile}>
            {title}
            <input 
                multiple={multiple}
                type={type}
                accept={accept}
                onChange={onChange}
                className={styles.inputFile}
                {...props}
            />
        </label>
    )
}