import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCalendarPlanEvent, getCalendarPlanEvent, updateCalendarPlanEvent } from '../../actions/chiefs_schedule'
import Modal from "react-responsive-modal";
import styles from './styles.module.css'
import moment from 'moment';
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import CustomInput from '../../components/CustomInput/CustomInput'
import CustomTextarea from '../../components/CustomTextarea/CustomTextarea'
import CustomDatepicker from '../../components/CustomDatepicker/CustomDatepicker'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import Loader from '../../components/Loader/Loader';



const validationSchema = Yup.object().shape({
    name: Yup.string().required("поле является обязательным"),
    start_date: Yup.string().required("поле является обязательным"),
    end_date: Yup.string().required("поле является обязательным"),
    description: Yup.string().required("поле является обязательным"),
})


const CalendarModal = ({open, closeModal, eventId, currentViewDate, selectedDate, handleDeleteEvent}) => {
  
    const modalTitle = `${eventId ? 'Изменить' : 'Добавить'} событие`
    
    const dispatch = useDispatch()
    const { event, loading } = useSelector(state => state.chiefs_schedule)
    
    useEffect(() => {
      open && eventId && dispatch(getCalendarPlanEvent(eventId))
      !open && setValues({})
      !open && setTouched({})
    }, [open])
    
    const getDateForSubmit = (date) => {
      return moment(date, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD HH:mm:ss')
    }

    const editEvent = async (values) => {
      const { updated, created, id, ...data } = values
      const start_date = getDateForSubmit(data.start_date)
      const end_date = getDateForSubmit(data.end_date)
      const payload = {...data, start_date, end_date}
      await dispatch(updateCalendarPlanEvent(id, payload, currentViewDate))
      closeModal()
    }
    
    const addEvent = async (data) => {
      const start_date = getDateForSubmit(data.start_date)
      const end_date = getDateForSubmit(data.end_date)
      const payload = {...data, start_date, end_date}
      await dispatch(createCalendarPlanEvent(payload, currentViewDate))
      closeModal()
    }
    
    const getInitialState = () => {
      const addMode = !eventId
      
      const start_date = moment(addMode ? selectedDate : event.start_date).utc().format('YYYY-MM-DDTHH:mm')
      const end_date = moment(addMode ? selectedDate : event.end_date).utc().format('YYYY-MM-DDTHH:mm')
      
      return addMode
        ? {color: '#24a2f0', start_date, end_date, color_text: '#ffffff'}
        : {...event, start_date, end_date}
    }

    const {
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      handleBlur,
      setValues,
      setTouched,
      setFieldTouched
    } = useFormik({
      initialValues: getInitialState(),
      enableReinitialize: true,
      validationSchema: validationSchema,
      onSubmit: eventId ? editEvent : addEvent
    })
    
    const fieldError = (fieldName) => touched[fieldName] && errors[fieldName]
  
    const deleteEventHandler = async (e) => {
      await handleDeleteEvent(eventId)(e)
      closeModal()
    }
  
    return (
      <Modal
        open={open}
        onClose={closeModal}
        classNames={{
          modal: styles.modal
        }}
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <div className={styles.title}>{modalTitle}</div>
        <div className={styles.modalBody}>
          {loading && <Loader top='42%' left='45%'/>}
          <div className={styles.inputsBlock}>
            <CustomInput
              title='Название'
              name='name'
              value={values.name}
              validate={fieldError('name')}
              onChange={handleChange}
              onBlur={handleBlur}
              />
            <CustomInput
              type='datetime-local'
              title='Дата и время начала'
              name='start_date'
              value={values.start_date}
              validate={fieldError('start_date')}
              onChange={handleChange}
              onBlur={handleBlur}
              width='190px'
              />
            <CustomInput
              type='datetime-local'
              title='Дата и время окончания'
              name='end_date'
              value={values.end_date}
              validate={fieldError('end_date')}
              onChange={handleChange}
              onBlur={handleBlur}
              width='190px'
            />
            <CustomTextarea
              title='Описание'
              name='description'
              value={values.description}
              className={styles.textarea}
              validate={fieldError('description')}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <CustomInput
              className={styles.colorPicker}
              title='Цвет фона'
              name='color'
              value={values.color}
              onChange={handleChange}
              type='color'
              width='100px'
              />      
            <CustomInput
              className={styles.colorPicker}
              title='Цвет текста'
              name='color_text'
              value={values.color_text}
              onChange={handleChange}
              type='color'
              width='100px'
            />      
          </div>
          <div className={styles.modalButtons}>
            <ButtonRed onClick={handleSubmit}>Сохранить</ButtonRed>
            <ButtonRed onClick={closeModal}>Закрыть</ButtonRed>
            {eventId && <ButtonRed onClick={deleteEventHandler}>Удалить</ButtonRed>}
          </div>
        </div>
      </Modal>
    )
}

export default CalendarModal