export const objFilter = (obj) => 
    Object.keys(obj)
          .filter( key => obj[key] || obj[key] === 0 || obj[key] === false )
          .reduce( (res, key) => (res[key] = obj[key], res), {} );


export const strip = (html) => {
var tmp = document.createElement("div");
tmp.innerHTML = html;
return tmp.textContent || tmp.innerText;
};