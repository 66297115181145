import React from 'react'
import Select from 'react-select'

const SelectAndSearch = ({options = [], placeholder = '', props, setState, state}) => {
  return (
    <>
    <Select 
      options={options} 
      placeholder={placeholder}
      {...props}
      onChange={e => setState(e)}
      value= {state}
    />
    </>
  )
}

export default SelectAndSearch






