import React from 'react'
import styles from "./LineBarChart.module.css"


export default function LineBarChart({
    percent,
    average,
    width,
    height
}) {
  return (
    <div className={styles.chart} style={{width: width, height: height}}>
        <div className={styles.box} style={{width: `${percent}%`}}></div>
                <div className={styles.text}>ЗАПОЛНЕНИЕ ПРОФИЛЕЙ</div>
                <div className={styles.text2}>{percent>15 && `${percent}%`}</div>
                <div className={styles.text3}>{`СРЕДНЕЕ ПО ДВИЖЕНИЮ - ${average}%`}</div>
    </div>
  )
}
