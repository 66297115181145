import styles from "./ButtonRed.module.css"
import cn from 'classnames'


export const ButtonRed = ({
    children,
    className,
    ...props
}) => {
    
    const buttonClassNames = cn(styles.button, {
        [className]: !!className
    })
    
    return (
        <button
            className={buttonClassNames}
            {...props}
        >
            {children}
        </button>
    )
}

