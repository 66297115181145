import React, { useState, useEffect, useCallback } from "react";
import styles from "./MapQuestion.module.css";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Container } from "./Container";
import { Item } from "./Item";
import map from "../../assets/images/test/map.png"


export function MapQuestion({
  question = [],
  answer = [],
  items = [],
  setOkey = () => {},
}) {
  
  const [_answers, setAnswers] = useState([...answer]);
  const [droppedItems, setDroppedItems] = useState([]);

  useEffect(() => {
    _answers.length === droppedItems.length && setOkey(true);
  }, [_answers])

  useEffect(() => {
    const newArr = [...answer].map(item => ({...item, lastDroppedItem:null}))
    setAnswers(newArr);
    setDroppedItems([])
  }, [answer]);

  const isDropped = (name) => droppedItems.indexOf(name) > -1;

  const handleDrop = useCallback(
    (index, item) => {
      setDroppedItems((prev) => {
        const tmp = [...prev];
        const idx = tmp.indexOf(item.name);
        if (idx > -1) tmp.slice(idx);
        else tmp.push(item.name);
        return [...tmp];
      });
      setAnswers((prev) => {
        prev[index].lastDroppedItem = item.name;
        return [...prev];
      });
    },
    [setAnswers, setDroppedItems]
  );
  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.wrapper}>
        <div className={styles.question}>{question}</div>
        <div className={styles.mapWrap}>
            <img src={map} alt="o_O"/>
          <div className={styles.map}>
          {_answers.map(({ item, lastDroppedItem, accepts }, idx) => (
            <Container
              item={item}
              lastDroppedItem={lastDroppedItem}
              accepts={accepts}
              idx={idx}
              onDrop={(item) => handleDrop(idx, item)}
              key={`${idx}`}
            />
          ))}
          </div>
        </div>
        <div className={styles.answer}>
          {items.map(({ name, type }, idx) => (
            <Item
              type={type}
              name={name}
              isDropped={isDropped(name)}
              key={`${idx}`}
            />
          ))}
        </div>
      </div>
    </DndProvider>
  );
}
