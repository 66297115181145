import React from "react";

import { useDrag } from "react-dnd";

import styles from "./MatchQuestion.module.css";

const Item = ({ type, image, isDropped }) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: { image, type },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [image, type]
  );

  return (
    !isDropped && (
      <div ref={drag} role="Box" className={styles.variantsItem}>
        <img src={image} alt="o_O" />
      </div>
    )
  );
};

export { Item };
