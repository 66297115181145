import React, { useState, useEffect } from 'react'
import styles from './LeaderContactsReport.module.css'
import { connect } from 'react-redux';
import { getDepartmentContacts } from '../../actions/reports'
import Loader from '../../components/Loader/Loader'
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { BACKEND_DOMAIN } from '../../utils/const';

function LeaderContactsReport({
  data, 
  loading,
  getDepartmentContacts
}) {
  
  const [openedDeps, setOpenedDeps] = useState([])
  const [clickedId, setClickedId] = useState(null)
  const [selectedDep, setSelectedDep] = useState('')

  useEffect(() => {
    getDepContacts()
  }, [])
  
  useEffect(() => {
    setOpenedDeps([])
  }, [selectedDep])
  

  const getDepContacts = (_id) => {
    const id = _id || 0
    !data[id] && getDepartmentContacts(id)
  }

  const changeOpenedState = (id) => {
    const newOpenedState = openedDeps.includes(id)
      ? openedDeps.filter(i => i !== id)
      : [...openedDeps, id]
    setOpenedDeps(newOpenedState)
  }

  const onLiClick = (id) => (e) => {
    e.stopPropagation()
    getDepContacts(id)
    changeOpenedState(id)
    setClickedId(id)
  }

  const renderLi = ({id, name, chief_info}) => {
    const {email, name: persName, personal_id, phone, id: profileId} = chief_info
    return (
      <li>
        <div onClick={onLiClick(id)} className={styles.row}>
          <div>{name}</div>
          <div>{persName}</div>
          <div>{phone}</div>
          <div>{email}</div>
          <div>
            <a
              href={`/profile/${profileId}`}
              target='blank'
              rel='noopener noreferrer'
              onClick={e => e.stopPropagation()}
            >
              {personal_id}
            </a>
          </div>
        </div>
        {openedDeps.includes(id) &&
          <ul>
            {data[id]?.length
              ? data[id].map(renderLi)
              : <li className={styles.noDataRow}>
                  {loading && clickedId === id
                    ? <Loader size={20} left='50%'/>
                    : 'Данные отсутствуют'
                  }
                </li>
            }
          </ul>
        }
      </li>
    )
  }

  const selectOptions = data[0] ? [{name: "Все", value: ''}, ...data[0].map(it => ({name: it.name, value: it.id}))] : []
  
  const filteredData = data[0] && selectedDep ? data[0].filter(it => it.id == selectedDep) : data[0]
  

  return (
    <div>
      <div className={styles.filters}>
      <CustomSelect
        width="500px"
        title="Выберите региональное отделение"
        options={selectOptions}
        onChange={(e) => setSelectedDep(e.target.value)}
        withoutFirst
      />
      </div>
      <div className={styles.listWrap}>
        <h2>Контакты:</h2>
        <div className={styles.listTitles}>
          <div>Отделение:</div>
          <div>Имя руководителя:</div>
          <div>Телефон:</div>
          <div>E-mail:</div>
          <div>Личный номер:</div>
        </div>
        <ul>{filteredData && filteredData.map(renderLi)}</ul>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  data: state.reports.contacts_report,
  loading: state.reports.loading
});

export default connect(mapStateToProps, {
  getDepartmentContacts
})(LeaderContactsReport);