import moment from "moment";
import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { selects } from "../../utils/const";
import styles from "./Detachments.module.css";

export default function DetachmentParticipants({
    setParticipantsMode,
    details
}) {
  return (
    <div className={styles.participalWrap}>
      <div onClick={() => setParticipantsMode(false)} className={styles.goBack}>
        <IoIosArrowRoundBack size={20} />
        назад
      </div>
      <h3>Список юнармейцев:</h3>
      <div className={styles.tableWrap}>
        {details && details.participants.length &&
            <table>
                <thead>
                    <tr>
                        <th>ФИО</th>
                        <th>Личный номер</th>
                        <th>Дата рождения</th>
                        <th>Телефон</th>
                        <th>E-mail</th>
                        <th>Адрес</th>
                        <th>Статус</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        details.participants.map(item =>
                            <tr>
                                <td>{`${item.lastName} ${item.firstName} ${item.fathersName}`}</td>
                                <td>{item.personalId}</td>
                                <td>{item.birthday && moment(item.birthday).format("DD.MM.YYYY")}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                <td>{item.address}</td>
                                <td>{item.status && selects.status.find(it => it.value===item.status).name }</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        }
      </div>
    </div>
  );
}
