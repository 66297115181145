import * as types from "../actions/types";

const initialState = {
  count: null,
  page_count: null,
  data: null,
  current_house: null,
  loading: false,
  info_for_excel: null,
  info_for_excel_load: false
};

export default function yunarmy_houses(state = initialState, action) {
  switch (action.type) {
    case types.SET_YUNARMY_HOUSES_COUNT:
      return {
        ...state,
        count: action.payload.data,
        page_count: action.payload.pageCount,
      };
    case types.SET_YUNARMY_HOUSES_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case types.SET_CURRENT_HOUSE_DATA:
      return {
        ...state,
        current_house: action.payload,
      };
    case types.SET_INFO_FOR_EXCEL:
      return {
        ...state,
        info_for_excel: action.payload,
      };
    case types.INFO_FOR_EXCEL_LOADING:
      return {
        ...state,
        info_for_excel_load: action.payload,
  };
    case types.SET_YUNARMY_HOUSES_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
