import React, { useState, useEffect } from "react";
import { OptionsQuestion } from "../../../components/OptionsQuestion/OptionsQuestion";
import { MatchQuestion } from "../../../components/MatchQuestion/MatchQuestion";
import { MapQuestion } from "../../../components/MapQuestion/MapQuestion";
import styles from "./April12.module.css";

import gagarin from "../../../assets/images/test/gagarin.jpg"
import leonov from "../../../assets/images/test/leonov.png"
import galileo from "../../../assets/images/test/galileo.png"
import kelly from "../../../assets/images/test/kelly.jpg"



const questions = [
  "Сколько времени пробыл Юрий Алексеевич Гагарин в космосе?",
  "Назовите имя космонавта, совершившего первый выход в открытое космическое пространство",
  "Кто впервые направил зрительную трубу в небо, превратив ее в телескоп и получил новые научные данные?",
  "Астронавт NASA Скотт Келли, вернувшийся в начале марта 2016 года с МКС на Землю, как выяснилось, за 340 дней, проведенных в космосе, вырос почти на пять сантиметров. Почему так произошло?"
];

const answers = [
 [ "108 минут", "120 минут", "117 минут", "133 минуты"],
 ["Герман Степанович Титов", "Валентина Владимировна Терешкова", "Алексей Архипович Леонов", "Георгий Михайлович Гречко"],
 ["Галилео Галилей", "Николай Коперник", "Константин Эдуардович Циолковский", "Леонардо да Винчи"],
 ["Эффективное питание на космической станции", "На Земле гравитация давит на позвоночник, а в космосе этого не происходит и поэтому позвоночник расправляется на всю длину", "Скотт Келли является самым молодым космонавтом. На момент полета ему было 18 лет и его организм ещё рос", "Ученые до сих пор не могут дать ответ на этот вопрос"]
]

const descriptions = [
  "Длительность первого орбитального полета вокруг Земли, совершенного 12 апреля 1961 года Юрием Гагариным на космическом корабле «Восток-1», составила 108 минут",
  "Алексей Архипович Леонов  - генерал-майор авиации, дважды Герой Советского Союза.18 марта 1965 года состоялся первый выход человека в открытый космос, который осуществил Алексей Леонов.  Впервые космонавт покинул кабину корабля и вышел в открытое пространство",
  "Галилео Галилей (1564-1642) - величайший мыслитель эпохи Ренессанса, основоположник современной механики, физики и астрономии. В 1609 году создал телескоп, дававший 32-кратное увеличение",
  "Не только Келли, но и все люди в невесомости вырастают, примерно, на три-пять сантиметров. На Земле гравитация давит на позвоночник, а в космосе этого не происходит. Позвоночник расправляется на всю длину. "
];

const img = [gagarin, null, galileo, null]

const descriptionImg = [ null, leonov, null, kelly ]

const correct = [0, 2, 0, 1];

export function April12({
  setView
}) {
  
  const [question, setQuestion] = useState(0);
  const [okey, setOkey] = useState(false);

  const COMPONENT = [
    <OptionsQuestion
      question={questions[0]}
      answer={answers[0]}
      correct={correct[0]}
      image={img[0]}
      setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
    question={questions[1]}
    answer={answers[1]}
    correct={correct[1]}
    image={img[1]}
    setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
    question={questions[2]}
    answer={answers[2]}
    correct={correct[2]}
    image={img[2]}
    setOkey={(val) => setOkey(val)}
    />,
    <OptionsQuestion
    question={questions[3]}
    answer={answers[3]}
    correct={correct[3]}
    image={img[3]}
    setOkey={(val) => setOkey(val)}
    />
  ]

  useEffect(() => {
    setOkey(false)
  }, [question])

  return (
    <div className={styles.wrapper}>
      <h1>12 Апреля - День космонавтики</h1>
      <div className={styles.navigation}>
        <button
          className={styles.left}
          disabled={question === 0}
          onClick={() => setQuestion(question - 1)}
        >
          Назад
        </button>
        <div className={styles.number}>{`${question + 1}/${
          questions.length
        }`}</div>
        <button
          className={styles.right}
          disabled={question === questions.length - 1}
          onClick={() => setQuestion(question + 1)}
        >
          Вперед
        </button>
      </div>
      <div style={{ width: "100%" }}>{COMPONENT[question]}</div>
      {!!okey && (
        <div className={styles.descriptionWrap}>
          <div className={styles.description}>
            <h3>Верно!</h3>
            <div>{descriptions[question]}</div>
            { descriptionImg[question] && <img src={descriptionImg[question]} alt="o_O" />}
            {question===questions.length-1 ?
              <button onClick={() => setView(null)}>ОК</button>
              : <button onClick={() => setQuestion(question+1)}>Следующий вопрос</button>
            }
          </div>
        </div>
      )}
    </div>
  );
}
