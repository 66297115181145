import React, {useState} from 'react'
import styles from "./Test.module.css";
import buttonBack from '../../img/buttonBack.png'
import Pick from './Pick/Pick'
import PickImage from './PickImage/PickImage'
import Compare from './Compare/Compare';
import Timer from './Timer/Timer';
import PickWho from './PickWho/PickWho';
// import InputText from './InputText/InputText';

const Test = ({data, setIsTest, isWhich, setisWhich}) => {

    const [step, setStep] = useState(0);
    const [over, setOver] = React.useState(false);
    const [points, setPoints] = useState(0);
    const [who, setWho] = useState([]);

    const question = data[step];

    const getAnswerComp = ({type, answers}) => {

        if (data?.length < step + 1) {
            return <div>Спасибо!</div>
        } else {

        if (!isWhich) {
          switch (type) {
            case 'pick':
                return <Pick answers={answers} setStep={setStep} setPoints={setPoints}/>
            case 'pickImage':
                return <PickImage answers={answers} setStep={setStep} setPoints={setPoints}/>
            case 'compare':
                return <Compare question={question} setStep={setStep} setPoints={setPoints}/>
          }   
        } else if (isWhich) {
            return <PickWho answers={answers} setStep={setStep} setPoints={setPoints} setWho={setWho} isWhich={isWhich} setisWhich={setisWhich} who={who}/>
        }               
    }
    }

    const getResume = (points, step) => {
      if (step == 16 || step == 17) {
        if (points >= 14 && points <= 17) {
          return 'Так держать. Это отличный результат.'
        }
        if (points >= 9 && points <= 13) {
          return 'Это неплохо, но не останавливайтесь на достигнутом.'
        }
        if (points >= 0 && points <= 8) {
          return 'Возможно сегодня просто не Ваш день . Уверены в следующий раз у Вас все получится.'
        }
      }
      if (step == 14) {
        if (points >= 11 && points <= 14) {
          return 'Так держать. Это отличный результат.'
        }
        if (points >= 9 && points <= 13) {
          return 'Это неплохо, но не останавливайтесь на достигнутом.'
        }
        if (points >= 0 && points <= 8) {
          return 'Возможно сегодня просто не Ваш день . Уверены в следующий раз у Вас все получится.'
        }
      }
      if (step == 10) {
        if (points >= 8 && points <= 10) {
          return 'Так держать. Это отличный результат.'
        }
        if (points >= 5 && points <= 7) {
          return 'Это неплохо, но не останавливайтесь на достигнутом.'
        }
        if (points >= 0 && points <= 4) {
          return 'Возможно сегодня просто не Ваш день . Уверены в следующий раз у Вас все получится.'
        }
      }
    }

    const handleTest = () => {
      setisWhich(false); 
      setIsTest([])
    }

    return (
    <div className={styles.wrapper}>
      <div className={styles.content}>

        {(data?.length < step + 1) || (over) ?
        <div className={styles.final}>
          {!isWhich ?
          <><div className={styles.pointsTitle}>Спасибо!</div>
            <div className={styles.pointsText}>Вы набрали {points} из {step} баллов!</div>
            <div className={styles.pointsText}>{getResume(points, step)}</div></>
          :
          <>
          <div className={styles.pointsTitle}>Спасибо!</div>
          <div className={styles.pointsFin}>{who.filter(el => el == 'Защитник').length * 10} % - Защитник</div>
          <div className={styles.pointsFin}>{who.filter(el => el == 'Атлет').length * 10} % - Атлет</div>
          <div className={styles.pointsFin}>{who.filter(el => el == 'Исследователь').length * 10} % - Исследователь</div>
          <div className={styles.pointsFin}>{who.filter(el => el == 'Созидатель').length * 10} % - Созидатель</div>
          </>  
      }
        </div>
        :
        <>
        <div className={styles.timeAndStep}>
          <div className={styles.step}>Вопрос {step+1}</div>
          <Timer hours={0} minutes={Math.floor(data.length / 2)} seconds={(60 / 10 * (data.length / 2).toString().split('.')[1]) || 0} over={over} setOver={setOver}/>
        </div>
        <div className={styles.question}>{question?.question}</div>
        {
            question.img ?
            <div className={styles.imgWrapper}>
            <div className={styles.img} style={{backgroundImage: `url(../img${question.img})`}}></div>
            </div>
            :
            <></>
          }
        {getAnswerComp(question)}
        </>
        }

      </div>

      <img src={buttonBack} className={styles.buttonBack} onClick={() => handleTest()}/>
    </div>
  )
}

export default Test