import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { reduxForm } from "redux-form";
import { Button } from "../../assets/common/Button/Button";
import { InputFile } from "../../assets/common/InputFile/InputFile"
import ReactQuill from "react-quill";
import styles from "./Tasks.module.css";
import { IoMdTrash, IoMdCreate, IoIosArrowRoundForward } from "react-icons/io";
import EditSteps from './ModalModes/EditSteps';
import { BACKEND_DOMAIN, eventLevels, optionsStap } from "../../utils/const";
import { createNotification } from "../../components/Notifications/Notifications";
import TaskProfiles from "./ModalModes/TaskProfiles";
import DatePicker from 'react-datepicker';
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { getForAny } from "../../actions/departments";
import AwardsBlock from "../../components/AwardsBlock/AwardsBlock";




const TasksModal = ({
  type,
  numberPage,
  selectedItem,
  editObject,
  addObject,
  uploadImage,
  deleteImage,
  articles,
  getSteps,
  getDepartments,
  stepsData,
  addTaskStep,
  stepsPageCount,
  deleteTaskStep,
  getDepartmentsByRegion,
  editTaskStep,
  getTaskProfiles,
  changeCompleteStatus,
  getTaskRealization,
  filters,
  fromQuest,
  fromQuestAddMode,
  accessRights,
  steps_data_load,
  loadingTask,
  ...props
}) => {
  
  const updateRights = type === "ADD" || accessRights.ac_update

  const dispatch = useDispatch()

  const {
    region_departments: regDepartments,
    local_departments: localDepartments,
    detachments,
    loading
  } = useSelector(state => state.departments)
  
  const [item, setItem] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [image, setImage] = useState(null);

  //steps
  const [addStepsMode, setAddStepsMode] = useState(false);
  const [selectedStep, setSelectedStep] = useState(null);
  const [profilesMode, setProfilesMode] = useState(false)
  const [realizationMode, setRealizationMode] = useState(null)
 
  
  const strip = (html) => {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;

    return tmp.textContent || tmp.innerText;
  };

  const options = eventLevels.slice(0, 3).map((items) => {
  return (
    <option 
      key={items.value}  
      value={items.value}
      selected={selectedItem != null && selectedItem.level == items?.value}
    >
      {items.name}
    </option>
  )
});

const closeAddSteps = () => {
  setAddStepsMode(false);
  setSelectedStep(null);
}

useEffect(() => {
  !regDepartments && getDepartments()
  
  if (!selectedItem) return;
  getSteps(numberPage+1, selectedItem.id);
  
}, [selectedItem,type])

  useEffect(() => {
    if (!item?.department || item.level !== 3) return;
    const parentDeps = item.department.reduce((acc, item) => item.parent_id ? acc : [...acc, item.department_id], [])
    dispatch(getForAny(parentDeps))
  }, [item?.department])
  

  useEffect(() => {
    if (type === "ADD") {
      setItem({
        name: '',
        eventid: '',
        points: '',
        level: '',
        text: '',
      })
      setImage(null)
      setImageURL(null)
    } else {
      selectedItem && setItem(selectedItem);
      if (selectedItem?.imageurl) {
        setImageURL(selectedItem.imageurl)
      } else {
        setImage(null)
        setImageURL(null)
      }
    }
  }, [selectedItem, type]);

  const handlSelect = (e) => {
    setItem((prev) => ({ ...prev, level: parseInt(e.target.value), department: null }))
  }

  const handlSelectEvent = (e) => {
    setItem((prev) => ({ ...prev, eventid: parseInt(e.target.value) }))
  }

  const addTaskItem = async () => {
    const errorField = !item.name ? "Поле название"
                :  item.level <= 0 ? "Поле уровень"
                : !item.text ? "Поле описание"
                : !image ? "Поле изображение"
                : !item.points ? "Поле баллы"
                : !item.eventid ? "Поле Относится к мероприятию"
                : !item.date_end || !item.date_start ? "Поля дата начала и дата конца"
                : ""
    if (!errorField) {
      const res = fromQuestAddMode 
        ? await addObject(item, null, { file: image }, fromQuestAddMode, [])
        : await addObject(item, numberPage+1, { file: image }, null, filters);
      res && props.onClose()
    } else {
      createNotification("error", `${errorField} является обязательным`)
    }
  };

  const editTaskItem = async () => {
    const tmp = {...item};
    tmp.id && delete tmp.id;
    tmp.imageurl && delete tmp.imageurl;
    const errorField = !item.name ? "Поле название"
      :  item.level <= 0 ? "Поле уровень"
      : !strip(item.text) ? "Поле описание"
      : !item.points ? "Поле баллы"
      : !item.eventid ? "Поле Относится к мероприятию"
      : !item.date_end || !item.date_start ? "Поля дата начала и дата конца"
      : !(image || imageURL) ? "Поле изображение"
      : ""
      if (!errorField) {
        const res = fromQuest
          ? await editObject(item.id, tmp, null, item.quest, [])
          : await editObject(item.id, tmp, numberPage+1, null, filters);
        if (res) {
          if (image) {
            uploadImage(selectedItem.id, { file: image }, numberPage+1, null, filters);
          } else if (selectedItem.imageurl && !imageURL) {
            deleteImage(item.id, numberPage+1, null, filters);
          }
          props.onClose()
        }
      } else {
        createNotification("error", `${errorField} является обязательным`)
      }
  };

  const handleUploadImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setImageURL(reader.result);
        setImage(e.target.files[0]);
      };
      reader.readAsDataURL(file);
    } else {
      setImageURL(null);
      setImage(null);
    }
  };

  const handleDeleteImage = () => {
    setImageURL(null);
    setImage(null);
    selectedItem.imageurl && deleteImage(item.id, numberPage+1, null, filters)
  };

  const handleSelectDepartment = ({parent, id}) => () => {
    const alreadyInclude = item.department?.find(({department_id}) => department_id === id)
    const newDepartment = {
      parent_id: parent || null,
      department_id: id
    }
    const department = alreadyInclude
      ? item.department.filter(({department_id, parent_id}) => department_id !== id && parent_id !== id)
      : item.department
        ? [...item.department, newDepartment]
        : [newDepartment]
    setItem({...item, department})
  }


  const checkboxValue = (id) => item.department?.find(({department_id}) => department_id === id)

  return (
    <Modal
      id='modal'
      {...props}
      classNames={{
        modal: `${realizationMode ? styles.modal2 : styles.modal}`,
        closeButton: styles.closeBtn,
      }}
    >
      {(addStepsMode || selectedStep) ? 
        (<EditSteps 
            addStepsMode={addStepsMode} 
            selectedStep={selectedStep} 
            onClose={closeAddSteps} 
            stepsPageCount={stepsPageCount} 
            addTaskStep={addTaskStep} 
            selectedItem={selectedItem}
            number={stepsData}
            editTaskStep={editTaskStep}
            type={type}
          />
        )
        :
        (
          profilesMode ?
          <TaskProfiles
            id={selectedItem.id}
            goBack={() => setProfilesMode(false)}
            getTaskProfiles={getTaskProfiles}
            getDepartmentsByRegion={getDepartmentsByRegion}
            changeCompleteStatus={changeCompleteStatus}
            getDepartments={getDepartments}
            getTaskRealization={getTaskRealization}
            realizationMode={realizationMode} 
            setRealizationMode={setRealizationMode}
          />
          :
          <>
            <div className={styles.title}>
              {type === "ADD"
                ? "Добавление задания"
                : "Редактирование задания"}
            </div>
            {item && <div className={styles.modalBody}>
              <div>
                <div className={styles.firstBlock}>
                  <div className={styles.firstBlock__left}>
                    <p className={styles.task__label}>Название:</p>
                    <input
                      maxlength="200"
                      value={item?.name}
                      disabled={!updateRights}
                      onChange={(e) =>
                        setItem((prev) => ({ ...prev, name: e.target.value }))
                      }
                      className={styles.input}
                    />
                    <br />

                    <p className={styles.task__label}>Относится к мероприятию:</p>
                    <select
                      onChange={(e) => handlSelectEvent(e)}
                      disabled={!updateRights}
                    >
                      <option key={0} value={0}>{'Выбрать'}</option>  
                      {articles && articles.map(item => 
                      <option 
                        key={item.id}  
                        value={item.id}
                        selected={selectedItem != null && selectedItem.eventid == item?.id}
                        >{item.title}
                      </option>)}
                    </select>
                    <br />
                      <p className={styles.task__label}>Даты выполнения задания:</p>
                      <div className={styles.timeBlock}>
                        <DatePicker
                            selected={item.date_start ? moment(item.date_start) : null}
                            onChange={(date) => setItem(prev => ({...prev, date_start: prev.date_start ? moment(date) : moment(date).set('hour', 23).set('minute', 0)}))}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={1}
                            timeInputLabel="Время:"
                            dateFormat="LLL"
                            readOnly={!updateRights}
                            showYearDropdown
                            />
                        <DatePicker
                             selected={item.date_end ? moment(item.date_end) : null}
                             onChange={(date) => setItem(prev => ({...prev, date_end: prev.date_end ? moment(date) : moment(date).set('hour', 23).set('minute', 0)}))}
                             showTimeSelect
                             timeFormat="HH:mm"
                             timeIntervals={1}
                             timeInputLabel="Время:"
                             dateFormat="LLL"
                             readOnly={!updateRights}
                             showYearDropdown
                        />
                      </div>
                    <br />

                    <p className={styles.task__label}>Баллы:</p>
                    <input
                      type='number'
                      value={item?.points}
                      disabled={!updateRights}
                      onChange={(e) =>
                        setItem((prev) => ({ ...prev, points: parseInt(e.target.value) }))
                      }
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.imageBlock}>
                    <div className={styles.image}>
                      {imageURL && <img src={imageURL&&image ? imageURL : `${BACKEND_DOMAIN || ""}${imageURL}`} alt="o_O" />}
                    </div>
                    {imageURL ? (
                      <Button
                        title="Очистить"
                        type="button"
                        disabled={!updateRights}
                        onClick={handleDeleteImage}
                      />
                    ) : (
                      <InputFile 
                      title="Выберите изображение"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"  
                      onChange={(e) => handleUploadImage(e)}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.secondBlock}>
                  <p className={styles.task__label}>Уровень:</p>
                  <select
                    onChange={handlSelect}
                    disabled={!updateRights}
                  >
                    <option key={0} value={0}>{'Выбрать'}</option>  
                    {options}
                  </select>
                {(item.level == 2 || item.level == 3) &&
                <div>
                  <div className={styles.label}>Региональные отделения:</div>
                  <div className={styles.regionDepartmentsList}>
                    <div>{regDepartments?.slice(0, Math.ceil(regDepartments.length/2)).map(it => 
                    <CustomCheckbox
                      label={it.name}
                      disabled={!updateRights}
                      value={checkboxValue(it.id)}
                      onChange={handleSelectDepartment(it)}
                    />)}</div>
                    <div>{regDepartments?.slice(Math.ceil(regDepartments.length/2)).map(it => 
                    <CustomCheckbox
                      label={it.name}
                      disabled={!updateRights}
                      value={checkboxValue(it.id)}
                      onChange={handleSelectDepartment(it)}
                    />)}</div>
                  </div>
                </div>}
                {item.level == 3 &&
                  <div className={styles.otherDepartmentsList}>
                    {localDepartments &&
                    <div style={{width: "48%", marginRight: "15px"}}>
                      <div className={styles.label}>Местные отделения:</div>
                      <div className={styles.checkBoxBlock}>
                        {loading
                          ? <Loader/>
                          : localDepartments.map(it => 
                            <CustomCheckbox
                              label={it.name}
                              disabled={!updateRights}
                              value={checkboxValue(it.id)}
                              onChange={handleSelectDepartment(it)}
                            />
                        )}
                      </div>
                    </div>}
                    {detachments &&
                    <div>
                      <div className={styles.label}>Отряды:</div>
                      <div className={styles.checkBoxBlock}>
                        {loading
                        ? <Loader/>
                        : detachments.map(it => 
                          <CustomCheckbox
                            label={it.name}
                            disabled={!updateRights}
                            value={checkboxValue(it.id)}
                            onChange={handleSelectDepartment(it)}
                          />
                        )}
                      </div>
                    </div>}                
                  </div>}

                    <div>
                      <br/>
                      <p className={styles.task__label}>Описание:</p>
                      <ReactQuill
                        value={item?.text}
                        readOnly={!updateRights}
                        onChange={(e) => setItem((prev) => ({ ...prev, text: e }))}
                      />
                      <br />
                    </div>

                    <div>
                      <p className={styles.task__label}>Награда:</p>
                      <AwardsBlock
                        selected={item.award_id}
                        onChange={(award_id) => setItem((prev) => ({ ...prev, award_id }))}
                      />
                    </div>
                </div>


                {
                  type == "EDIT" && 
                <>
                <p className={styles.stepTitleBig}>Структура задания по шагам:</p>
                <div className={styles.stepsWrapper}>
                <div className={styles.stepWrapper}>
                    <span className={styles.stepTextNumber}><span>Шаг</span></span>                                 
                      <p className={styles.stepTitle}>Название</p>         
                      <p className={styles.stepText}>Описание</p>
                      <p className={styles.stepResult}>Результат</p>
                      <span className={styles.iconText}>x</span>
                      <span className={styles.iconText}>x</span>
                  </div>

                  {!steps_data_load ? 
                  
                  <>
                    {stepsData && stepsData.length > 0 && 
                      stepsData.map((el, index) => 
                      <div className={styles.stepWrapper} key={`${el.name}_${index}`}>
                        <span className={styles.stepTextNumber}>№ {el.number}</span>
                        <span className={styles.stepTitle}>{el.name}</span>
                        <p className={styles.stepText}>{strip(el.description)}</p>
                        <span className={styles.stepResult}>{el.result && Object.values(optionsStap)[el.result].name}</span>
                        <i
                          className={styles.editStep}
                          onClick={() => setSelectedStep(el)}
                          style={updateRights ? null : {pointerEvents: 'none', opacity: 0.5}}
                        >
                          <IoMdCreate/>
                        </i>
                        <i
                          className={styles.deleteStep}
                          onClick={() => deleteTaskStep(selectedItem.id, el.id)}
                          style={updateRights ? null : {pointerEvents: 'none', opacity: 0.5}}
                        >
                          <IoMdTrash/>
                        </i>
                      </div>
                      ) 
                    }
                  </>
                
                  :
                  <Loader left='45%'/>
                  }
                </div>
                
              {selectedItem && updateRights && 
                  <span className={styles.addStepButton} onClick={() => setAddStepsMode(true)}>Добавить шаг</span>
              }
              <div
                onClick={() => setProfilesMode(true)}
                className={styles.goForward}
              >
                Юнармейцы, выполнившие задание
                <IoIosArrowRoundForward size={20} />
              </div>
                </>
              }
              </div>
                <div className="settingModal__btns">
                {updateRights &&
                <button
                  className="newsListHeader__btn btn_grey"
                  type="button"
                  onClick={type === "ADD" ? addTaskItem : editTaskItem}
                >
                  {type === "ADD" ? "Добавить" : "Сохранить"}
                </button>}
                <button
                  className="newsListHeader__btn btn_grey"
                  onClick={props.onClose}
                  type="button"
                >
                  Закрыть
                </button>
              </div>
            </div>}
          </>
        )
      }
    </Modal>
  );
};

const TasksModalWithForm = reduxForm({
  form: "task",
})(TasksModal);

export default connect()(TasksModalWithForm);
