import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { SET_ACCESS_ENTITIES, SET_ACCESS_LOADING, SET_GROUP_ACCESS } from "./types";
import { checkAndUpdateToken } from "./auth";
import { createNotification } from "../components/Notifications/Notifications";

export const getAccessEntities = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ACCESS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/access_control_entity`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_ACCESS_ENTITIES, payload });
    }
  }
  dispatch({ type: SET_ACCESS_LOADING, payload: false });
};

export const getGroupAccess = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ACCESS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/access_control/role/${groupId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_GROUP_ACCESS, payload });
    }
  }
  dispatch({ type: SET_ACCESS_LOADING, payload: false });
};

export const updateGroupAccess = (entityId, roleId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ACCESS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/access_control/${entityId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Изменения успешно сохранены')
      dispatch(getGroupAccess(roleId))
    }
  }
  dispatch({ type: SET_ACCESS_LOADING, payload: false });
};

export const createGroupAccess = (roleId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_ACCESS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/access_control`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Изменения успешно сохранены')
      dispatch(getGroupAccess(roleId))
    }
  }
  dispatch({ type: SET_ACCESS_LOADING, payload: false });
};

