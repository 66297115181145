import img4_1 from "../../../assets/images/test/4_1.png";
import img4_2 from "../../../assets/images/test/4_2.png";
import img4_3 from "../../../assets/images/test/4_3.png";
import img4_4 from "../../../assets/images/test/4_4.png";
import img4_5 from "../../../assets/images/test/4_5.png";

import img5_1 from "../../../assets/images/test/5_1.png";
import img5_2 from "../../../assets/images/test/5_2.png";
import img5_3 from "../../../assets/images/test/5_3.png";
import img5_4 from "../../../assets/images/test/5_4.png";
import img5_5 from "../../../assets/images/test/5_5.png";
import img5_6 from "../../../assets/images/test/5_6.png";

import img6_1 from "../../../assets/images/test/6_1.png";
import img6_2 from "../../../assets/images/test/6_2.png";
import img6_3 from "../../../assets/images/test/6_3.png";
import img6_4 from "../../../assets/images/test/6_4.png";
import img6_5 from "../../../assets/images/test/6_5.png";
import img6_6 from "../../../assets/images/test/6_6.png";

import img13_1 from "../../../assets/images/test/13_1.png";
import img13_2 from "../../../assets/images/test/13_2.png";
import img13_3 from "../../../assets/images/test/13_3.png";
import img13_4 from "../../../assets/images/test/13_4.png";


const types = {
  SV: "Сухопутные войска",
  VKS: "Воздушно-космические силы ",
  VMF: "Военно-Морской Флот",
  RVSN: "Ракетные войска стратегического назначения",
  VDV: "Воздушно-десантные войска",
  GP: "ГЕНЕРАЛ-ПОЛКОВНИК",
  P: "ПОЛКОВНИК",
  L: "ЛЕЙТЕНАНТ",
  ML: "МЛАДШИЙ ЛЕЙТЕНАНТ",
  SP: "СТАРШИЙ ПРАПОРЩИК",
  S: "СЕРЖАНТ",
  VVP: "Войска ВВС и ПВО",
  VS: "Войска связи ",
  RVA: "Ракетные войска и артиллерия",
  SPV: "Сухопутные войска",
  AV: "Автомобильные войска",
  ZHV: "Железнодорожные войска",
  ZVO: "Западный военный округ",
  SF: "Северный флот ",
  UVO: "Южный военный округ",
  CVO: "Центральный военный округ",
  VVO: "Восточный военный округ",
  ZRK: "Зенитный ракетный комплекс «Бук-М1»",
  RSZO: "122-мм реактивная система залпового огня 9К51 «Град» (БМ-21)",
  BTR: "Бронетранспортер БТР-80",
  UBIM: "Универсальная бронированная инженерная машина УБИМ",
};

const AnswerQuestions = [
  [
    { accepts: [types.ZVO], lastDroppedItem: null, item: types.ZVO },
    { accepts: [types.SF], lastDroppedItem: null, item: types.SF },
    { accepts: [types.UVO], lastDroppedItem: null, item: types.UVO },
    { accepts: [types.CVO], lastDroppedItem: null, item: types.CVO },
    { accepts: [types.VVO], lastDroppedItem: null, item: types.VVO },
  ],
  [
    { accepts: [types.SV, types.VKS, types.VMF, types.RVSN, types.VDV], lastDroppedItem: null, item: types.SV, correctItem: types.SV },
    { accepts: [types.SV, types.VKS, types.VMF, types.RVSN, types.VDV], lastDroppedItem: null, item: types.VKS, correctItem: types.VKS },
    { accepts: [types.SV, types.VKS, types.VMF, types.RVSN, types.VDV], lastDroppedItem: null, item: types.VMF, correctItem: types.VMF },
    { accepts: [types.SV, types.VKS, types.VMF, types.RVSN, types.VDV], lastDroppedItem: null, item: types.RVSN, correctItem: types.RVSN },
    { accepts: [types.SV, types.VKS, types.VMF, types.RVSN, types.VDV], lastDroppedItem: null, item: types.VDV, correctItem: types.VDV },
  ],
  [
    { accepts: [types.GP, types.P, types.L, types.ML, types.SP, types.S], lastDroppedItem: null, item: types.GP, correctItem: types.GP },
    { accepts: [types.GP, types.P, types.L, types.ML, types.SP, types.S], lastDroppedItem: null, item: types.P, correctItem: types.P },
    { accepts: [types.GP, types.P, types.L, types.ML, types.SP, types.S], lastDroppedItem: null, item: types.L, correctItem: types.L },
    { accepts: [types.GP, types.P, types.L, types.ML, types.SP, types.S], lastDroppedItem: null, item: types.ML, correctItem: types.ML },
    { accepts: [types.GP, types.P, types.L, types.ML, types.SP, types.S], lastDroppedItem: null, item: types.SP, correctItem: types.SP },
    { accepts: [types.GP, types.P, types.L, types.ML, types.SP, types.S], lastDroppedItem: null, item: types.S, correctItem: types.S },
  ],
  [
    { accepts: [types.VVP, types.VS, types.RVA, types.SPV, types.AV, types.ZHV], lastDroppedItem: null, item: types.VVP, correctItem: types.VVP },
    { accepts: [types.VVP, types.VS, types.RVA, types.SPV, types.AV, types.ZHV], lastDroppedItem: null, item: types.VS, correctItem: types.VS },
    { accepts: [types.VVP, types.VS, types.RVA, types.SPV, types.AV, types.ZHV], lastDroppedItem: null, item: types.RVA, correctItem: types.RVA },
    { accepts: [types.VVP, types.VS, types.RVA, types.SPV, types.AV, types.ZHV], lastDroppedItem: null, item: types.SPV, correctItem: types.SPV },
    { accepts: [types.VVP, types.VS, types.RVA, types.SPV, types.AV, types.ZHV], lastDroppedItem: null, item: types.AV, correctItem: types.AV },
    { accepts: [types.VVP, types.VS, types.RVA, types.SPV, types.AV, types.ZHV], lastDroppedItem: null, item: types.ZHV, correctItem: types.ZHV },
  ],
  [
    { accepts: [types.ZRK, types.RSZO, types.BTR, types.UBIM], lastDroppedItem: null, item: types.ZRK, correctItem: types.ZRK },
    { accepts: [types.ZRK, types.RSZO, types.BTR, types.UBIM], lastDroppedItem: null, item: types.RSZO, correctItem: types.RSZO },
    { accepts: [types.ZRK, types.RSZO, types.BTR, types.UBIM], lastDroppedItem: null, item: types.BTR, correctItem: types.BTR },
    { accepts: [types.ZRK, types.RSZO, types.BTR, types.UBIM], lastDroppedItem: null, item: types.UBIM, correctItem: types.UBIM },
  ]
];

const regionItems = [
  {
    type: types.ZVO,
    name: types.ZVO,
  },
  {
    type: types.SF,
    name: types.SF,
  },
  {
    type: types.UVO,
    name: types.UVO,
  },
  {
    type: types.CVO,
    name: types.CVO,
  },
  {
    type: types.VVO,
    name: types.VVO,
  },
];

const AnswerAnswers = [
  [
    {
      type: types.SV,
      image: img4_1,
    },
    {
      type: types.VKS,
      image: img4_2,
    },
    {
      type: types.VMF,
      image: img4_3,
    },
    {
      type: types.RVSN,
      image: img4_4,
    },
    {
      type: types.VDV,
      image: img4_5,
    },
  ],
  [
    {
      type: types.GP,
      image: img5_4,
    },
    {
      type: types.P,
      image: img5_5,
    },
    {
      type: types.L,
      image: img5_2,
    },
    {
      type: types.ML,
      image: img5_6,
    },
    {
      type: types.SP,
      image: img5_3,
    },
    {
      type: types.S,
      image: img5_1,
    },
  ],
  [
    {
      type: types.VVP,
      image: img6_2,
    },
    {
      type: types.VS,
      image: img6_6,
    },
    {
      type: types.RVA,
      image: img6_1,
    },
    {
      type: types.SPV,
      image: img6_5,
    },
    {
      type: types.AV,
      image: img6_4,
    },
    {
      type: types.ZHV,
      image: img6_3,
    },
  ],
  [
    {
      type: types.ZRK,
      image: img13_1,
    },
    {
      type: types.RSZO,
      image: img13_2,
    },
    {
      type: types.BTR,
      image: img13_3,
    },
    {
      type: types.UBIM,
      image: img13_4,
    }
  ]
];

export { AnswerQuestions, AnswerAnswers, regionItems };
