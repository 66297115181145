import React, {useState, useEffect} from 'react'
import { connect } from "react-redux";
import {
  getRegionRating,
  setRegionRating,
  setRegionRatingLoading,
  getRatingValue,
  setRatingValue,
  getRatingCriterion,
  getExcelRating,
  removeRatingValue
} from "../../actions/regionRating";
import { getRegionGroups } from "../../actions/users";
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
  import Loader from '../../components/Loader/Loader';
import { getYearsArr } from '../../utils/yunarmyStat';
import styles from "./RegRating.module.css";
import RegRatingModal from './RegRatingModal';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFont from '../../fonts/fonts';
import logo from '../../assets/images/label.png';
import { MdPictureAsPdf } from "react-icons/md";
import { PiFileXlsFill } from "react-icons/pi";
import { getPlaceList } from '../../utils/regRating';
import { createNotification } from '../../components/Notifications/Notifications';



function RegRating({
    data, 
    loading,
    userRegions,
    accessRights,
    cellLoading,
    getRegionRating,
    setRegionRatingLoading,
    getRatingCriterion,
    getExcelRating,
    setRegionRating,
    getRatingValue,
    setRatingValue,
    removeRatingValue,
    ratingValue,
    confirmLoading,
    criterions,
    regionGroups,
    getRegionGroups,
    status,
    closedDate,
    token
}) {
  
    
  const [sortedBy, setSortedBy] = useState('rating')
  const [year, setYear] = useState(new Date().getFullYear())
  const [selectedItem, setSelectedItem] = useState(null)
  const [groupView, setGroupView] = useState(true)

  useEffect(() => {
    getRegionRating(year, sortedBy, 'region')
    setRegionRatingLoading(true)
    getRatingCriterion(year)
    !regionGroups && getRegionGroups()
  }, [year, sortedBy])

  const closeModal = () => {
    setSelectedItem(null);
  };

  const renderRow = (placesList) => (it, i) => {
    const thisRegionRights = accessRights.ac_read || userRegions.includes(it.regionId)
    return (
      <tr
        key={it.name}
        onClick={() => thisRegionRights && setSelectedItem(it)}
        style={(userRegions.length===1 && userRegions[0]===it.regionId) ? {backgroundColor: "rgba(55, 250, 7, 0.801)"} : null}
      >
        <td>{it.name}</td>
        <td>{it.total_rating}</td>
        <td>{placesList[i]}</td>
      </tr>
    )
  }

  const createDocData = async () => {
    
    addFont(jsPDF.API)
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'p', lineHeight: 0.5 })
		doc.setFont("Montserrat");

    doc.setFontSize(12)
    doc.text("Всероссийское детско-юношеское военно-патриотическое", 100, 10, "center" )
    doc.text("общественное движение «ЮНАРМИЯ»", 100, 15, "center" )
    doc.setFontSize(14).text("РЕГИОНАЛЬНЫЙ РЕЙТИНГ", 100, 25, "center" )
    doc.setFontSize(11).text(`за ${year} год`, 100, 29, "center" )
    const img = new Image()
    img.src = logo
    doc.addImage(img, 'png', 5, 5, 25, 25)
    doc.autoTable({
      html: `#regRatingTable`,
      startY: doc.pageCount > 1? doc.autoTableEndPosY() + 10 : 35,
      styles: {
        font: 'Montserrat',
        fontSize: 8
      },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
      },
      didParseCell: (data) => {
        if (data.cell.colSpan===3) {
          data.cell.styles.fillColor = 'rgb(248, 207, 249)';
        }
      }
    })

    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`regrating.pdf`))
  }

  const getRows = () => {
    
    let rows = []
    let usedRegions = []

    if (groupView) {
      regionGroups.forEach(group => {
        rows.push(<tr>
          <td style={{backgroundColor: "rgb(55 104 100)", color: "#f5a26b", fontSize: "17px"}} colSpan={3}>{group.group_name}</td>
        </tr>)
  
        const regions = group.regions.map(reg => reg.id)
        const filteredData = data.filter(item => regions.includes(item.regionId))
        const placesList = getPlaceList(filteredData)
        const newRows = filteredData.map(renderRow(placesList))
        rows = [...rows, ...newRows]
        usedRegions = [...usedRegions, ...regions]
      })
      
      rows.push(<tr>
        <td style={{backgroundColor: "rgb(55 104 100)", color: "#f5a26b", fontSize: "17px"}} colSpan={3}>Остальные</td>
      </tr>)

      const filteredData = data.filter(item => !usedRegions.includes(item.regionId))
      const placesList = getPlaceList(filteredData)
      const newRows = filteredData.map(renderRow(placesList))
      rows = [...rows, ...newRows]
    } else {
      const placesList = getPlaceList(data)
      rows = data.map(renderRow(placesList))
    }
    
    return rows
  }

  const loadXlsx = async () => {
    const url = await getExcelRating(year)
    if (!url) return createNotification('error', 'Ошибка загрузки xlsx файла')
    const a = document.createElement('a')
    a.href = url
    a.download = `regrating ${year}.xlsx`
    a.click()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.filtersBlock}>
        <div className={styles.selectBlock}>
          <div className={styles.year}>
            <span>Год:</span>
            <select onChange={(e) => setYear(e.target.value)} value={year}>
              {getYearsArr(2016).map((item) => (
                <option key={item} value={item}>{item}</option>
              ))}
            </select>
          </div>
        </div>
        <div className={styles.filter}>
          <div>Сортировать по:</div>
          <select onChange={(e) => setSortedBy(e.target.value)} value={sortedBy}>
            <option value='rating'>рейтингу</option>
            <option value='name'>алфавиту</option>
          </select>
        </div>
        <CustomCheckbox
          label="по группам"
          value={groupView}
          color="rgb(136 37 50)"
          onChange={() => setGroupView(!groupView)}
        />
        <ButtonRed
          style={{backgroundColor: 'brown', marginLeft: "20px"}}
          onClick={toPdf}
        >
          <MdPictureAsPdf
            color='white'
            size={22}
            style={{marginRight: "5px"}}
            /> 
           скачать
        </ButtonRed>
        <ButtonRed
          onClick={loadXlsx}
          style={{background: 'linear-gradient(45deg, #209e63, #0f783f)'}}
        >
          <PiFileXlsFill
            color='white'
            size={22}
            style={{marginRight: "5px"}}
          />
          скачать
        </ButtonRed>
      </div>
        {loading ? <Loader top="50%" left="50%" /> :
        <div className={styles.tableBlock} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          {year && <h1>{`Региональный рейтинг за ${year}г.`}</h1>}
          <div className={`${styles.newTable}`}>
            {data && regionGroups && ( 
              <table id='regRatingTable'>
                <thead>
                    <tr>
                      <th style={{width: "50vw"}}>Регион</th>
                      <th style={{width: "25vw"}}>Баллов за год</th>
                      <th style={{width: "25vw"}}>Место</th>
                    </tr>
                </thead>
                <tbody>
                  {getRows()}
                </tbody>
              </table>
            )}
          </div>
        </div>}
        {selectedItem && (
        <RegRatingModal
          open={selectedItem}
          year={year}
          sortedBy={sortedBy}
          onClose={closeModal}
          userRegions={userRegions}
          selectedItem={selectedItem}
          setRegionRating={setRegionRating}
          cellLoading={cellLoading}
          getRatingValue={getRatingValue}
          data={data}
          removeRatingValue={removeRatingValue}
          closedDate={closedDate}
          status={status}
          loading={confirmLoading}
          criterions={criterions}
          ratingValue={ratingValue}
          setRatingValue={setRatingValue}
          token={token}
          accessRights={accessRights}
        />
      )}
    </div>
  );
}


const mapStateToProps = ({
  auth,
  region_rating,
  users
}) => ({
    data: region_rating.data,
    closedDate: region_rating.closed_date,
    cellLoading: region_rating.cellLoading,
    loading: region_rating.loading,
    confirmLoading: region_rating.confirm_loading,
    ratingValue: region_rating.rating_value,
    criterions: region_rating.criterion,
    token: auth.token,
    regionGroups: users.regionGroups,
    status: auth.superuser ? ["superuser", "agsh", "nrsh"]
            : auth.user.agsh ? ["agsh", "nrsh"]
            : auth.user.nrsh ? ["nrsh"]
            : [],
    accessRights: auth.accessRights.find(i => i.name==='regional_rating')||{},
    userRegions: auth.userRegions
  });
  
  export default connect(mapStateToProps, {
    getRegionRating,
    setRegionRating,
    setRegionRatingLoading,
    getRatingValue,
    removeRatingValue,
    getRatingCriterion,
    setRatingValue,
    getRegionGroups,
    getExcelRating
  })(
    RegRating
  );