import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { confirmParamTypes, paramsEditUser } from "../../utils/const";
import styles from "./RatingParams.module.scss";
import { MdClose, MdEdit} from "react-icons/md";
import { transliterate } from "../../utils/translit";
import NoData from "../../components/NoData/NoData";
import { createNotification } from "../../components/Notifications/Notifications";
import CustomTextarea from "../../components/CustomTextarea/CustomTextarea";

const addState = {
  title: "",
  name: "",
  type: "1",
  order: ""
}

const RatingParamsModal = ({
  type,
  selectedItem,
  loading,
  userInfo,
  editRatingParam,
  ...props
}) => {
  
  const [item, setItem] = useState(null);
  const [addPlug, setAddPlug] = useState(null)
  const [editingItem, setEditingItem] = useState(null)

  useEffect(() => {
    if (type === "ADD") {
      setItem({
        chief: "",
        city: "",
        email: "",
        regionId: "",
        name: "",
        phone: "",
        position: "",
        decision: "",
      });
    } else {
      selectedItem && setItem(selectedItem);
    }
  }, [selectedItem]);


  const handleAddRegion = () => {
  };

  const handleEditRegion = () => {
    editRatingParam(item)
    props.onClose()
  };

  const handleClose = () => {
    setItem(null);
    props.onClose();
  };

  const handleInputChange = (e) => {
    const obj = { ...item };
    obj[e.target.name] = e.target.value;
    setItem(obj);
  };

  const confirmReqClick = () => {
    const newItem = {...item}
    if (item.confirmation_required) {
      delete newItem.dynamic_fields
    } else {
      newItem.dynamic_fields = {
        table: false,
        fields: []
      }
    }
    setItem({
      ...newItem,
      confirmation_required: !item.confirmation_required
    })
  }

  const handleAddParam = () => {
    const types = item.dynamic_fields.fields.map(item => Number(item.type))
    if (addPlug.type==3 && types.includes(3)) {
      createNotification('error', 'Параметр с типом "файл" может быть только 1')
    } else {
      const newParamsObject = JSON.parse(JSON.stringify(item))
      newParamsObject.dynamic_fields.fields.push(addPlug)
      newParamsObject.dynamic_fields.fields.sort((a, b) => Number(a.order) - Number(b.order))
      setItem(newParamsObject)
      setAddPlug(null)
    }
    
  }

  const handleSetPlugValue = (e, editing) => {
    const value = editing ? editingItem : addPlug
    const newValue = {...value, [e.target.name]: e.target.value}
    e.target.name === "title" && (newValue.name = transliterate(e.target.value))
    editing ? setEditingItem(newValue) : setAddPlug(newValue)
  }

  const handleDeleteParamsItem = (index) => {
    const newValue = JSON.parse(JSON.stringify(item))
    newValue.dynamic_fields.fields.splice(index, 1)
    setItem(newValue)
  }

  const saveEditedItem = () => {
    const index = editingItem.index
    const newValue = {...editingItem}
    delete newValue.index
    const newItem = JSON.parse(JSON.stringify(item))
    newItem.dynamic_fields.fields[index] = newValue
    newItem.dynamic_fields.fields.sort((a, b) => Number(a.order) - Number(b.order))
    setItem(newItem)
    setEditingItem(null)
  }

  const tableModeChange = () => {
    const newItem = JSON.parse(JSON.stringify(item))
    const newValue = !item.dynamic_fields.table
    newItem.dynamic_fields.table = newValue
    setItem(newItem)
  }

  return (
    <Modal
      onClose={handleClose}
      open={props.open}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {type === "ADD" ? "Добавление парамтера" : "Редактирование параметра"}
      </div>
      {item && (
        <div className={styles.modalBody}>
          <div className={styles.inputsBlock}>
            <CustomTextarea
              name="name"
              value={item.name}
              title="Название"
              disabled={!userInfo.superuser && !userInfo.agsh}
              onChange={handleInputChange}
            />
            <CustomInput
              name="data_format"
              value={item.data_format}
              title="Формат данных"
              disabled
              onChange={handleInputChange}
            />
            <CustomTextarea
              name="description"
              value={item.description}
              title="Описание"
              disabled={!userInfo.superuser && !userInfo.agsh}
              onChange={handleInputChange}
            />
            <CustomSelect
              title="Кто может редактировать"
              options={paramsEditUser}
              name="access"
              selectedValue={item.access}
              disabled={!userInfo.superuser && !userInfo.agsh}
              onChange={handleInputChange}
              selwidth="30%"
              />
            <CustomCheckbox
              label="Рассчитывается автоматически"
              value={item.is_calculated_automatically}
              onChange={() => setItem(prev => ({...prev, is_calculated_automatically: !item.is_calculated_automatically}))}
            />
            <CustomCheckbox
              label="Активный"
              value={item.active}
              onChange={() => setItem(prev => ({...prev, active: !item.active}))}
            />
            <CustomCheckbox
              label="Требует подтверждения"
              value={item.confirmation_required}
              onChange={confirmReqClick}
            />
            {item.confirmation_required && item.dynamic_fields &&
              <div className={styles.paramsBlock}>
                <div className={styles.paramsTitle}>Параметры подтверждения:</div>
                <div className={styles.paramsList}>
                  {item.dynamic_fields.fields.length
                    ? item.dynamic_fields.fields.map((it, index) =>
                        <div
                          key={index}
                          className={styles.paramsItem}
                          style={index%2 ? {backgroundColor: "#c1e9e5"} : null}
                        >
                          {!editingItem && <MdClose
                            onClick={() => handleDeleteParamsItem(index)}
                          />}
                          {!editingItem &&
                          <MdEdit
                            onClick={() => setEditingItem({...it, index: index})}
                          />}
                          <div className={styles.firstRow}>
                            <span>Название поля:</span>
                            <textarea
                              value={editingItem?.index===index 
                                ? editingItem.title
                                : it.title
                              }
                              onChange={(e) => handleSetPlugValue(e, true)}
                              name="title"
                              disabled={editingItem?.index !== index}
                            />
                          </div>
                          <div className={styles.secondRow}>
                            <div>
                              <span>Тип поля:</span>
                              <select
                                value={editingItem?.index===index
                                  ? editingItem.type
                                  : it.type
                                }
                                onChange={(e) => handleSetPlugValue(e, true)}
                                name="type"
                                disabled={editingItem?.index !== index}
                              >
                                {
                                  confirmParamTypes.map(it =>
                                      <option value={it.value}>{it.name}</option>
                                  )
                                }
                              </select>
                            </div>
                            <div>
                              <span>Порядок отображения:</span>
                              <input
                                value={editingItem?.index===index 
                                  ? editingItem.order
                                  : it.order
                                }
                                onChange={(e) => handleSetPlugValue(e, true)}
                                type="number"
                                min="0"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                name="order"
                                disabled={editingItem?.index !== index}
                              />
                            </div>
                          </div>
                          {editingItem?.index===index &&
                          <button
                            onClick={saveEditedItem}
                          >
                            Сохранить
                          </button>}
                        </div>
                      )
                      : <NoData
                          text="данные отсутствуют"
                          style={{margin: "15px 0"}}
                        />
                    }
                </div>
                {!!item.dynamic_fields.fields.length &&
                <div className={styles.requiredCheckbox}>
                  <CustomCheckbox
                    label="таблица"
                    value={item.dynamic_fields.table}
                    onChange={tableModeChange}
                  />
                </div>}
                {addPlug ?
                  <div className={styles.paramsItem} style={{
                    background: "#ffc7c7",
                    maxWidth: "80%"
                  }}>
                    <MdClose
                      onClick={() => setAddPlug(null)}
                      />
                    <div className={styles.firstRow}>
                            <span>Название поля:</span>
                            <textarea
                              value={addPlug.title}
                              onChange={handleSetPlugValue}
                              name="title"
                            />
                          </div>
                          <div className={styles.secondRow}>
                            <div>
                              <span>Тип поля:</span>
                              <select
                                selectedValue={addPlug.type}
                                onChange={handleSetPlugValue}
                                name="type"
                              >
                                {
                                  confirmParamTypes.map(it =>
                                      <option value={it.value}>{it.name}</option>
                                  )
                                }
                              </select>
                            </div>
                            <div>
                              <span>Порядок отображения:</span>
                              <input
                                value={addPlug.order}
                                onChange={handleSetPlugValue}
                                type="number"
                                min="0"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                name="order"
                              />
                            </div>
                      </div>
                    <button
                      onClick={handleAddParam}
                    >
                      Добавить
                    </button>
                  </div>
                  :
                  <button
                    onClick={() => setAddPlug(addPlug ? null : addState)}
                  >
                    Добавить параметр
                  </button>
                }
              </div>
            }
          </div>
          <div className={styles.modalButtons}>
            {(userInfo.superuser || userInfo.agsh) && (
              <ButtonRed
                onClick={
                  type === "ADD" ? handleAddRegion : handleEditRegion
                }
              >
                {type === "ADD" ? "Добавить" : "Сохранить"}
              </ButtonRed>
            )}
            <ButtonRed onClick={handleClose}>Закрыть</ButtonRed>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RatingParamsModal;
