const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const getYearsArr = (finalYear) => {
  let year = new Date().getFullYear();
  const res = [];
  while (year >= finalYear) {
    res.push(year);
    year--;
  }
  return res;
};

export const getModifyData = (arr) => {
  const params = Array.from(new Set(arr.map((it) => it.paraname)));
  const qarts = Array.from(
    new Set(arr.map((it) => new Date(it.quarter).getMonth()))
  );
  const years = Array.from(
    new Set(arr.map((it) => new Date(it.quarter).getFullYear()))
  );
  const resArray = [];
  for (const par of params) {
    const _years = [];
    for (const year of years) {
      const _quarts = [];
      for (const qart of qarts) {
        const item = arr.find(
          (it) =>
            it.paraname === par &&
            new Date(it.quarter).getFullYear() === year &&
            new Date(it.quarter).getMonth() === qart
        );
        _quarts.push(item ? item : null);
      }
      _years.push({ year: year, quarts: _quarts });
    }
    resArray.push({ param: par, years: _years });
  }
  return resArray;
};

export const getModifyRatingData = (arr) => {
  
  const resArray = []

  const regions = Array.from(
    new Set(arr.map((it) => it.region_department_name))
  ).sort();

  for (let region of regions) {
    let i = 0
    const array = []
    while (i < 12) {
      const item = arr.find(it => it.region_department_name === region && new Date(it.date).getMonth() === i)
      array.push(item ? item : null)
      i++
    }
    resArray.push({region: region, rating: array})
  }

  return (resArray);
};

export const ratingTableHeader = (arr) => {
  const firstRow = []
  const secondRow = []
  const titlesArr = arr.map(it => it.name)
  let counter = 0
  let savedTitle = ''
  for (const title of titlesArr) {
    const titleArr = title.split(' - ')
    if (counter) {
      if (titleArr.length<2) {
        firstRow.push({ title: savedTitle, col: counter, row: 1 })
        firstRow.push({ title: title, col: 1, row: 2 })
        counter = 0
      } else {
        if (titleArr[1]===savedTitle) {
          counter++
        } else {
          firstRow.push({ title: savedTitle, col: counter, row: 1 })
          counter = 1
        }
        savedTitle = titleArr[1]
        secondRow.push({ title: titleArr[0], col: 1, row: 1 })
      }
    } else {
      if (titleArr.length<2) {
        firstRow.push({ title: title, col: 1, row: 2 })
        counter = 0
      } else {
        savedTitle = titleArr[1]
        counter++
        secondRow.push({ title: titleArr[0], col: 1, row: 1 })
      }
    }
  }
  if (counter) {
    firstRow.push({ title: savedTitle, col: counter, row: 1 })
  }
  return {firstRow, secondRow}
}

export const filterRatingData = (arr) => {
  const idList = arr[0].values.filter(i => !i.hasOwnProperty('active') || i.active).map(i => i.rating_parameter_id)
  arr.forEach(element => {
    const filtered = element.values.filter(i => idList.includes(i.rating_parameter_id))
    element.values = filtered
  });
  return arr
} 
