import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import {
  getDigitalCount,
  getDigitalItems,
  deleteDigitalItem,
  addDigitalItem,
  editDigitalItem,
  getDigitalCategory,
  uploadBigdFile
} from '../../actions/digital'
import { IoMdClose } from "react-icons/io";
import DigitalModal from './DigitalModal';
import Loader from '../../components/Loader/Loader';
import styles from './Digital.module.css'
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed';
import { Pagination } from '../../assets/common/Pagination/Pagination';
import moment from 'moment';
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import ItemsPerPage from '../../components/ItemsPerPage';



function Digital({
  getDigitalCount,
  getDigitalItems,
  deleteDigitalItem,
  addDigitalItem,
  editDigitalItem,
  pageCount,
  getDigitalCategory,
  category,
  data,
  loading,
  itemsOnPage,
  accessRights,
  uploadBigdFile,
  token
}) {
  
  const deleteRights = accessRights.ac_delete

  const [numberPage, setNumberPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [sort, setSort] = useState("");
  const [confirmation, setConfirmation] = useState({txt: null, id: null});
  const [text, setText] = useState('');


  useEffect(() => {
    getDigitalCount(text);
    getDigitalItems(numberPage+1, sort, text);
  }, [numberPage, itemsOnPage, sort, text])
  
  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      const obj = data.find(it => it.id === selectedItem.id)
      setSelectedItem(obj)    
    }
  }, [data])

  const openModal = (type, item) => () => {
    setSelectedItem(item)
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleDeleteDigital = (id) => {
    deleteDigitalItem(id, numberPage+1)
  }
  

  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  const acceptConfirmation = (id) => {
    handleDeleteDigital(id);
    setConfirmation({txt: null, id: null});
  };
  
  const rejectConfirmation = () => {
    setConfirmation({txt: null, id: null});
  };

  const handleStatusClick = (data) => () => {
    const status = data.status === 2 ? 1 : 2
    const payload = {...data, status}
    editDigitalItem(payload, numberPage + 1)
  }

  return (
    <>
      <div className={styles.container}>
      {confirmation?.id && 
          <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation.txt}
          acceptConfirmation={() => acceptConfirmation(confirmation.id)}
          rejectConfirmation={rejectConfirmation}
          />
          }
      {loading && !isModalOpen && <Loader top="45%" left="45%" />}
        <div className={styles.title}>Цифровая академия юнармейца</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
          {accessRights.ac_create && <ButtonRed onClick={openModal("ADD")}>Добавить</ButtonRed>}
          <div className={styles.selectBlock}>
              <span>Название</span>
              <input value={text} onChange={e => setText(e.target.value)} placeholder="Поиск по названию"/>
          </div>
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Цифровая академия юнармейца</div>
          </div>
          <div className={styles.table}>
            {data &&
              data.slice()
                  .sort((a, b) => b.created - a.created)
                  .map((a) => (
                  <div key={a.id} className={styles.tableRow}>
                    <div>
                      <CustomCheckbox
                        onClick={handleStatusClick(a)}
                        value={a.status === 2}
                        title='Опубликовано'
                      />
                    </div>
                    <div
                      className={styles.displayname}
                      onClick={openModal("EDIT", a)}
                    >
                      {a.name}
                    </div>
                    <div className={styles.date}>{moment(a.created).format('L')}</div>
                    <div className={styles.duration}>{a.length}</div>
                    <div className={styles.actions}>
                      {deleteRights
                        ? <i className={styles.deleteIcon} onClick={() => setConfirmation({txt: a.name, id: a.id})}>
                            <IoMdClose fontSize={24} color={'red'}/>
                          </i>
                        : <div style={{height:'1.5rem'}}></div>
                      }
                    </div>
                  </div>
                ))}
          </div>
          <div className={styles.pagination}>
            <Pagination pageCount={pageCount} numberPage={numberPage} onPageChange={handlePageClick}/>
          </div>
          <div className={styles.controls}>
            <div
                onClick={handleSortClick}
                className={`${styles.sortBlock} ${sort ? styles.sortActive : ''}`}
            >
                {sort && sort==='desc'
                ? <BsSortDown/>
                : <BsSortDownAlt/>}
            </div>
            <ItemsPerPage/>
          </div>
        </div>
        
      </div>
      {isModalOpen && <DigitalModal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        numberPage={numberPage}
        loading={loading}
        editDigitalItem={editDigitalItem}
        addDigitalItem={addDigitalItem}
        getDigitalItems={getDigitalItems}
        getCategory={getDigitalCategory}
        data={data}
        category={category}
        accessRights={accessRights}
        uploadBigdFile={uploadBigdFile}
        token={token}
        setText={setText}
      />}
    </>
  )
}

const mapStateToProps = (state) => ({
    pageCount: state.digital.page_count,
    data: state.digital.data,
    category: state.digital.category,
    loading: state.digital.loading,
    lastUpdate: state.schedule.lastUpdate,
    itemsOnPage: state.auth.itemsOnPage,
    accessRights: state.auth.accessRights.find(i => i.name==='digital_academy')||{},
    token: state.auth.token
  })

export default connect(mapStateToProps, {
  getDigitalCount,
  getDigitalItems,
  deleteDigitalItem,
  addDigitalItem,
  editDigitalItem,
  getDigitalCategory,
  uploadBigdFile
})(Digital)