import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import {
  addNews,
  getNews,
  editNews,
  editedNewsUpdate,
  getCurrentNew
} from "../../actions/news";
import moment from "moment";
import DatePickerField from "../../components/shared/DatePickerField";
import ImageField from "../../components/shared/ImageField";
import { PATHS } from "../../routing/Routes";
import TextField from "../../components/shared/TextField";
import WysiwygField from "../../components/shared/WysiwygField";
import { NAME_SCREENS } from "../../utils/const";
import Loader from "../../components/Loader/Loader";

const fiels = [
  "image",
  "description",
  "title",
  "linktext",
  "linkurl",
  "articledate",
  "pubdate",
];

const AddNews = ({
  formValue,
  initialValues,
  handleSubmit,
  change,
  pathname,
  editedNewsUpdate,
  getCurrentNew,
  getNews,
  accessRights,
  history,
  editNews,
  addNews,
  sendRequest,
  userInfo,
  loading,
  ...props
}) => {
  
  const updateRights = pathname.includes('add') || accessRights.ac_update || formValue?.userid === userInfo.id


  const [image, setImage] = useState(null);

  useEffect(() => {
    if (formValue && formValue.image) {
      setImage(formValue.image);
    } else {
      setImage(null);
    }

    if (formValue !== initialValues || (formValue && !initialValues)) {
      editedNewsUpdate(true);
    }
  }, [formValue, initialValues]);

  useEffect(() => {
    if (
      pathname === "/news/add_news" ||
      pathname === "/event/add_event" ||
      pathname === "/ads/add_ads"
    ) {
      fiels.map((item) => change( item, item === "articledate" || item === "pubdate" ? new Date() : null));
    } else {
      getCurrentNew(props.match.params.newId)
    }
  }, [pathname]);

  const screen = () =>
  pathname === "/event/add_event" ? 2 : pathname === "/news/add_news" ? 1 : 3;

  const goToScreen = () => {
    if (formValue !== initialValues || (formValue && !initialValues)) {
      const action = window.confirm(
        "Есть несохраненные данные. Уйти со страницы?"
      );
      if (action) {
        editedNewsUpdate(false);
      } else {
        return false;
      }
    }

    let path = "";
    
    if ((/event/i).test(pathname)) {
      path = PATHS.AUTH.EVENT;
    } else if ((/news/i).test(pathname)) {
      path = PATHS.AUTH.NEWS;
    } else {
      path = PATHS.AUTH.ADS;
    }

    history.push(path);
  };

  const resetImage = () => {
    change("image", null);
  }
  
  const addNew = (values) => {
    if (
      initialValues && /edit/i.test(pathname)
    ) {
      editNews(values?.id, formValue, history, pathname);
    } else {
      addNews(
        {
          articledate: moment(values.articledate).format("YYYY-MM-DD"),
          pubdate: moment(values.pubdate).format("YYYY-MM-DD"),
          articletype: screen(),
          linktext: values.linktext || "",
          linkurl: values.linkurl || "",
          title: values.title || "",
          description: values.description || "",
          starred: false,
          // archived: false,
          source: "",
          status: 1,
          image: values.image || null,
        },
        history,
        pathname
      );
    }
    editedNewsUpdate(false);
  };

  return (
    <div className="addNews">
      <div className="addNews__container container">
        <div className="newsListContainer__title">{`${/edit/i.test(pathname) ? "Редактирование" : "Добавление"} ${/ads/i.test(pathname) ? "объявления" : "новости"}`}</div>

        {loading ? <div className="addNewsBody">
          <Loader top="50%" left="49%"/>
        </div> :
          <div className="addNewsBody">
          <div className="addNewsBody__left">
            <p className="news__label">Заголовок</p>
            <TextField
              name="title"
              disabled={!updateRights}
              className="newsTitle"
            />

            <p className="news__label">Текст</p>
            <WysiwygField
              className="newsText"
              name="description"
              readOnly={!updateRights}
            />
          </div>

          <div className="addNewsBody__right">
            <p className="news__label">Фото</p>
            <div className="news-right-foto">
              <ImageField name="image" className="news-fotoBlock" />
              {image && updateRights && (
                <div className="news-right-foto__btns">
                  <button onClick={resetImage}>Очистить</button>
                </div>
              )}
            </div>

            <p className="news__label">Источник</p>
            <TextField
              name="linktext"
              className="news-source"
              disabled={!updateRights}
            />

            <p className="news__label">Ссылка на источник</p>
            <TextField
              name="linkurl"
              className="news-linkSource"
              disabled={!updateRights}
            />

            <p className="news__label">Дата</p>
            <DatePickerField
              name="articledate"
              className="news-datepicker"
              readOnly
            />

            <p className="news__label news__label_mt">Дата публикации</p>
            <DatePickerField
              name="pubdate"
              className="news-datepicker"
              disabled={!updateRights}
              />
          </div>
        </div>}
        <div className="news-left-btns">
          {updateRights && 
            <button
            className="news-left-btn news-left-btn__save"
            onClick={handleSubmit(addNew)}
            disabled={sendRequest}
          >
            Сохранить
          </button>}
          <button
            className="news-left-btn news-left-btn__close"
            onClick={goToScreen}
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
};

const AddNewsForm = reduxForm({
  form: "news",
  enableReinitialize : true
})(AddNews);

const mapStateToProps = (state, props) => {
  const {
    layout: { sendRequest },
    auth: { user },
    schedule: { currentNew, loading }
  } = state;
  const { location } = props;
  const category = (/news/i).test(location.pathname)
                      ? 'news'
                      : 'ads'
  const form = getFormValues("news")(state);
  return {
    loading,
    userInfo: user,
    formValue: form,
    initialValues: (/edit/i).test(location.pathname) ? currentNew : {},
    pathname: location.pathname,
    sendRequest,
    accessRights: state.auth.accessRights.find(i => i.name===category)||{}
  };
};

export default connect(mapStateToProps, {
  addNews,
  getNews,
  editNews,
  editedNewsUpdate,
  getCurrentNew
})(AddNewsForm);
