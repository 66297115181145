import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { SET_PERMISSION_GROUPS, SET_PERMISSION_GROUP_INFO, SET_PERMISSION_GROUP_USERS, SET_PERMISSION_LOADING } from "./types";
import { checkAndUpdateToken } from "./auth";

export const getPermissionGroups = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_group`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_PERMISSION_GROUPS, payload });
    }
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};

export const getPermissionGroupInfo = (groupId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_group/${groupId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_PERMISSION_GROUP_INFO, payload });
    }
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};


export const deletePermissionGroup = (groupId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_group/${groupId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const { permissionGroups } = getState().roles.permission_groups
      const payload = permissionGroups.filter(group => group.id !== groupId)
      dispatch({ type: SET_PERMISSION_GROUPS, payload });
    }
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};

export const updatePermissionGroup = (groupId, data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_group/${groupId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getPermissionGroups());
    }
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};

export const createPermissionGroup = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/permission_group`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getPermissionGroups());
    }
  }
  dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};


export const getGroupUsers = (groupId) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
    dispatch({ type: SET_PERMISSION_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/perm_group_to_user/role/${groupId}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_PERMISSION_GROUP_USERS, payload });
    }
    }
    dispatch({ type: SET_PERMISSION_LOADING, payload: false });
};