import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import Modal from "react-responsive-modal";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import Loader from "../../components/Loader/Loader";
import { createNotification } from "../../components/Notifications/Notifications";
import ConfirmationMode from "./ConfirmationMode/ConfirmationMode";
import styles from "./RegRating.module.css";
import voprosikImg from "../../assets/images/Voprosik.png"
import { declin } from "../../utils/declination";

const RegRatingModal = ({
  onClose,
  year,
  sortedBy,
  data,
  status,
  selectedItem,
  userRegions,
  accessRights,
  cellLoading,
  setRegionRating,
  removeRatingValue,
  getRatingValue,
  ratingValue,
  setRatingValue,
  criterions,
  loading,
  closedDate,
  token
}) => {
  const [_data, setData] = useState(null);
  const [currentCell, setCurrentCell] = useState(null);
  const [uploadValueId, setUploadValueId] = useState(null)
  const [confirmationMode, setConfirmationMode] = useState(null);
  const [ratingValueAction, setRatingValueAction] = useState(false);
  const [unsaveData, setUnsaveData] = useState(false)
  const [haveEditRight, setHaveEditRight] = useState(false)

  useEffect(() => {
    const management = status.includes('superuser') || status.includes('agsh')
    const yearCondition = true;
    const dateCondition = moment(moment()).isSameOrBefore(closedDate);
    const haveRight = management || (yearCondition && dateCondition)
    setHaveEditRight(haveRight)
  }, [year, closedDate])

  useEffect(() => {
    const obj = data.find((item) => item.id === selectedItem.id);
    const newData = JSON.parse(JSON.stringify(obj));
    setData(newData);
  }, [data]);

  const handleChangeCell = (e) => {
    const obj = { ...currentCell };
    const newVal = e.target.value
    obj.value = newVal ? Number(newVal) : null
    obj.changed = true;
    setCurrentCell(obj);
  };

  const handleClickCell = (item) => {
    if (haveEditRight) {
      if (userRegions.includes(selectedItem.regionId)) {
        if (status.includes(item.access)) {
          [15, 25, 29, 30, 35, 36, 42, 43].includes(item.rating_parameter_id)
          ? changeBooleanCellValue({
            ...item,
            region_department_id: item.id,
          })
          : setCurrentCell({
            ...item,
            region_department_id: item.id,
          });
        } else {
              let message
              if (item.access==="nobody") { message = "Вы не имеете права редактировать данный параметр!" }
              else if (status.length===1) { message = "Данный параметр рейтинга может редактировать только АГШ/SuperUser!" }
              else if (!status.length) { message = "Данный параметр рейтинга может редактировать только АГШ/НРШ/SuperUser!" }
              else { message = "Доступ к данному параметру отсутствует" }
              createNotification("error", message, null, 2000);
        }
      } else {
        createNotification("error", "Доступ к данному региону отсутствует");
      }
    } else {
      createNotification("error", "Время редактирования рейтинга по данному году вышло");
    }
  };

  const changeBooleanCellValue = (data) => {
    const newValue = Number(!data.value);
    const postReqObj = {
      rating_id: data.rating_parameter_id,
      region_department_id: selectedItem.id,
      value: newValue,
      year: year,
    };
    const newData = JSON.parse(JSON.stringify(_data));
    const index = newData.values.findIndex(it => it.rating_parameter_id === data.rating_parameter_id)
    newData.values[index] = { ...data, value: newValue };
    setUploadValueId(data.rating_parameter_id)
    setRegionRating(postReqObj, year);
    setData(newData);
  };

  const setNewValueMonthly = () => {
    if (currentCell.changed) {
      const obj = { ...currentCell };
      const postReqObj = {
        rating_id: currentCell.rating_parameter_id,
        region_department_id: selectedItem.id,
        value: currentCell.value,
        year: year,
      };
      delete obj.changed;
      delete obj.score;
      delete obj.region_department_id;
      const newData = JSON.parse(JSON.stringify(_data));
      const index = newData.values.findIndex(it => it.rating_parameter_id === currentCell.rating_parameter_id)
      newData.values[index] = obj
      setUploadValueId(currentCell.rating_parameter_id)
      setRegionRating(postReqObj, year);
      setData(newData);
    }
    setCurrentCell(null);
  };

  const blurCell = (e) => {
    e.keyCode === 13 && e.target.blur();
  };

  const openConfirmationModal = (item) => {
      if (item.confirmation_required) {
      item.value_id && setConfirmationMode(item.value_id)
      !item.value_id && createNotification('error', 'Баллы не выставлены, либо параметр не указан.')
    }
  }

  const descrVisibleToggle = (id, newState) => {
    const element = document.getElementById(`description_${id}`)
    element.style.display = newState
  }

  const getRows = () => {
    const rows = [];
    criterions
      .filter((it) => !it.parent)
      .forEach((it) => {
        rows.push(
          <tr key={it.criterion}>
            <td
              colSpan={2}
              style={{pointerEvents: "none", backgroundColor: "#ff000038", fontWeight: "700"}}
            >
              {it.criterion}
            </td>
          </tr>
        );
        criterions
          .filter((el) => el.parent === it.id)
          .forEach((title) => {
            const filteredParams = _data.values.filter((elem) => elem.criterion_id === title.id)
            const scoreSum = filteredParams.reduce((accum, currentVal) => accum + currentVal.score||0, 0)
            const resScore = (title.max_value && title.max_value<scoreSum) ? title.max_value : scoreSum
            rows.push(
              <tr 
              key={title.criterion}>
                <td
                  style={{backgroundColor: "#0000ff36", pointerEvents: "none", fontWeight: "500"}}
                  >
                  {title.criterion}
                </td>
                <td
                  style={{backgroundColor: "#0000ff36", pointerEvents: "none", fontWeight: "500"}}
                >
                  Итого по разделу: 
                  <em><b> {resScore} {declin(resScore, 'балл')}</b></em>
                </td>
              </tr>
            );
            filteredParams.forEach((item) =>
                rows.push(
                  <tr key={item.name}>
                    <td
                      style={{textAlign: "left"}}
                      onDoubleClick={() => openConfirmationModal(item)}
                      className={styles.firstColumn}
                    >{item.name}</td>
                    <td
                      title={
                        item.value
                          ? `Изменено ${item.updated_by_name || ""} ${
                              moment(item.updated).format("LLL") || ""
                            }`
                          : null
                      }
                      onClick={() => handleClickCell(item)}
                      onDoubleClick={() => openConfirmationModal(item)}
                      style={
                        item.confirmation_required
                          ? item.is_confirmed
                            ? { backgroundColor: "rgba(55, 250, 7, 0.501)" }
                            : { backgroundColor: "rgba(255, 166, 0, 0.651)" }
                          : !status.includes(item.access)
                          ? { backgroundColor: "#ef4d4d" }
                          : null
                      }
                    >
                      {item.description &&
                      <img
                        src={voprosikImg}
                        alt="o_O"
                        onMouseEnter={() => descrVisibleToggle(item.rating_parameter_id, "block")}
                        onMouseLeave={() => descrVisibleToggle(item.rating_parameter_id, "none")}
                      />}
                      <div
                        id={`description_${item.rating_parameter_id}`}
                        className={styles.paramDescription}
                      >
                        {item.description}
                        <br/>
                        <br/>
                        {`Максимальное количество баллов - ${title.max_value||'неограничено'}`}
                      </div>
                      {cellLoading && uploadValueId===item.rating_parameter_id ? (
                        <Loader size={20} top="20%" left="46%" />
                      ) : [15, 25, 29, 30, 35, 36, 42, 43].includes(
                          item.rating_parameter_id
                        ) ? (
                        item.value ? (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <i>
                              <AiOutlineCheck size={16} />
                            </i>
                            {/* {!!item.score && `(${item.score} ${declin(item.score, 'балл')})`} */}
                          </div>
                        ) : null
                      ) : item && currentCell && item.rating_parameter_id === currentCell.rating_parameter_id ? (
                        <input
                          type="number"
                          min="0"
                          value={currentCell.value ? currentCell.value : null}
                          onKeyDown={blurCell}
                          onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()}
                          onChange={handleChangeCell}
                          onBlur={setNewValueMonthly}
                          autoFocus={true}
                        />
                      ) : item ? (
                        <div>
                          <span style={{fontSize: "15px"}}>{item.value}</span>
                          <br />
                          {!!(item.value && item.dynamic_fields && item.dynamic_fields.fields.length && !item.is_confirmed)
                            && "(требует подтверждения АГШ)"
                          }
                        </div>
                      ) : null}
                    </td>
                  </tr>
                )
              );
          });
      });
    rows.push(
      <tr key='total'>
        <td>Итого:</td>
        <td><b>{_data.total_rating} {declin(_data.total_rating, 'балл')}</b></td>
      </tr>
    );
    return rows;
  };

  const acceptConfirmation = () => {
    setConfirmationMode(null)
    setUnsaveData(false)
  }

  const rejectConfirmation = () => {
    setUnsaveData(false)
  }

  const closeConfirmMode = () => {
    setUnsaveData(true)
  }

  return (
    <Modal
      open={selectedItem}
      onClose={() => confirmationMode ? closeConfirmMode() : onClose()}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>
        {confirmationMode
          ? "Подтверждение параметра"
          : `Редактирование параметров для ${selectedItem.name}`}
      </div>
      <div className={styles.modalBody}>
        {confirmationMode ? (
          <ConfirmationMode
            year={year}
            token={token}
            sortedBy={sortedBy}
            valueId={confirmationMode}
            getRatingValue={getRatingValue}
            ratingValue={ratingValue}
            loading={loading}
            removeRatingValue={removeRatingValue}
            setRatingValue={setRatingValue}
            ratingValueAction={ratingValueAction}
            setRatingValueAction={setRatingValueAction}
            setConfirmationMode={setConfirmationMode}
            status={status}
            haveEditRight={haveEditRight}
          />
        ) : (
          _data &&
          criterions && (
            <div className={`${styles.tableWrap} ${styles.secondWrapStyle}`}>
              <table>
                  {getRows()}
              </table>
            </div>
          )
        )}
        {unsaveData &&
          <Confirmation
            title="На странице имеются несохраненные данные. Вы уверены что хотите ее покинуть?"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
          />
        }
        <div className="settingModal__btns">
          {confirmationMode && haveEditRight && (
            <button
              className="newsListHeader__btn btn_grey"
              type="button"
              onClick={() => setRatingValueAction(true)}
            >
              Сохранить
            </button>
          )}
          <button
            className="newsListHeader__btn btn_grey"
            onClick={() =>
              confirmationMode ? setConfirmationMode(null) : onClose()
            }
            type="button"
          >
            {confirmationMode ? "Отменить" : "Закрыть"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RegRatingModal;
