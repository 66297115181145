import { BACKEND_URL_PREFIX, newsOnPage } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import {
  SET_REGULATION_COUNT,
  SET_REGULATION_DATA,
  SET_REGULATION_SEARCH,
  SET_REGULATION_USER_LIST,
  SET_REGIONS_LIST_REGULATION,
  ERROR
} from "./types.js";

export const delSearch = () => ({
  type: SET_REGULATION_SEARCH,
  payload: null,
});

export const getRegulationObject =
  (page=1, type, search, region, sort) =>
  async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const offset = page * newsOnPage - newsOnPage;
      const params = {
        offset: offset,
        limit: newsOnPage,
        doc_type: type == 1 ? 'provision' : 'order',
      }

      if(!!search) params.name = search
      if(!!region) params.region_ids = `[${[region].join(',')}]`
      if(sort) params.alph = sort

      const urlParams = new URLSearchParams(params).toString();
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/article_document?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        
        const data = await response.json();
        dispatch({ type: SET_REGULATION_DATA, payload: data });
       
        const get = (type) => {
          if(type == 2) {
            return Math.ceil(data.count.total_rows_order / newsOnPage);
          } else if (type == 1) {
            return Math.ceil(data.count.total_rows_provision / newsOnPage);
          }
        }

        dispatch({
          type: SET_REGULATION_COUNT,
          payload: { data: data, pageCount: get(type) },
        });
        
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteRegulationObject =
  (id, numberPage, type) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/article_document/${id}`,
        {
          method: "DELETE",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        dispatch(getRegulationObject(numberPage, type));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const editRegulationObject =
  (id, data, numberPage, type, files) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/article_document/${id}`,
        {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        }
      );
      dispatch(getRegulationObject(numberPage, type));
      if (response.ok && files && files.length) {
        const data = await response.json();
        files.forEach(element => {
          if(element instanceof File) {
          dispatch(uploadImage(data.article_document.id, { file: element }, numberPage, type));  
        }
        });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const addRegulationObject =
  (data, numberPage, type, files) => async (dispatch, getState) => {
    if(data.approving_doc === null) {delete data.approving_doc}
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/article_document`, {
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });
      if (response.ok && files && files.length) {
        const data = await response.json();
        files.forEach(element => {
            dispatch(uploadImage(data.article_document.id, { file: element }, numberPage, type));  
        });
      } else {
        dispatch(getRegulationObject(numberPage, type));
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const uploadImage =
  (id, file, numberPage, type) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    const fd = new FormData();
    fd.append("file", file.file);

    if (getState().auth.token) {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/article_document/${id}/file`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          },
          body: fd,
        }
      );
      if (response.ok) {
        dispatch(getRegulationObject(numberPage, type));
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

export const deleteFile = (id, numberPage, type) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/article_document/file/${id}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      dispatch(getRegulationObject(numberPage, type));
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

  export const searchByTitle = (text) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/articles/2?web=true&text=${text}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: SET_REGULATION_SEARCH,
          payload: data,
        });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };

  export const getUserList = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/user`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        dispatch({
          type: SET_REGULATION_USER_LIST,
          payload: data,
        });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };


  export const getRegions = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/regions`, {
        ...buildDefaultHeaders(getState),
      });
  
      if (response.ok) {
        const payload = await response.json();
        dispatch({ type: SET_REGIONS_LIST_REGULATION, payload });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
  };