import React, {useState, useEffect} from 'react'
import styles from "./Mobile_app.module.css";
import label from "../../assets/images/label.png";

const MobileApp = () => {
  const [personalId, setPersonalId] = useState(null);
  const [otp, setOtp] = useState(null);

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const android = `yunarmy://login?personalId=${personalId}&otp=${otp}#Intent;package=com.nintegra.yunarmy;scheme=https;end`;
  const playMarket = `https://play.google.com/store/apps/details?id=com.nintegra.yunarmy`;
  const iphone = `yunarmy://login?personalId=${personalId}&otp=${otp}`;
  // const appStore = `https://apps.apple.com/ru/app/%D1%8E%D0%BD%D0%B0%D1%80%D0%BC%D0%B5%D0%B5%D1%86/id1608384459`;

  useEffect(() => {

    let id = window.location.search.replace( '?personalId=', '').split('&otp=')[0]; 
    let pw = window.location.search.replace( '?personalId=', '').split('&otp=')[1]; 

    id && setPersonalId(id);  
    pw && setOtp(pw);  
  }, [])

  useEffect(() => {
    if (personalId && otp) {
        if (/android/i.test(userAgent)) {
            document.getElementById('android').click()
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          document.getElementById('iphone').click(); 
        } else {
          document.getElementById('playMarket').click();
        }
      }
  }, [personalId, otp])
  

  return (
    <div className={styles.wrapper}>
        <div className={styles.mobile_appWrapper}>
            <img src={label} className={styles.mobile_appImg}/>
             <a 
                className={styles.mobile_appButton}
                id='android'
                hidden
                href={android}
            ></a>
            <a 
                className={styles.mobile_appButton}
                id='playMarket'
                hidden
                href={playMarket}
            ></a>
            <a 
                className={styles.mobile_appButton}
                id='iphone'
                hidden
                href={iphone}
            ></a>
        </div>
    </div>
  )
}

export default MobileApp;
