import React from 'react';
import { Field } from 'redux-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WrappedWysiwygField = ({ input, ...props }) => (
  <ReactQuill
    {...input}
    {...props}
    onBlur={(range, source, quill) => {
      input.onBlur(quill.getHTML());
    }}
  />
);

const WysiwygField = props => (
  <Field
    component={WrappedWysiwygField}
    {...props}
  />
);

export default WysiwygField;
