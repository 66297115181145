import React from 'react'
import styles from "./FindField.module.css"
import { IoIosSearch, IoIosClose } from "react-icons/io";



export default function FindField({
    searchInput,
    setSearchInput
}) {
  return (
    <div className={styles.searchBlock}>
      <IoIosSearch />
      <input 
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <IoIosClose                 
        onClick={() => setSearchInput("")}
      />
    </div>
  )
}
