import * as types from '../actions/types';

const initialState = {
    count: null,
    page_count: null,
    data: null,
    files_list: null,
    loading : false
  };

export default function videos (state = initialState, action) {
  switch (action.type) {
    case types.SET_VIDEOS_COUNT:
      return {
        ...state,
        count: action.payload.data,
        page_count: action.payload.pageCount
      };
      case types.SET_VIDEOS_DATA:
      return {
        ...state,
        data: action.payload
      };
      case types.SET_VIDEO_FILES_LIST:
      return {
        ...state,
        files_list: action.payload
      };
      case types.SET_VIDEO_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}

