export const declin = (number, word) => {
    let wordEnd
    const n = number%100
    const n1 = number%10
    if ((n>10 && n<20) || n1>4 || !n1) {
        wordEnd = 'ов'
    } else if (n1===1) {
        wordEnd = ''
    } else {
        wordEnd = 'а'
    }
    return `${word}${wordEnd}`
}

export const usersWord = (value) => {
    value = Math.abs(value) % 100; 
	var num = value % 10;
	if(value > 10 && value < 20) return 'пользователей'; 
	if(num > 1 && num < 5) return 'пользователя';
	if(num == 1) return 'пользователь'; 
	return 'пользователей';
}