import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-responsive-modal'
import { useParams, useHistory } from 'react-router-dom'
import { getCurrentNew } from '../../actions/news'
import styles from './styles.module.scss'
import { BACKEND_DOMAIN } from '../../utils/const'
import moment from 'moment'
import parse from "html-react-parser";
import Loader from '../../components/Loader/Loader'


export default function ViewEvent () {
  
  const dispatch = useDispatch()
  const { eventId } = useParams()
  const { loading, currentNew } = useSelector(state => state.schedule)
  const history = useHistory()


  useEffect(() => {
    dispatch(getCurrentNew(eventId))
  }, [])
  
  const {startdate, enddate, imageurl, title, place, participantlimit, registration_required, description, responsible, responsible_email} = currentNew || {}

  const dateRange = startdate && enddate ? `${moment(startdate).format('DD.MM.YYYY')} - ${moment(enddate).format('DD.MM.YYYY')}` : '-'

  const closeModal = () => history.push('/')


  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      open={true}
      onClose={closeModal}
      classNames={{ modal: styles.modal }}
    >
      {loading
        ? <Loader color='#911f1f' top='110px' left='260px' />
        : currentNew
          ? <>
              {imageurl && <img src={`${BACKEND_DOMAIN || ''}${imageurl}`} alt='o_O' />}
              
              <div className={styles.descriptionBlock}>
                
                <h2>{title}</h2>
                
                <ul className={styles.descriptionList}>
                  <li>
                    <span>Место проведения:</span>
                    {place || '-'}
                  </li>
                  <li>
                    <span>Сроки проведения:</span>
                    {dateRange}
                  </li>
                  <li>
                    <span>Ограничение количества участников:</span>
                    {participantlimit || '-'}
                  </li>
                  <li>
                    <span>Требуется регистрация:</span>
                    {registration_required ? 'Да' : 'Нет'}
                  </li>
                  <li>
                    <span>Куратор мероприятия:</span>
                    {responsible || '-'}
                  </li>
                  <li>
                    <span>Почта для связи:</span>
                    <a type='email' href={`mailto:${responsible_email}`} target='_blank' rel='noopener noreferrer'>{responsible_email}</a>
                  </li>
                </ul>

                {description &&
                  <div className={styles.description}>
                    <h2>Описание:</h2>
                    {parse(description)}
                  </div>
                }

              </div>
            </>

          : <h1>Новость не найдена</h1>
      }
    </Modal>
  )
}
