import React, {useState, useEffect} from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


 const _data = {
    labels: ['Yellow', 'Red'],
    datasets: [
      {
        label: '# of Votes',
        data: [0, 100],
        backgroundColor: [
          '#af0300',
          '#f09522',
        ],
        borderColor: [
          '#af0300',
          '#f09522',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
      plugins: {
          legend: { display: false },
          // tooltip: { enabled: false },
      }
  }


export default function PieChart({
  percent,
  labels
}) {

  const [data, setData] = useState({..._data})

  useEffect(() => {
    const obj = JSON.parse(JSON.stringify(_data))
    if (percent) {
      obj.datasets[0].data = [percent, 100-percent]
    }
    if (labels) {
      obj.labels = labels
    }
    setData(obj)
  }, [percent, labels])
  
  return (
    <div>
        <Pie
            data={data}
            options={options}
            width={100}
            height={100}
        />
    </div>
  )
}
