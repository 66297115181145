import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Li from '../../components/Li'
import { IoIosAdd } from 'react-icons/io'
import {thrash} from '../../assets/svg/other'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPermissionGroups, deletePermissionGroup } from '../../actions/permissions'
import Loader from '../../components/Loader/Loader'
import { declin, usersWord } from '../../utils/declination'
import Modal from './Modal'
import { useState } from 'react'
import { IoMdCreate } from 'react-icons/io';


export default function Roles () {
  
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(getPermissionGroups())
  }, [])
  
  const {permissionGroups: roles, loading} = useSelector(state => state.roles.permission_groups)

  const [selectedItem, setSelectedItem] = useState(null)

  const closeModal = () => setSelectedItem(null)

  const liClickHandler = (path, name) => () => history.push(`/roles/${path}`)
  

  const openModalHandler = (data) => (e) => {
    e.stopPropagation()
    setSelectedItem(data || {})
  }
  
  const thrashClickHandler = (groupId) => (e) => {
    e.stopPropagation()
    dispatch(deletePermissionGroup(groupId))
  }


  const renderLi = (data) => {
    const {displayname, id} = data
    return (
      <Li onClick={liClickHandler(id, displayname)}>
        <div className={styles.firstLiBlock}>
          <div>{displayname}</div>
          <div>{10} {usersWord(10)}</div>
        </div>
        <div className={styles.secondLiBlock}>
          <label>
            <input type='checkbox' onClick={e => e.stopPropagation()}/>
            Разрешен запуск приложения
          </label>
          <IoMdCreate onClick={openModalHandler(data)} size={22}/>
          <div onClick={thrashClickHandler(id)}>
            {thrash()}
          </div>
        </div>
      </Li>
    )
  }

  return (
    <div className={styles.wrapper}>
        <Modal
          open={!!selectedItem}
          onClose={closeModal}
          data={selectedItem}
          addMode={!selectedItem?.id}
        />
        <h1>Роли</h1>
        {loading
          ? <Loader/>
          : roles && <ul className={styles.rolesList}>{roles.map(renderLi)}</ul>
        }
        <Button
          style={{padding: '.5rem 1.5rem'}}
          onClick={openModalHandler()}
        >
          <IoIosAdd size={30}/>
          Добавить роль
        </Button>
    </div>
  )
}
