import React, {useState} from 'react'
import styles from './styles.module.css'
import buttonPinkPlay from '../img/buttonPinkPlay.png'
import buttonBluePlay from '../img/buttonBluePlay.png'
import buttonGreenPlay from '../img/buttonGreenPlay.png'
import buttonOrangePlay from '../img/buttonOrangePlay.png'
import buttonPurplePlay from '../img/buttonPurplePlay.png'
import buttonYellowPlay from '../img/buttonYellowPlay.png'
import buttonDarkBluePlay from '../img/buttonDarkBluePlay.png'
import buttonBack from '../img/buttonBack.png'

const Video = ({setPage, data, pathName}) => {

    const [isShowVideo, setIsShowVideo] = useState(false);

      const bgData = [
        buttonPinkPlay, buttonBluePlay, buttonGreenPlay, buttonOrangePlay, buttonPurplePlay, buttonYellowPlay, buttonDarkBluePlay,
        buttonPinkPlay, buttonBluePlay, buttonGreenPlay, buttonOrangePlay, buttonPurplePlay, buttonYellowPlay, buttonDarkBluePlay,
        buttonDarkBluePlay]

    const handleLink = (link) => {
        setIsShowVideo(false);
        setIsShowVideo(link);
    }

    const handleClose = (e) => {
        if (e.target.nodeName == 'VIDEO') return;
        setIsShowVideo(false)
        e.stopPropagation();
    }

    return (
    <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
        {
            data?.map((el, i) => 
                <div className={styles.wrapperButton} onClick={() => handleLink(el.link)}>
                    <img src={bgData[i]} className={styles.img}/>
                    <span className={styles.name}>{el.name}</span>
                </div>
            )
        }
        </div>

        {isShowVideo ? 
        
        <div className={styles.wrapperVideo} onClick={(e) => handleClose(e)}> 
            <video className={styles.video} autoPlay={true}>
                <source src={`https://asmc.yunarmy.ru/video/${pathName}/${isShowVideo}.mp4`}/>
            </video>
        </div>
        :<></>
        }
        <img src={buttonBack} className={styles.buttonBack} onClick={() => setPage('default')}/>
    </div>
  )
}

export default Video