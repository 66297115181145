import { combineReducers } from 'redux'
import {access_control} from './accessControl'
import {permission_groups} from './permissionGroups'


const roles = combineReducers({
    access_control,
    permission_groups
})

export default roles