import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPopulationReport } from '../../actions/reports'
import { selects } from '../../utils/const'
import Loader from '../../components/Loader/Loader'
import styles from './styles.module.css'
import { AiOutlineFilePdf } from "react-icons/ai";
import addFont from "../../fonts/fonts";
import jsPDF from 'jspdf'
import logo from "../../assets/images/label.png";
import moment from 'moment'
import { getFederalDistricts } from '../../actions/users'
import CustomSelect from '../../components/CustomSelect/CustomSelect'



export default function PopulationReport () {
  
  const dispatch = useDispatch()
  const {population_report, loading} = useSelector(state => state.reports)
  const {displayname: userName} = useSelector(state => state.auth)
  const {federalDistricts} = useSelector(state => state.users)
  
  const [district, setDistrict] = useState('')

  useEffect(() => {
    dispatch(getPopulationReport(district))
    !federalDistricts && dispatch(getFederalDistricts())
  }, [district])
  
  const statuses = selects.status.slice(0, selects.status.length-1)
  const {population: data, ...total} = population_report || {}

  const renderHeaderCell = ({name}) => <th>{name}</th>
  
  const renderRowCell = (regionData) => ({value}) => <td>{regionData[value || 'all']}</td>

  const renderRow = ({name, ...regionData}) => {
    return (
      <tr>
        <th>{name || 'Итого:'}</th>
        {statuses.map(renderRowCell(regionData))}
      </tr>
    )
  }


  const toPdf = () => {
    createDocData().then((doc) => doc.save(`Численность по регионам.pdf`));
  };

  const addFooters = (doc, img) => {
    const pageCount = doc.internal.getNumberOfPages();
    for (var i = 1; i <= pageCount; i++) {
      doc.setFontSize(6);
      doc.setPage(i);
      doc.addImage(img, "png", 5, 5, 25, 25);
      doc.text(`Отчет составлен пользователем: ${userName} ${moment().format("DD.MM.YYYY HH:mm")}`, 150, 205, "center")
      doc.setFontSize(10).text(`ВСЕРОССИЙСКОЕ ДЕТСКО-ЮНОШЕСКОЕ\nВОЕННО-ПАТРИОТИЧЕСКОГО ДВИЖЕНИЯ "ЮНАРМИЯ"`, 150, 10, "center");
    }
  };

  const createDocData = async () => {
    
    let lastY = 30
    const orientation = 'l'
    
    const getY = (indent, newValue) => {
      let newY = newValue ? newValue + indent : lastY + indent
      if (newY > doc.internal.pageSize.height) {
        doc.addPage('a4', orientation)
        newY = 10
      }
      lastY = newY
      return newY
    }

    addFont(jsPDF.API);

    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: orientation,
    });

    const img = new Image();
    img.src = logo;

    doc.setFont("Montserrat");

    doc.text("ЧИСЛЕННОСТЬ ЮНАРМЕЙЦЕВ ПО РЕГИОНАМ", 150, lastY, "center")
    districtName && doc.setFontSize(12).text(districtName, 150, getY(7), "center")
    doc.setFontSize(10).text(`по состоянию на ${moment().format("DD.MM.YYYY")} года`, 150, getY(7), "center")
    
    doc.autoTable({
      html: `#populationReportTable`,
      margin: {
        top: 30
      },
      styles: { font: 'Montserrat', fontSize: 8 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
      },
      footStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
      },
      showFoot: "lastPage",
      startY: getY(5)
    })

    addFooters(doc, img);

    doc.addPage("a4", orientation);
    doc.deletePage(doc.getCurrentPageInfo().pageNumber);
    
    return doc;
  };

  const Table = () =>
    <>
      <AiOutlineFilePdf size={35} onClick={toPdf}/>
      <table id='populationReportTable' className={styles.reportTable}>
        <thead>
          <tr>
            <th>Регион</th>
            {statuses.map(renderHeaderCell)}
          </tr>
        </thead>
        <tbody>
          {data.map(renderRow)}
          {renderRow(total)}
        </tbody>
      </table> 
    </>
  
  const districtOptions = federalDistricts?.map(({name, id}) => ({name, value: id}))
  const allDistrictOptions = districtOptions ? [{name: 'Все', value: ''}, ...districtOptions] : []

  const districtName = federalDistricts?.find(({id}) => id == district)?.name

  return (
    <div className={styles.wrapper}>
      <CustomSelect
        options={allDistrictOptions}
        value={district}
        title='Федеральный округ'
        onChange={e => setDistrict(e.target.value)}
        selwidth='420px'
        withoutFirst
      />
      <h3>Численность по регионам</h3>
      <h3>{districtName || ''}</h3>
      {loading ? <Loader top='50%' left='48%'/> : data && <Table/>}
    </div>
  )
}
