/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFederalDistricts } from "../../actions/users";
import FederalDistrictsModal from "./FederalDistrictsModal";
import styles from "./FederalDistricts.module.css";
import Confirmation from "../../assets/common/Confirmation/Confirmation";


const FederalDistricts = () => {

  const dispatch = useDispatch()
  const { federalDistricts: data } = useSelector(state => state.users)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    !data && dispatch(getFederalDistricts())
  }, []);

  const openModal = (type, item) => () => {
    setSelectedItem(item);
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Федеральные округа</div>
        <div className={styles.newsList}>
          <div className={styles.newsListHeader}>
          </div>
          <div className={styles.tableHeader}>
            <div className={styles.displayname}>Федеральные округа:</div>
            <div className={styles.superuser}></div>
            <div className={styles.actions} />
          </div>
          <div className={styles.table}>
            {data?.map((a) => (
                <div key={a.name} className={styles.tableRow}>
                  <div
                    className={styles.displayname}
                    onClick={openModal("EDIT", a)}
                  >
                    <span>
                      {a.name}
                    </span>
                  </div>
                  <div className={styles.superuser}></div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <FederalDistrictsModal
        open={isModalOpen}
        type={modalType}
        onClose={closeModal}
        selectedItem={selectedItem}
      />
    </>
  );
};


export default FederalDistricts
