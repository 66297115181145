import * as types from "../actions/types";

const initialState = {
  images: null,
  badges: null,
  loading: false
};

export default function badges (state = initialState, action) {
  switch (action.type) {
    case types.SET_BADGES_IMAGES:
      return {
        ...state,
        images: action.payload,
      };
    case types.SET_BADGES_DATA:
      return {
        ...state,
        badges: action.payload,
      };
    case types.SET_BADGES_LOADING:
        return {
            ...state,
            loading: action.payload,
        };
      default:
        return state;
    }
}