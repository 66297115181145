import React from 'react'
import styles from "./BarChart.module.css"


export default function BarChart({
    percent,
    width,
    height
}) {
  return (
    <div className={styles.chart} style={{width: width, height: height}}>
        <div className={styles.box} style={{height: `${percent > 0 ? percent : 0}%`}}>{percent>15 && `${percent}%`}</div>
    </div>
  )
}
