/* eslint-disable */
import React, {  useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { getNews, editNews, getAuthors, setNumberPage, setCurrentEvent, getDepartments, deleteNews } from "../../actions/news";
import { setItemsOnPage } from "../../actions/auth";
import DropdownField from "../../components/shared/DropdownField";
import RenderRow from "./RenderRow";
import { PATHS } from "../../routing/Routes";
import { reduxForm } from "redux-form";
import TextField from "../../components/shared/TextField";
import { getRegions } from "../../actions/auth";
import Loader from "../../components/Loader/Loader";
import { itemsCount } from "../../utils/const";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";


const initialValue = { value: null, label: "Все" };

const options = [
  initialValue,
  { value: 1, label: "Не опубликовано" },
  { value: 2, label: "Опубликовано" },
  { value: "starred", label: "Избранное" },
  { value: 3, label: "В архиве" },
  { value: 4, label: "В корзине" },
];

const level = [
  initialValue,
  { value: 1, label: "Федеральный" },
  { value: 2, label: "Региональный" },
  { value: 3, label: "Местный" },
  { value: 4, label: "Окружной" },
];

const News = ({
  history,
  pathname,
  newsList,
  lastUpdate,
  numberPage,
  currentEvent,
  newsPages,
  authors,
  formValue,
  handleSubmit,
  setNumberPage,
  setItemsOnPage,
  getNews,
  editNews,
  getAuthors,
  deleteNews,
  getRegions,
  regions,
  getDepartments,
  deps,
  accessRights,
  loading,
  userInfo,
  itemsOnPage
}) => {
  
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [regId, setRegId] = useState(null);
  const [sort, setSort] = useState("");

  
  useEffect(() => {
    getRegions();
  }, [])  

  useEffect(() => {
    getNews(screen(), history, null, numberPage+1, null, null, null, null, null, sort);
    getAuthors();
  }, [pathname, itemsOnPage, sort]);

  const screen = () =>
    pathname === "/event" ? 2 : pathname === "/news" ? 1 : 3;

  const handleSortClick = () => {
    const values = ['', 'asc', 'desc']
    const newValueIndex = (values.indexOf(sort)+1)%3
    setSort(values[newValueIndex])
  }

  const goToScreen = () =>
    history.push(
      pathname === "/event"
        ? PATHS.AUTH.ADD_EVENT
        : pathname === "/news"
        ? PATHS.AUTH.ADD_NEWS
        : PATHS.AUTH.ADD_ADS
    );

    const handleDelete = (id) => {
      deleteNews(id, screen(), history, 4, numberPage+1, null, null, null, null, null, sort);
    }

  const renderRow = (values, index) => (
    <RenderRow
      key={index}
      values={values}
      index={index}
      editNews={editNew}
      deleteNews={handleDelete}
      showMenu={(id) => setSelectedNewsId(id)}
      closeMenu={() => setSelectedNewsId(null)}
      pathname={pathname}
      havePublishRight={!['nmo', 'nrsh'].includes(accessRights.user_role) && (accessRights.ac_update || values.userid===userInfo.id)}
      haveDeleteRight={accessRights.user_role!=='nmo' && (accessRights.ac_update || values.userid===userInfo.id)}
      selectedNewsId={selectedNewsId}
      userInfo={userInfo}
    />
  );

  const editNew = (id, field, value) => () => {
    const news = newsList.find((item) => item.id === id);
    const index = newsList.indexOf(news);
    const newsListCopy = [...newsList];

    if (index !== -1) {
      newsListCopy[index][field] =
        field === "starred"
          ? !newsListCopy[index][field]
          : (newsListCopy[index][field] = value);

      editNews(
        id,
        newsListCopy[index],
        null,
        formValue?.state && formValue.state.value,
        formValue?.level && formValue.level.value,
        numberPage+1,
        formValue?.authors && formValue.authors.value,
        formValue?.department_id && formValue.department_id.value, 
        formValue?.region_id.value,
      );
    }
  };

  const handlePageClick = (data) => {
    const { state, authors, level, department_id, region_id } = formValue;
    setNumberPage(data.selected);
    getNews(
      screen(),
      history,
      state && state.value,
      ++data.selected,
      authors && authors.value,
      level && level.value,
      department_id && department_id.value,
      region_id && region_id.value,
      null,
      sort
    );
  };

  const handleGetDep = (e) => {
    if(e.value === 'Все') {
      search( null, "department_id")
    } else {
      search( e.value, "department_id")
    }
    
  }

  const handleGetLvl = (e) => {
    setRegId(null);
    formValue.department_id = {value: null, name: null};
    formValue.level = {value: null, label: null};
    formValue.region_id = {value: null, label: null};
    search(e.value, "level")
    if (e.value == 2) {
      getDepartments();
    }
  }

  const handleGetReg = (e) => {
    formValue.department_id = {value: null, name: null};
    formValue.region_id = {value: null, label: null};
    setRegId(e.value);
    search(e.value, "region_id");
  }

  const blur = (values) => {
    if (!values.search) {
      getNews(screen(), history);
    }
  };  

  const getNormalize = (value) => {
    let normal = [{ id: null, name: "Все" }, ...value];
    return normal;
  }

  const search = (values, type) => {
    const search = values && values.search && formValue.search;
    let state, authors, level, department_id, region_id;

    switch (type) {
      case "state":
        state = values;
        authors = formValue.authors.value;
        level = formValue.level.value;
        department_id =  formValue.department_id.value;
        region_id = formValue.region_id.value;
        break;
      case "authors":
        state = formValue.state.value;
        authors = values;
        level = formValue.level.value;
        department_id =  formValue.department_id.value;
        region_id = formValue.region_id.value;
        break;
      case "level":
        state = formValue.state.value;
        authors = formValue.authors.value;
        level = values;
        department_id =  formValue.department_id.value;
        region_id = formValue.region_id.value;
        break;
      case "department_id":
        state = formValue.state.value;
        authors = formValue.authors.value;
        level = formValue.level.value;
        department_id = values;
        region_id = formValue.region_id.value;
        break;
      case "region_id":
        state = formValue.state.value;
        authors = formValue.authors.value;
        level = formValue.level.value;
        department_id = formValue.department_id.value;
        region_id = values;
        break;
      default:
        state = formValue.state.value;
        authors = formValue.authors.value;
        level = formValue.level.value;
        department_id =  formValue.department_id.value;
        region_id = formValue.region_id.value;
    }

    getNews(screen(), history, state, 1, authors, level, department_id, region_id, search);
  };

  const optionsNormalize = (optionsList) => {
    if (optionsList) {
      const options = [initialValue];
      optionsList.map((item) =>
        options.push({ value: item.username, label: item.displayname })
      );
      return options;
    }
    return [];
  };

  const searchKeyPress = e => e.key === 'Enter' && handleSubmit(search)()

  return (
    <div className="container newsListContainer">
      <div className="newsListContainer__title">
        {pathname === "/event"
          ? "Мероприятия"
          : pathname === "/news"
          ? "Новости"
          : "Объявления"}
      </div>

      <div className="newsList">
        <div className="newsListHeader">
          {(accessRights.ac_create || accessRights.ac_create_by_region) && 
            <ButtonRed onClick={goToScreen}>Добавить</ButtonRed>
          }

          <div style={pathname === '/event' ? null : {width: '100%', display: 'flex', flexWrap: 'wrap'}}>
          <DropdownField
            className="newsListHeader__dropdown"
            placeholder="Выбор состояния"
            options={options}
            name="state"
            onChange={(e) => search(e.value, "state")}
          />

          <DropdownField
            className="newsListHeader__dropdown"
            placeholder="Выбор автора"
            options={optionsNormalize(authors)}
            name="authors"
            onChange={(e) => search(e.value, "authors")}
          />
          </div>

          <div>
            {pathname === '/event' ?
              <DropdownField
              className="newsListHeader__dropdown"
              placeholder="Уровень мероприятия"
              options={level}
              name="level"
              onChange={(e) => handleGetLvl(e)}
            />
            :
            ''
            }
          {
            formValue?.level.value === 3 ? 
            <DropdownField
            className="newsListHeader__dropdown"
            placeholder="Регион"
            options={regions ? [{ id: null, federalUnit: "Все", regionName:'' }, ...regions].map(it => ({label: `${it.federalUnit} ${it.regionName}`, value: it.id})) : []}
            name="region_id"
            onChange={(e) => handleGetReg(e)}
          />
          :
          ''
          }

          {
            formValue?.level.value === 2 ? 
            <DropdownField
            className="newsListHeader__dropdown"
            placeholder="Отделение"
            options={deps ? [{ id: null, name: "Все"}, ...deps].map(it => ({label: it.name, value: it.id})) : []}
            name="department_id"
            onChange={(e) => handleGetDep(e)}
          />
          :
          ''
          }

          </div>

          <div>
          {
            regId !== null && formValue?.level.value === 3 ?
            <DropdownField
            className="newsListHeader__dropdown"
            placeholder="Отделение"
            options={regions && regId && 
              getNormalize(regions.filter(el => el.id === regId)[0]?.domestic_departments)
              ?.map(it => ({label: `${it.name}`, value: it.id}))}
            name="department_id"
            onChange={(e) => search(e.value, "department_id")}
          />
          :
          ''
          }

          <div className="newsListHeader__input">
            <TextField
              onBlur={handleSubmit(blur)}
              name="search"
              onKeyDown={searchKeyPress}
            />
            <button onClick={handleSubmit(search)} />
          </div>
          </div>
        </div>

        <div className="newsListRow newsListRow__header">
          <div className="newsListRow__state">Состояние</div>
          <div className="newsListRow__title">Заголовок</div>
          <div className="newsListRow__author">Автор</div>
          <div className="newsListRow__date">Дата</div>
        </div>

        {loading ?
          <Loader top="40%" left="45%" /> :
          <div className="newsListTable">
          {lastUpdate && newsList && (currentEvent ? renderRow(newsList[currentEvent], 0) : newsList.map(renderRow))}

          <div className="newsListPagination">
            <ReactPaginate
              previousLabel="В начало"
              nextLabel="В конец"
              breakLabel={"..."}
              breakClassName="break-me"
              pageCount={newsPages || 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
              forcePage={numberPage}
            />
          </div>
        </div>}
        <div className="controls">
          <div
                onClick={handleSortClick}
                className={`sortBlock ${sort ? 'sortActive' : ''}`}
          >
              {sort && sort==='desc'
              ? <BsSortDown/>
              : <BsSortDownAlt/>}
          </div>
          <div className="itemsCount">
              <select value={itemsOnPage} onChange={(e) => setItemsOnPage(e.target.value)}>
                {itemsCount.map(item => <option key={item} value={item}>{item}</option>)}
              </select>
              <span>
                - строк на стр.
              </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewsForm = reduxForm({
  form: "filter",
})(News);

const mapStateToProps = (state, props) => {
  const {
    schedule: { newsList, newsPages, authors, lastUpdate, currentEvent, loading },
    auth: { user }
  } = state;
  const form = getFormValues("filter")(state);
  const category = (/news/i).test(location.pathname)
                      ? 'news'
                      :  (/ads/i).test(location.pathname)
                        ? 'ads'
                        : 'event'
  return {
    formValue: form,
    newsList,
    itemsOnPage: state.auth.itemsOnPage,
    numberPage: state.schedule.currentPage,
    newsPages,
    authors,
    currentEvent,
    lastUpdate,
    loading,
    regions: state.auth.regions,
    pathname: props.location.pathname,
    deps: state.schedule.deps,
    userInfo: user,
    initialValues: {
      state: initialValue,
      authors: initialValue,
      level: initialValue,
      department_id: initialValue,
      region_id: initialValue,
    },
    accessRights: state.auth.accessRights.find(i => i.name===category)||{}
  };
};

export default connect(mapStateToProps, { getNews, editNews, getAuthors, setNumberPage, setCurrentEvent, getRegions, getDepartments, setItemsOnPage, deleteNews })(
  NewsForm
);
