import * as types from '../actions/types';

const initialState = {
  data: {},
  sendRequest: false,
};

export default function layout(state = initialState, action) {
  switch (action.type) {
    case types.LAYOUT_FETCH_COMPLETED:
      return {
        ...state,
        data: action.data,
      };
    case types.LINKS_FETCH_COMPLETED:
      return {
        ...state,
        links: action.links,
      };
    case types.UPDATE_SEND_REQUEST:
      return {
        ...state,
        sendRequest: action.sendRequest,
      };
    default:
      return state;
  }
}
