import qs from "qs";
import moment from "moment";
import {
  AUTH_COMPLETED,
  AUTH_ERROR_MODAL_CLOSED,
  AUTH_FAILED,
  FETCHED_USER,
  IS_BASIC_LOGIN_ALLOWED,
  NETWORK_REQUEST_SENT,
  SIGNED_OUT,
  SSO_IS_NOT_IMPLEMENTED,
  SET_USER_INFO,
  SET_DASHBOARD_INFO,
  SET_USER_PHOTO_LOADING,
  SET_REGION_LIST_AUTH,
  SET_DASHBOARD_INFO_LOADING,
  ERROR,
  SET_ITEMS_ON_PAGE,
  SET_USER_REGIONS_AUTH,
  SET_USER_ACCESS_RIGHTS
} from "./types";
import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { createNotification } from "../components/Notifications/Notifications";

const TOKEN_UPDATING_INTERVAL = 190;

export const setItemsOnPage = (number) => ({type: SET_ITEMS_ON_PAGE, payload: number})


const getTimeToExpireToken = (getState) => {
  const { expires } = getState().auth;
  return moment(expires).diff(moment.utc()) / 1000 / 60;
};

export const checkAndUpdateToken = () => async (dispatch, getState) => {
  const timeToExpire = getTimeToExpireToken(getState);
  if (timeToExpire < TOKEN_UPDATING_INTERVAL) {
    try {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/token`,
        buildDefaultHeaders(getState)
      );
      if (response.ok) {
        const result = await response.json();
        if (result.error && result.error.code === 401) {
          dispatch({ type: SIGNED_OUT });
          window.location.href = "/";
        } else {
          dispatch({ type: AUTH_COMPLETED, user: result });
        }
      }
    } catch (ERR) {
        dispatch({ type: SIGNED_OUT });
        window.location.href = "/";
    }
  }
};

export const signOut = (history) => async (dispatch) => {
  dispatch({ type: SIGNED_OUT });
  history.push("/");
};

export const auth = (values, history) => async (dispatch, getState) => {
  
  dispatch({type: SET_USER_PHOTO_LOADING, payload: true})
  try {
    const { username, password } = values;
    const tok = `${username.trim()}:${password.trim()}`;
    const hash = window.btoa(unescape(encodeURIComponent(tok)));
    const Authorization = `Basic ${hash}`;

    const response = await fetch(`${BACKEND_URL_PREFIX}/token`, {
      headers: {
        Authorization,
        "Access-Control-Allow-Origin": "*",
      },
    });

    dispatch({ type: NETWORK_REQUEST_SENT, response });
    if (response.ok) {
      const result = await response.json();
      if (result.error && result.error.code === 401) {
        createNotification('error', 'Неверное имя пользователя или пароль')
      } else {
        dispatch({ type: AUTH_COMPLETED, user: result });
        await dispatch(getUserInfo())
        await dispatch(getUserRegions())
        await dispatch(getUserAccessRights())
      }
    } else if (response.status === 401) {
      dispatch({type: AUTH_FAILED, error: "Неверное имя пользователя или пароль"});
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  } catch (ERROR) {
      createNotification('error', 'Сбой авторизации')
      console.log(ERROR)
    } finally {
      // console.log()
      dispatch({type: SET_USER_PHOTO_LOADING, payload: false})
    }
  };

export const allowBasicLogin = () => async (dispatch) => {
  const response = await fetch(`${BACKEND_URL_PREFIX}/settings`);

  dispatch({ type: NETWORK_REQUEST_SENT, response });

  if (response.ok) {
    const result = await response.json();
    dispatch({
      type: IS_BASIC_LOGIN_ALLOWED,
      allowBasicLogin: result.auth.sso.allowbasiclogin,
    });
  } else {
    dispatch({ type: ERROR, payload: response });
  }
};

// TODO после активации sso поправить.
export const sso = (history) => async (dispatch, getState) => {
  const response = await fetch(
    `${BACKEND_URL_PREFIX}/token/sso`,
    buildDefaultHeaders(getState)
  );

  dispatch({ type: NETWORK_REQUEST_SENT, response });

  const parameters = qs.parse(history.location.search.substring(1));
  const { login, password } = parameters;
  const loginAndPasswordExist = login && password;

  if (response.ok) {
    const result = await response.json();
    dispatch({ type: AUTH_COMPLETED, user: result });
    // eslint-disable-next-line
    history.push("/lk");
  } else if (response.status === 404) {
    dispatch({ type: SSO_IS_NOT_IMPLEMENTED });
    if (getState().auth.token) {
      if (loginAndPasswordExist) {
        dispatch(signOut(history));
        dispatch(auth({ username: login, password }, history));
      }
      if (getTimeToExpireToken(getState) <= 0) {
        dispatch(signOut(history));
      } else {
        dispatch(checkAndUpdateToken());
        history.location.pathname === "/" && history.push("/lk");
      }
    } else {
      loginAndPasswordExist
        ? dispatch(auth({ username: login, password }, history))
        : history.push("/");
    }
  } else if (response.status === 401) {
    // dispatch(allowBasicLogin());
    history.push("/");
    dispatch({ type: ERROR, payload: response });
  } else {
    console.log("sso error");
    history.push("/");
    dispatch({ type: ERROR, payload: response });
  }
};

export const closeAuthError = () => ({ type: AUTH_ERROR_MODAL_CLOSED });

export const loadUser = () => async (dispatch, getState) => {
  const response = await fetch(`${BACKEND_URL_PREFIX}/token`, {
    method: "GET",
    ...buildDefaultHeaders(getState),
  });

  if (response.ok) {
    const user = await response.json();
    dispatch({
      type: FETCHED_USER,
      user,
    });
  } else {
    dispatch({ type: ERROR, payload: response });
  }
};

export const showErrorAuth = () => ({
  type: AUTH_FAILED,
  error: "СНАЧАЛА НЕОБХОДИМО АВТОРИЗОВАТЬСЯ!",
});

export const uploadUserImage = (id, image) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());

  const fd = new FormData();
  fd.append("file", image);

  if (getState().auth.token) {
    dispatch({ type: SET_USER_PHOTO_LOADING, payload: true });
    await dispatch(deleteUserImage(id));
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/image`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
      body: fd,
    });
    if (response.ok) {
      console.log("ok");
      await dispatch(getUserInfo());
    } else {
      dispatch({ type: ERROR, payload: response });
    }
    dispatch({ type: SET_USER_PHOTO_LOADING, payload: false });
  }
};

export const deleteUserImage = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/image`, {
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      console.log("ok");
      dispatch(getUserInfo());
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getUserInfo = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {
    const id = getState().auth.id;
    const userInfo = await fetch(`${BACKEND_URL_PREFIX}/user/${id}`, {
      headers: {
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
        "Access-Control-Allow-Origin": "*",
      },
    });
    if (userInfo.ok) {
      const info = await userInfo.json();
      dispatch({ type: SET_USER_INFO, payload: info[0] });
    } else {
      dispatch({ type: ERROR, payload: userInfo });
    }
  }
};

export const getUserRegions = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {
    const id = getState().auth.id;
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${id}/regions`, {
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_USER_REGIONS_AUTH, payload: data });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};

export const getUserAccessRights = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/access_control`, {
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_USER_ACCESS_RIGHTS, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};


export const getDashboardById = (regionId) => async (dispatch, getState) => {
  dispatch({ type: SET_DASHBOARD_INFO_LOADING, payload: true });
  await dispatch(checkAndUpdateToken());

  if (getState().auth.token) {
    
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/region/${regionId}/dashboard`,
      {
        headers: {
          Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      const payload = data.data
      dispatch({ type: SET_DASHBOARD_INFO, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
  dispatch({ type: SET_DASHBOARD_INFO_LOADING, payload: false });
};

export const getRegions = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/regions`, {
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_REGION_LIST_AUTH, payload });
    } else {
      dispatch({ type: ERROR, payload: response });
    }
  }
};