import * as types from '../actions/types';

const initialState = {
    count: null,
    pageCount: null,
    data: null,
    loading : false
  };

export default function virtualAwards (state = initialState, action) {
  switch (action.type) {
    case types.SET_VIRTUAL_AWARDS_COUNT:
      const { count, pageCount } = action.payload;
      return {
        ...state,
        count,
        pageCount
      };
      case types.SET_VIRTUAL_AWARDS_DATA:
      return {
        ...state,
        data: action.payload
      };
      case types.SET_VIRTUAL_AWARDS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}