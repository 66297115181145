import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import styles from "./RegionGroups.module.css"
import CustomInput from "../../components/CustomInput/CustomInput"
import { useFormik } from "formik";



const RegionGroupsModal = ({
  type,
  selectedItem,
  addGroup,
  editGroup,
  ...props
}) => {

  const addGroupItem = (values) => {
    addGroup(values)
    props.onClose()
  }

  const editGroupItem = (values) => {
    editGroup(values)
    props.onClose()
  }

  const formik = useFormik({
    initialValues: type==="ADD" 
        ? {
            group_name: "",
            order_by: ""
          }
        : selectedItem,
    onSubmit: values => type==="ADD" ? addGroupItem(values) : editGroupItem(values),
  })

  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      <div className={styles.title}>{type === "ADD" ? "Добавление группы" : "Редактирование группы"}</div>
      <div className={styles.modalBody}>
        <div>
          <CustomInput
            title="Название группы"
            name="group_name"
            value={formik.values.group_name}
            onChange={formik.handleChange}
          />
          <CustomInput
            title="Порядок отображения"
            name="order_by"
            value={formik.values.order_by}
            onChange={formik.handleChange}
          />
        </div>
        <div className={styles.modalButtons}>
          <ButtonRed onClick={formik.handleSubmit}>{type === "ADD" ? "Добавить" : "Сохранить"}</ButtonRed>
          <ButtonRed onClick={props.onClose}>Закрыть</ButtonRed>
        </div>
      </div>
    </Modal>
  );
};


export default RegionGroupsModal