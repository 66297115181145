import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getYearsArr, ratingTableHeader } from "../../utils/yunarmyStat";
import {
  getRegionRating,
  setRegionRating,
  setRegionRatingLoading,
  setRatingValue,
  updatePercentage,
  getRatingValue,
  removeRatingValue
} from "../../actions/regionRating";
import styles from "./RegRating.module.css";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import { AiOutlineCheck } from "react-icons/ai"
import { createNotification } from "../../components/Notifications/Notifications";
import GeneralRatingModal from "./GeneralRatingModal";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import { declin } from "../../utils/declination";

function RegRatingGeneral({
  getRegionRating,
  setRegionRatingLoading,
  setRegionRating,
  setRatingValue,
  getRatingValue,
  ratingValue,
  data,
  closedDate,
  removeRatingValue,
  userRegions,
  confirmLoading,
  loading,
  cellLoading,
  updatePercentage,
  token,
  status
  }) {
  

  const [_data, setData] = useState()
  const [sortedBy, setSortedBy] = useState('rating')
  const [year, setYear] = useState(new Date().getFullYear())
  const [currentCell, setCurrentCell] = useState(null);
  const [indexes, setIndexes] = useState(null)
  const [confirmationMode, setConfirmationMode] = useState(null)
  const [haveEditRight, setHaveEditRight] = useState(false)


  useEffect(() => {
    const yearCondition = moment().year()==year
    const dateCondition = moment(closedDate).diff(moment(), 'hours') > 0
    setHaveEditRight((yearCondition && dateCondition) || status.length>2)
  }, [year, closedDate])
  

  useEffect(() => {
    getRegionRating(year, sortedBy)
    setRegionRatingLoading(true)
  }, [year, sortedBy])

  useEffect(() => {
    if (data) {
      const newData = JSON.parse(JSON.stringify(data))
      setData(newData)
    } else {
      setData([]);
    }
  }, [data])

  const handleChangeCell = (e) => {
    const obj = { ...currentCell };
    obj.value = Number(e.target.value);
    obj.changed = true;
    setCurrentCell(obj);
  };

  const handleClickCell = (it, item, i, ind) => {
    
    const management = status.includes('superuser') || status.includes('agsh')

    const yearCondition = moment().year()==year
    const dateCondition = moment(closedDate).diff(moment(), 'hours') > 0
    
    if (management || (yearCondition && dateCondition)) {
      if (userRegions.includes(it.regionId)) {
        if (status.includes(item.access)) {
          [15,25,29,30,35,36,42,43].includes(item.rating_parameter_id)
          ? changeBooleanCellValue({...item, region_department_id: it.id, departmentIndex: i, ratingParIndex: ind})
          : setCurrentCell({...item, region_department_id: it.id, departmentIndex: i, ratingParIndex: ind})
        } else {
          if (!item.confirmation_required) {
            let message
            if (item.access==="nobody") { message = "Вы не имеете права редактировать данный параметр!" }
            else if (!status.length) { message = "Данный параметр рейтинга может редактировать только АГШ/НРШ/SuperUser!" }
            else { message = "Доступ к данному параметру отсутствует" }
            createNotification("error", message, null, 2000);
          }
        }
      } else {
        createNotification('error', 'Доступ к данному региону отсутствует')
      }
    } else {
      createNotification("error", "Время редактирования рейтинга по данному году вышло");
    }
  }

  const changeBooleanCellValue = (data) => {
    const newValue = Number(!data.value)
    const postReqObj = {
      rating_id: data.rating_parameter_id,
      region_department_id: data.region_department_id,
      value: newValue,
      year: year
    }
    const newData = [..._data]
    newData[data.departmentIndex].values[data.ratingParIndex] = {...data, value: newValue}
    setRegionRating(postReqObj, year)
    setData(newData)
  }

  const setNewValueMonthly = () => {
    if (currentCell.changed) {
      const obj = {...currentCell}
      const postReqObj = {
        rating_id: currentCell.rating_parameter_id,
        region_department_id: currentCell.region_department_id,
        value: currentCell.value,
        year: year
      }
      delete obj.changed
      delete obj.score
      delete obj.region_department_id
      const newData = [..._data]
      newData[currentCell.departmentIndex].values[currentCell.ratingParIndex] = obj
      setIndexes({i: currentCell.departmentIndex, ind: currentCell.ratingParIndex})
      setRegionRating(postReqObj, year)
      setData(newData)
      setCurrentCell(null);
    }
  };

  const blurCell = (e) => {
    e.keyCode===13 && e.target.blur()
  }

  const openConfirmationModal = (item) => {
    item.confirmation_required && setConfirmationMode(item.value_id)
  }

  return (
    <div className={styles.wrapper}>
      {confirmationMode &&
        <GeneralRatingModal
          year={year}
          sortedBy={sortedBy}  
          confirmationMode={confirmationMode}
          setRatingValue={setRatingValue}
          removeRatingValue={removeRatingValue}
          getRatingValue={getRatingValue}
          status={status}
          onClose={() => setConfirmationMode(null)}
          setConfirmationMode={setConfirmationMode}
          ratingValue={ratingValue}
          loading={confirmLoading}
          haveEditRight={haveEditRight}
          token={token}
        />}
      <div className={styles.filtersBlock}>
        <div className={styles.selectBlock}>
          <div className={styles.year}>
            <span>Год:</span>
            <select onChange={(e) => setYear(e.target.value)} value={year}>
              {getYearsArr(2016).map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
        </div>
        <div className={styles.filter}>
          <div>Сортировать по:</div>
          <select onChange={(e) => setSortedBy(e.target.value)} value={sortedBy}>
            <option value='rating'>рейтингу</option>
            <option value='name'>алфавиту</option>
          </select>
        </div>
        <ButtonRed
          style={{backgroundColor: '#881111'}}
          onClick={() => updatePercentage(year, sortedBy)}
        >
          Пересчитать % участников движения
        </ButtonRed>
      </div>
        {loading ? <Loader top="50%" left="50%" /> :
        <div>
          {year && <h1>{`Региональный рейтинг за ${year}г.`}</h1>}
          <div className={styles.tableWrap}>
            {_data && _data.length > 0 && ( 
              <table>
                <thead>
                <tr>
                    <th rowSpan={2} className={styles.doubleCell}>
                      <div>
                        <span className={styles.cellBottomPart}>Регион</span>
                        <span className={styles.cellTopPart}>Параметр</span>
                        <div className={styles.cellLine}></div>
                      </div>
                    </th>
                    {ratingTableHeader(_data[0].values).firstRow.map(it =>
                      <th key={it.title} colSpan={it.col} rowSpan={it.row} >{it.title}</th>
                    )}
                    <th rowSpan={2}>Итого:</th>
                </tr>
                <tr>
                    {ratingTableHeader(_data[0].values).secondRow.map((it,i) =>
                      <th key={`${it.title}_${i}`} colSpan={it.col} rowSpan={it.row} >{it.title}</th>
                    )}
                </tr>
                </thead>
                <tbody>
                  {_data?.map((it,i) => <tr key={it.name}>
                      <td>{it.name}</td>
                      {
                        it.values.map((item,ind) =>
                        <td
                          title={item.value ? `Изменено ${item.updated_by_name||''} ${moment.utc(item.updated).format("LLL")||''}` : null}
                          key={`${it.name} ${item.rating_parameter_id}`}
                          onClick={() => handleClickCell(it, item, i, ind)}
                          onDoubleClick={() => openConfirmationModal(item)}
                          style={item.confirmation_required ? (item.is_confirmed ? {backgroundColor: "rgba(55, 250, 7, 0.501)"} : {backgroundColor: "rgba(255, 166, 0, 0.651)"}) : null}
                        >
                          {cellLoading && indexes && indexes.i===i && indexes.ind===ind ?
                            <Loader size={20} top="20%" left="40%"/>
                              : ([15,25,29,30,35,36,42,43].includes(item.rating_parameter_id) ?
                                (item.value ?
                                  <div style={{display: "flex", flexDirection: "column"}}>
                                    <i><AiOutlineCheck size={16}/></i>
                                    {!!item.score && `(${item.score} ${declin(item.score, 'балл')})`}
                                  </div> : null
                                )
                                : (item && it.id === currentCell?.region_department_id && item.rating_parameter_id === currentCell?.rating_parameter_id ?
                                  (<input
                                    type="number"
                                    min="0"
                                    value={currentCell.value ? currentCell.value : null}
                                    onKeyDown={blurCell}
                                    onChange={handleChangeCell}
                                    onKeyPress={(event) => !/[.0-9]/.test(event.key) && event.preventDefault()}
                                    onBlur={setNewValueMonthly}
                                    autoFocus={true}
                                />)
                                : ((item && item.value) ?
                                  <div>
                                    {item.value}
                                    <br/>
                                    {!!item.score && `(${item.score} ${declin(item.score, 'балл')})`}
                                  </div>
                                  :
                                    null)))}
                        </td>)
                      }
                      <td>{it.total_rating}</td>
                    </tr>)
                  }
                </tbody>
              </table>
            )}
          </div>
        </div>}
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.region_rating.data,
  closedDate: state.region_rating.closed_date,
  cellLoading: state.region_rating.cellLoading,
  loading: state.region_rating.loading,
  confirmLoading: state.region_rating.confirm_loading,
  userRegions: state.auth.userRegions,
  ratingValue: state.region_rating.rating_value,
  token: state.auth.token,
  status: state.auth.superuser ? ["superuser", "agsh", "nrsh"]
            : state.auth.user.agsh ? ["agsh", "nrsh"]
            : state.auth.user.nrsh ? ["nrsh"]
            : []
});

export default connect(mapStateToProps, {
  getRegionRating,
  setRegionRating,
  setRegionRatingLoading,
  setRatingValue,
  updatePercentage,
  removeRatingValue,
  getRatingValue
})(
  RegRatingGeneral
);
