import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import { SET_CHECK_STATUS, SET_CHECK_STATUS_LOADING, SET_ACTIVITY_RATING_DATA_DOWNLOAD, SET_ACTIVITY_RATING_DATA_DOWNLOAD_LOADING,
  SET_ACTIVITY_RATING_DATA_NEW, SET_REPORT_LOADING_NEW, ADD_ACTIVITY_RATING_DATA, SET_ACTIVITY_RATING_DATA_ALL, SET_REPORT_LOADING_ALL, ERROR, SET_ACTIVITY_RATING_DATA, SET_ANALYTICAL_REPORT_DATA, SET_ANALYTICAL_REPORT_DETAILS, SET_DEPARTMENT_CONTACTS, SET_REPORT_DETAILS_LOADING, SET_REPORT_LOADING, SET_POPULATION_REPORT_DATA } from "./types.js";
import { createNotification } from "../components/Notifications/Notifications";
import moment from "moment";

export const getActivityRatingAllClear = (payload = []) => ({
  type: SET_ACTIVITY_RATING_DATA_ALL,
  payload,
});

export const getActivityRatingLoadAllClear = (payload = null) => ({
  type: SET_REPORT_LOADING_ALL,
  payload,
});

export const getAnalyticalReport =
  (depId, statuses = '') =>
  async (dispatch, getState) => {
    dispatch({type: SET_REPORT_LOADING, payload: true})
    dispatch({type: SET_ANALYTICAL_REPORT_DETAILS})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(
        `${BACKEND_URL_PREFIX}/regional_department/${depId}/analytical_report${statuses ? `?statuses=${statuses}` : ''}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json()
        dispatch({ type: SET_ANALYTICAL_REPORT_DATA, payload: data.report });
        dispatch({type: SET_REPORT_LOADING, payload: false})
        const finalInd = data.report.length - 2
        const depIds = data.report.map(item => item.department_id).slice(0, finalInd)
        dispatch(getAnalyticalReportDetails([...depIds, depId], statuses))
        return true
      } else if (response.status===400) {
        const errorData = await response.json()
        const errorText = errorData.error || "Выбранный регион не доступен данному пользователю" 
        createNotification("error", errorText);
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    dispatch({type: SET_REPORT_LOADING, payload: false})
    return false
};

export const getAnalyticalReportDetails = (depIds, statuses) => async (dispatch, getState) => {
    dispatch({type: SET_REPORT_DETAILS_LOADING, payload: true})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      for (const id of depIds) {
        const response = await fetch(
          `${BACKEND_URL_PREFIX}/department/${id}/detachments${statuses ? `?statuses=${statuses}` : ''}`,
          {
            method: "GET",
            ...buildDefaultHeaders(getState),
          }
          );
          if (response.ok) {
            const data = await response.json()
            const payload = data.detachments || []
            dispatch({type: SET_ANALYTICAL_REPORT_DETAILS, payload})
          } else {
            dispatch({type: SET_ANALYTICAL_REPORT_DETAILS, payload: []})
            dispatch({ type: ERROR, payload: response });
          }  
        }
      }
    dispatch({type: SET_REPORT_DETAILS_LOADING, payload: false})
};




export const getActivityRating =
  (params, reset) =>
  async (dispatch, getState) => {
    reset && dispatch({type: SET_REPORT_LOADING, payload: true})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const getParams = {}
        
        const offset = reset ? 0 : getState().reports.activity_rating.length
        const limit = 100
        
        getParams.offset = offset
        getParams.limit = limit
        params.regionId !== 'all' && (getParams.regionId = params.regionId) 
        params.regionId !== 'all' && params.domestic_departmentId !== 'all' && (getParams.domestic_department = params.domestic_departmentId)
        params.domestic_departmentId !== 'all' && params.detachmentId !== 'all' && (getParams.detachmentId = params.detachmentId)
        params.date_from && (getParams.date_from = moment(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD'))
        params.date_to && (getParams.date_to = moment(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD'))
        params.order_by && (getParams.order_by = params.order_by)
        params.desc && (getParams.desc = params.desc)
        
        const urlParams = new URLSearchParams(getParams).toString();
        
        const response = await fetch(
        `${BACKEND_URL_PREFIX}/unaprofile/activity_rating_v2?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json()
        const actionType = reset ? SET_ACTIVITY_RATING_DATA : ADD_ACTIVITY_RATING_DATA 
        dispatch({ type: actionType, payload: data.data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    reset && dispatch({type: SET_REPORT_LOADING, payload: false})
};

export const getActivityRatingAll =
  (params, reset) =>
  async (dispatch, getState) => {
    reset && dispatch({type: SET_REPORT_LOADING_ALL, payload: true})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const getParams = {}
        params.regionId == 'all' && (getParams.sum_points = 1) 
        params.regionId !== 'all' && (getParams.regionId = params.regionId) 
        params.regionId !== 'all' && params.domestic_departmentId !== 'all' && (getParams.domestic_department = params.domestic_departmentId)
        params.domestic_departmentId !== 'all' && params.detachmentId !== 'all' && (getParams.detachmentId = params.detachmentId)
        params.date_from && (getParams.date_from = moment(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD'))
        params.date_to && (getParams.date_to = moment(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD'))
        params.order_by && (getParams.order_by = params.order_by)
        params.desc && (getParams.desc = params.desc)
        
        const urlParams = new URLSearchParams(getParams).toString();
        
        const response = await fetch(
        `${BACKEND_URL_PREFIX}/unaprofile/activity_rating_v2?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json()
        dispatch({ type: SET_ACTIVITY_RATING_DATA_ALL, payload: data.data });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    reset && dispatch({type: SET_REPORT_LOADING_ALL, payload: false})
};


export const getDepartmentContacts = (_depId) => async (dispatch, getState) => {
  dispatch({type: SET_REPORT_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const depId = _depId || 0
    const response = await fetch(
      `${BACKEND_URL_PREFIX}/department_chief_contacts/parent/${depId}`,
      {
        method: "GET",
        ...buildDefaultHeaders(getState),
      }
      );
      if (response.ok) {
        const data = await response.json()
        const payload = {key: depId, data: data.departments}
        dispatch({type: SET_DEPARTMENT_CONTACTS, payload})
      } else {
        dispatch({ type: ERROR, payload: response });
      } 
  }
  dispatch({type: SET_REPORT_LOADING, payload: false})
}

export const getPopulationReport = (district_id) => async (dispatch, getState) => {
  dispatch({type: SET_REPORT_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const params = district_id ? { district_id } : {}
    const urlParams = new URLSearchParams(params).toString();

    const response = await fetch(
      `${BACKEND_URL_PREFIX}/region/population?${urlParams}`,
      {
        method: "GET",
        ...buildDefaultHeaders(getState),
      }
      );
      if (response.ok) {
        const payload = await response.json()
        dispatch({type: SET_POPULATION_REPORT_DATA, payload})
      } else {
        dispatch({ type: ERROR, payload: response });
      } 
  }
  dispatch({type: SET_REPORT_LOADING, payload: false})
}

export const createActivityRating =
  (params, reset) =>
  async (dispatch, getState) => {
    reset && dispatch({type: SET_REPORT_LOADING_NEW, payload: true})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const getParams = {}
        // params.regionId == 'all' && (getParams.sum_points = 1) 
        params.regionId !== 'all' && (getParams.regionId = params.regionId) 
        params.regionId !== 'all' && params.domestic_departmentId !== 'all' && (getParams.domestic_department = params.domestic_departmentId)
        params.domestic_departmentId !== 'all' && params.detachmentId !== 'all' && (getParams.detachmentId = params.detachmentId)
        params.date_from && (getParams.date_from = moment(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD'))
        params.date_to && (getParams.date_to = moment(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD'))
        params.order_by && (getParams.order_by = params.order_by)
        params.desc && (getParams.desc = params.desc)
        
        const urlParams = new URLSearchParams(getParams).toString();
        
        const response = await fetch(
        `${BACKEND_URL_PREFIX}/unaprofile/activity_rating/excel_request?${urlParams}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json()
        dispatch({ type: SET_ACTIVITY_RATING_DATA_NEW, payload: data.request_id });
      } else {
        dispatch({ type: ERROR, payload: response });
      }
    }
    reset && dispatch({type: SET_REPORT_LOADING_NEW, payload: false})
};

export const getCheckStatus = (id) => async(dispatch, getState) => {
  dispatch({ type: SET_CHECK_STATUS_LOADING, payload: true });
  const response = await fetch(`${BACKEND_URL_PREFIX}/unaprofile/activity_rating/excel_status/${id}`,
    {
      method: "GET",
      ...buildDefaultHeaders(getState),
    }
  );
  const data = await response.json(); 
  dispatch({type: SET_CHECK_STATUS, payload: data});
  dispatch({ type: SET_CHECK_STATUS_LOADING, payload: false });
};

export const getDownloadExcel = (id) => async(dispatch, getState) => {
  dispatch({ type: SET_ACTIVITY_RATING_DATA_DOWNLOAD_LOADING, payload: true });
  const response = await fetch(`${BACKEND_URL_PREFIX}/unaprofile/activity_rating/excel_file/${id}`,
    {
      method: "GET",
      ...buildDefaultHeaders(getState),
    }
  );
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);


  dispatch({type: SET_ACTIVITY_RATING_DATA_DOWNLOAD, payload: url});
  dispatch({ type: SET_ACTIVITY_RATING_DATA_DOWNLOAD_LOADING, payload: false });
};