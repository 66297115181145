import React from "react";
import { useState, useEffect } from "react";
import {Text, Transformer} from 'react-konva';




export default function TextBlock({
  data,
  shapeProps, 
  onSelect,
  isSelected,
  onChange
}) {
  
  
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  
  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);


  return (
    <>
      <Text
        text={data.data}
        ref={shapeRef}
        onTap={onSelect}
        onClick={onSelect}
        fontStyle={700}
        {...shapeProps}
        draggable
        wrap='word'
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransform={(e) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX()
          node.scaleX(1)
          onChange({
            ...shapeProps,
            width: Math.max(30, node.width()*scaleX)
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={['middle-left', 'middle-right']}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 30) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
}
