import { BACKEND_URL_PREFIX } from "../utils/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken } from "./auth";
import { SET_NUMBER_REPORT, SET_NUMBER_REPORT_LOADING} from "./types.js";
import { createNotification } from "../components/Notifications/Notifications";

export const getNumberRatingAllClear = (payload = []) => ({
  type: SET_NUMBER_REPORT,
  payload,
});

export const getNumberReport =
  (params) =>
  async (dispatch, getState) => {
    dispatch({type: SET_NUMBER_REPORT_LOADING, payload: true})
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

      const response = await fetch(
        `${BACKEND_URL_PREFIX}/yunarmy_reports/quantity?date_from=${params.date_from}&date_to=${params.date_to}&region=${params?.regionId?.map(el => `${el.value}`)}`,
        {
          method: "GET",
          ...buildDefaultHeaders(getState),
        }
      );
      if (response.ok) {
        const data = await response.json()
        dispatch({ type: SET_NUMBER_REPORT, payload: data });
      } else if (response.status===400) {
        const errorData = await response.json()
        const errorText = errorData.error || "Выбранный регион не доступен данному пользователю" 
        createNotification("error", errorText);
      } else {
      }
    }
    dispatch({type: SET_NUMBER_REPORT_LOADING, payload: false})
    return false
};
