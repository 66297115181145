import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function PrivateRoute ({ children, ...rest }) {
 
    const { token: isAuth } = useSelector(state => state.auth)
    const {pathname} = useLocation()


    return (
      <Route {...rest} render={() => isAuth ? children : <Redirect to={{pathname: '/', state: {from: pathname}}} />} />
    );
  }