import React, {useState} from 'react'
import styles from './styles.module.css'
import logo from '../img/logo.png'
import header from '../img/header.png'
import researcherI from '../img/researcher.png'
import emblemResearcher from '../img/emblemResearcher.png'
import { Link } from 'react-router-dom'
import Video from '../Video/Video'
import TestWrapper from '../Test/TestWrapper'
import Article from '../Article/Article'
import buttonBack from '../img/buttonBack.png'

const Researcher = () => {

  const testTitle = 'Юнармеец-исследователь — ведущий осмысленное самообразование, совершающий полезные открытия, информационно подкованный, обладающий цифровыми компетенциями интернет-пользователь, активно участвующий в предметных олимпиадах, конкурсах, постоянно расширяющий кругозор.'

  const articleData = [
    {
      title: '«ЮНАВИА»',
      path: 'unavia',
      text: [
        'Профильное направление «ЮНАВИА» — направление для юнармейцев, увлеченных авиацией, космонавтикой и беспилотниками.',
        'Познакомиться с оборудованием для строительства беспилотных летательных аппаратов, полетать в виртуальном небе, примерить десантные парашюты и даже побывать в настоящем самолёте Ан-2. Юнармейцы могут всё это испытать на себе! А еще посетить лекции по современной авиации, мастер-классы на авиа-тренажерах, экскурсии по профильным музеям и вводные занятия по воздушно-десантной подготовке.',
        'Для будущих покорителей неба в рамках направления «ЮНАВИА» проходят окружные слеты юных авиаторов «ЮНАВИА». В 2023 г. окружные слеты пройходят в Москве и Московской области, Казани и Новосибирске.',
        'Для того, чтобы стать участником окружного слета необходимо:',
        'Быть действующим участником Движения «ЮНАРМИЯ» в возрасте от 14 до 17 лет, занимающимся по направлению ЮНАВИА и/или являющимся членом авиационных, кадетских классов/кружков',
        'Собрать команду из 10 человек;',
        'Принять участие в отборочных тестированиях и решении ситуационных задач в приложении «Юнармеец»;',
        'Набрать больше всего баллов.'  
      ]
    },
    {
      title: 'ЮНФЛОТ»',
      path: 'unflot',
      text: [
        'Профильное направление «ЮНФЛОТ» — направление, способствующее освоению тем, связанных с морским и речным судоходством.',
        'Юнги изучают историю Отечества. Узнают о героях военно-морского и гражданского флота, знакомятся с работой водного транспорта, бортового оборудования, портов. Осваивают судостроение и ходят в летние яхтенные походы «ЮНФЛОТ».',
        'В 2023 году Яхтенные походы проходят в городах Шлиссельбург, Новороссийск, Астрахань, Мурманск и Владивосток.',
        '140 участников Движения со всей России приняли участие в образовательном модуле «ЮНФЛОТ» в рамках программы «Всероссийская парусная регата «Восточный ветер» на базе Всероссийского детского центра «Океан», который проходил в июле текущего года.',
        'Для участия в яхтенном походе необходимо:',
        'Быть действующим участником Движения «ЮНАРМИЯ» в возрасте от 14 до 18 лет, занимающимся по направлению ЮНАВИА и/или являющимся членом авиационных, кадетских классов/кружков',
        'Собрать команду из 10 человек;',
        'Записать видеовизитку о своей команде;',
        'Принять участие в отборочном тестировании в приложении «Юнармеец»;',
        'Набрать больше всего баллов за отборочный этап.'  
      ]
    },
    {
      title: '«ЮНТЕХ»',
      path: 'unteh',
      text: [
        'Профильное направление «ЮНТЕХ» — направление для юнармейцев, увлекающихся научно-техническим творчеством, разработкой и внедрением новых технологий, инновационных продуктов и услуг.',
        'В рамках реализации данного направления юнармейцы осваивают профессиональные и надпрофессиональные навыки в конструировании, программировании, дизайне, энергетике, управлении БПЛА и железнодорожным транспортом.',
        'Юнармейцы-новаторы принимают участие во Всероссийском Форуме инновационно-технического творчества «ЮНТЕХ».',
        'Не знаешь, как туда попасть? Все просто.',
        'Подай заявку на официальном сайте Движения «ЮНАРМИЯ»;',
        'Выполни конкурсное техническое задание;',
        'Жди результаты.'  
      ]
    },
    {
      title: 'Инновационно-техническая смена «ЮНТЕХ»',
      path: 'smena',
      text: [
        'Инновационно-техническая смена «ЮНТЕХ» — смена инновационного и научно-технического творчества, проходящая в ВДЦ «Смена». «ЮНТЕХ» ориентирует ребят на комплексное развитие, позволяя укрепить физическую подготовку, сформировать инженерное мышление и навыки проектирования.',
        'В течение двух недель ребята отрабатывают навыки строевой и тактической подготовки. Юнармейцы знакомятся с историей официальных символов Российской Федерации, Вооруженных сил России, с церемониалами использования символов и знамен.',
        'Конечно, нельзя не сказать про инженерию космических систем и мобильную робототехнику! Проектирование, программирование на языке Python, сборку и проведение наземных испытаний микроспутника, а также создание инженерных деталей и 3D-моделей.',
        'Категория участников программы:',
        'Подростки 14-17 лет – активные участники Движения «ЮНАРМИЯ», имеющие достижения в области технического и естественнонаучного творчества, обладающие знаниями и умениями в области программирования, инжиниринга, материаловедения, робототехники, физики, алгебры, геометрии, химии.'  
      ]
    }
  ]

  const testData = [
    {title: 'Через тернии к звездам',
    questionList: [
      {
        question: 'Что именно произошло 12 апреля 1961 года?',
        type: 'pick',
        answers: [{
          answer: 'родился Юрий Гагарин',
          bool: false
        },
        {
          answer: 'человек впервые совершил полет в космическом пространстве',
          bool: true
        },
        {
          answer: 'был запущен первый космический спутник',
          bool: false
        }
        ] 
      },
      {
        question: 'Как звали конструктора, благодаря которому стал возможен первый космический полет?',
        type: 'pick',
        answers: [{
          answer: 'Михаил Ломоносов',
          bool: false
        },
        {
          answer: 'Михаил Тихонравов',
          bool: false
        },
        {
          answer: 'Сергей Королев',
          bool: true
        }
        ] 
      },
      {
        question: 'Какую фразу произнесла Валентина Терешкова перед полётом?',
        type: 'pick',
        answers: [{
          answer: 'Маленький шаг для человека, большой скачок для человечества!',
          bool: false
        },
        {
          answer: 'Только бы не облажаться!',
          bool: false
        },
        {
          answer: 'Эй! Небо! Сними шляпу! Я к тебе иду!',
          bool: true
        }
        ] 
      },
      {
        question: 'Валерий Поляков – обладатель мирового рекорда, самого длительного полета в космос. Сколько он провел на орбите?',
        type: 'pick',
        answers: [{
          answer: '437 суток и 18 часов',
          bool: true
        },
        {
          answer: '345 суток и 12 часов',
          bool: false
        },
        {
          answer: '299 суток и 22 часа',
          bool: false
        }
        ] 
      },
      {
        question: 'Какой город называют «колыбелью космонавтики»?',
        type: 'pick',
        answers: [{
          answer: 'Тулу',
          bool: false
        },
        {
          answer: 'Королев',
          bool: false
        },
        {
          answer: 'Калугу',
          bool: true
        }
        ] 
      },
      {
        question: 'Какой фильм, по традиции, смотрят космонавты перед полетом?',
        type: 'pick',
        answers: [{
          answer: '«Через тернии к звездам»',
          bool: false
        },
        {
          answer: '«Белое солнце пустыни»',
          bool: true
        },
        {
          answer: '«Марсианин»',
          bool: false
        }
        ] 
      },
      {
        question: 'Сопоставьте космонавтов по их позывным',
        type: 'compare',
        answersL: [{
          answer: 'Юрий Гагарин',
          id: 1
        },
        {
          answer: 'Валентина Терешкова',
          id: 2
        },
        {
          answer: 'Герман Титов',
          id: 3
        },
        {
          answer: 'Алексей Леонов',
          id: 4
        }
        ],
        answersR: [{
          answer: 'Кедр',
          id: 1
        },
        {
          answer: 'Чайка',
          id: 2
        },
        {
          answer: 'Орел',
          id: 3
        },
        {
          answer: 'Алмаз - 2',
          id: 4
        }
        ] 
      },
      {
        question: 'Космонавты утверждают, что у космоса есть свой запах. На что он похож?',
        type: 'pick',
        answers: [{
          answer: 'запах моря',
          bool: false
        },
        {
          answer: 'запах ночных фиалок',
          bool: false
        },
        {
          answer: 'запах воздуха после грозы',
          bool: true
        }
        ] 
      },
      {
        question: 'Все знают фразу, ставшую в последствии крылатой, которую сказал Юрий Гагарин перед стартом. А вот какую фразу он бросил во время заключительной стадии полета?',
        type: 'pick',
        answers: [{
          answer: '«Я горю, прощайте, товарищи!»',
          bool: true
        },
        {
          answer: 'Космос — это космос. Ничего похожего на Земле нет',
          bool: false
        },
        {
          answer: 'Космос вовсе не далек. До него всего час езды — при условии, что твоя машина может ехать вертикально вверх',
          bool: false
        }
        ] 
      },
      {
        question: 'Чему или кому посвящён памятник, установленный на Луне?',
        type: 'pick',
        answers: [{
          answer: 'памяти погибших космонавтов',
          bool: true
        },
        {
          answer: 'Земле',
          bool: false
        },
        {
          answer: 'первому шагу по лунной поверхности',
          bool: false
        }
        ] 
      },
      {
        question: 'Космический зонд «Вояджер-1» летит к звездам со скоростью 62 тыс. км/ч уже 38 лет и улетел уже на 20,055 млрд км. На аппарате есть золотая пластинка со всякой информацией о Земле для инопланетян. Чего нет на пластинке?',
        type: 'pick',
        answers: [{
          answer: 'послания «Мир», «Ленин», «СССР»',
          bool: true
        },
        {
          answer: '«волшебные флейты» Моцарта',
          bool: false
        },
        {
          answer: 'обращение Б.Н. Ельцина',
          bool: false
        }
        ] 
      },
      {
        question: 'МКС сегодня',
        type: 'pickImage',
        answers: [{
          answer: '/mks1.jpg',
          bool: true
        },
        {
          answer: '/mks2.png',
          bool: false
        },
        {
          answer: '/mks3.jpg',
          bool: false
        }
        ] 
      },
      {
        question: 'Кто такая Светлана Евгеньевна Савицкая?',
        type: 'pick',
        answers: [{
          answer: 'первая космическая туристка',
          bool: false
        },
        {
          answer: 'первая в мире женщина-космонавт, вышедшая в открытый космос',
          bool: false
        },
        {
          answer: 'женщина космонавт, рекордсмен по длительности пребывания в открытом космосе',
          bool: true
        }
        ] 
      },
      {
        question: 'Белка и Стрелка — советские собаки-космонавты, совершившие космический полёт на корабле «Спутник-5» 19 августа 1960 года. Они стали первыми животными, которые совершили орбитальный космический полёт и успешно вернулись на Землю. Но мало кому известно, что знаменитые Белка и Стрелка имели другие имена. Главнокомандующий ракетными войсками стратегического назначения Митрофан Иванович Неделин потребовал сменить иностранные имена собак! Как звали собак изначально?',
        type: 'pick',
        answers: [{
          answer: 'Рокки и Мерфи',
          bool: false
        },
        {
          answer: 'Мишка и Чижик',
          bool: false
        },
        {
          answer: 'Альбина и Маркиза',
          bool: true
        }
        ] 
      },
      {
        question: 'В газетах, соблюдая секретность, его называли Теоретиком Космонавтики. Сразу после войны математик и механик, стал заниматься ракетной тематикой. Помогал Королеву создать оптимальную конструкцию ракеты Р-7, которая и открыла космическую эру. О ком идет речь?',
        type: 'pick',
        answers: [{
          answer: 'Константин Циолковский',
          bool: false
        },
        {
          answer: 'Николай Пилюгин',
          bool: false
        },
        {
          answer: 'Мстислав Келдыш',
          bool: true
        }
        ] 
      },
      {
        question: 'В конце мая 2023 года Указом Президента Российской Федерации учреждена новая награда – Орден Гагарина, она присуждается за заслуги в области освоения космоса. Кто из учредителей Движения «ЮНАРМИЯ» стал первым кавалером ордена Гагарина?',
        type: 'pick',
        answers: [{
          answer: 'Сергей Рязанский',
          bool: false
        },
        {
          answer: 'Юлия Пересильд',
          bool: false
        },
        {
          answer: 'Валентина Терешкова',
          bool: true
        }
        ] 
      },
      {
        question: 'В июле 2018 года на третьем слёте Всероссийского военно-патриотического общественного движения «ЮНАРМИЯ» этот потомственный космонавт был избран начальником Главного Штаба Движения «ЮНАРМИЯ». Занимал должность до декабря 2020 года.',
        type: 'pick',
        answers: [{
          answer: 'Роман Романенко',
          bool: true
        },
        {
          answer: 'Сергей Волков',
          bool: false
        },
        {
          answer: 'Олег Артемьев',
          bool: false
        }
        ] 
      },
    ]
    },

    {title: 'Военная история',
    questionList: [
      {
        question: 'Невская битва под руководством князя Александра Ярославича произошла в 1240 году. Какой фразой, согласно преданию, воодушевил князь свою дружину?',
        type: 'pick',
        answers: [{
          answer: 'Кто напуган, наполовину – побит.',
          bool: false
        },
        {
          answer: 'Братья! Не в силах Бог, а в правде!',
          bool: true
        },
        {
          answer: 'Русские в плен не сдаются!',
          bool: false
        },
        {
          answer: 'Некуда уже теперь деться — Потягнем братия на поганых!',
          bool: false
        }
        ] 
      },
      {
        question: 'В мае 1945 года Красная армия взяла Берлин второй раз в истории. Знаешь, когда была основана эта традиция?',
        type: 'pick',
        answers: [{
          answer: 'В 1799 году, после перехода Суворова через Альпы.',
          bool: false
        },
        {
          answer: 'В 1814 году, когда освобождали Европу от Наполеона.',
          bool: false
        },
        {
          answer: 'В 1760 году, когда наши совместно с австрийцами освобождали Пруссию от ее короля, Фридриха II.',
          bool: true
        },
        {
          answer: 'В 1918 году, когда был заключен Брестский мир.',
          bool: false
        }
        ] 
      },
      {
        question: 'На параде Победы 1945 года была осуществлена «церемония повержения вражеских знамен и штандартов», а что с ними стало потом?',
        type: 'pick',
        answers: [{
          answer: 'Маленький проданы на частном аукционе',
          bool: false
        },
        {
          answer: 'перевезены в Западную Германию',
          bool: false
        },
        {
          answer: 'помещены в советский музей',
          bool: true
        },
        {
          answer: 'они были уничтожены',
          bool: false
        }
        ] 
      },
      {
        question: 'В 1943 г. совершенно неожиданно для фашистов на полях сражений появился новый тяжелый танк, созданный конструкторами в рекордно короткие сроки. Фашистское командование приказало своим танковым частям избегать встречных боев с этими танками и вести по ним огонь только из засады и укрытия. Что это за танк?',
        type: 'pick',
        answers: [{
          answer: 'КВ',
          bool: false
        },
        {
          answer: 'Т-70',
          bool: false
        },
        {
          answer: 'ИС – 1',
          bool: false
        },
        {
          answer: 'ИС – 2',
          bool: true
        }
        ] 
      },
      {
        question: 'За мужество, стойкость и массовый героизм, проявленные защитниками городов в борьбе за свободу и независимость Отечества присваивается Почётное звание Российской Федерации «Город воинской славы». Скольким городам присвоено это звание?',
        type: 'pick',
        answers: [{
          answer: '47',
          bool: true
        },
        {
          answer: '17',
          bool: false
        },
        {
          answer: '22',
          bool: false
        },
        {
          answer: '89',
          bool: false
        }
        ] 
      },
      {
        question: 'Представителей какого рода войск Советской армии в годы Великой Отечественной войны немцы называли «черной смертью»?',
        type: 'pick',
        answers: [{
          answer: 'Летчиков истребителей',
          bool: false
        },
        {
          answer: 'Танкистов',
          bool: false
        },
        {
          answer: 'Моряков',
          bool: true
        },
        {
          answer: 'Артиллеристов',
          bool: false
        }
        ] 
      },
      {
        question: 'Какая из крепостей прославилась героической обороной в начале войны и надписью «Я умираю, но не сдаюсь! Прощай, Родина» сделанной в казармах войск НКВД СССР?',
        type: 'pick',
        answers: [
          {
            answer: 'Гродненская крепость',
            bool: false
          },
          {
            answer: 'Брестская крепость',
            bool: true
          },
          {
            answer: 'Крепость Осовец',
            bool: false
          },

        ], 
      },
      {
        question: 'Какой из указов немецкого командования снимал ответственность с солдат за свершение военных преступлений, направленных против местного населения?',
        type: 'pick',
        answers: [{
          answer: '«О мерах по укреплению дисциплины и порядка в армии и запрещении самовольного отхода с боевых позиций».',
          bool: false
        },
        {
          answer: '«О применении военной подсудности в районе „Барбаросса“ и об особых мерах войск».',
          bool: true
        },
        {
          answer: '«Об ответственности военнослужащих за сдачу в плен и оставление врагу оружия».',
          bool: false
        }
        ] 
      },
      {
        question: 'За что и когда были присвоены первые звания Героев Советского Союза в мирное время?',
        type: 'pick',
        answers: [{
          answer: '«1934 год, за спасение пассажиров парохода «Челюскин», зажатого льдами в Беринговом проливе',
          bool: true
        },
        {
          answer: 'в 1930 годы за исследование Арктики',
          bool: false
        },
        {
          answer: '1961 году за первый полет в космос',
          bool: false
        }
        ] 
      },
      {
        question: 'За годы войны на фронте с оружием в руках сражались от 600 тысяч до 1 миллиона женщин. Сколько из них во время войны получили награду «Герой Советского Союза»?',
        type: 'pick',
        answers: [{
          answer: '87 женщин',
          bool: true
        },
        {
          answer: '82 женщины',
          bool: false
        },
        {
          answer: '57 женщин',
          bool: false
        }
        ] 
      },
      {
        question: 'Какое географическое название - место, где состоялось одно из сражений с участием русской армии, - стало нарицательным, обозначающим катастрофическое поражение?',
        type: 'pick',
        answers: [{
          answer: 'Шипка',
          bool: false
        },
        {
          answer: 'Кёнигсберг',
          bool: false
        },
        {
          answer: 'Цусима',
          bool: true
        }
        ] 
      },
      {
        question: 'Что такое КОНР?',
        type: 'pick',
        answers: [{
          answer: 'Одно из управлений советской контрразведки',
          bool: false
        },
        {
          answer: 'Отдел пропаганды Рабоче-Крестьянской Красной Армии',
          bool: false
        },
        {
          answer: 'Политическая и военная организация русских, сражавшихся на стороне Вермахта',
          bool: true
        }
        ] 
      },
      {
        question: 'Во время Второй мировой войны стоматолог из Пенсильвании убедил президента Рузвельта применить зажигательные бомбы со специальным механизмов для нападения на Японию. Носителями бомб должны были стать…',
        type: 'pick',
        answers: [{
          answer: 'голуби',
          bool: false
        },
        {
          answer: 'собаки',
          bool: false
        },
        {
          answer: 'летучие мыши',
          bool: true
        }
        ] 
      },
      {
        question: 'Во время Второй мировой войны в польской армии был необычный солдат-животное, которому затем был официально присвоен статус военного с рангом и номером, как положено. О каком животном идёт речь?',
        type: 'pick',
        answers: [{
          answer: 'собака',
          bool: false
        },
        {
          answer: 'медведь',
          bool: true
        },
        {
          answer: 'волк',
          bool: false
        }
        ] 
      },
      {
        question: 'Немцы любят комфорт! В их окопах во время Первой мировой войны были даже...',
        type: 'pick',
        answers: [{
          answer: 'прикроватные тумбы с фотографиями родных',
          bool: false
        },
        {
          answer: 'шкафы и дверные замки',
          bool: true
        },
        {
          answer: 'чайные сервизы',
          bool: false
        }
        ] 
      },
      {
        question: 'Учредитель Движения «ЮНАРМИЯ», советский и российский военачальник, общественный и политический деятель, гвардии генерал-полковник, командир 9-й роты — той самой, которую прославил фильм Фёдора Бондарчука. Все это о:',
        type: 'pick',
        answers: [{
          answer: 'Николае Викторовиче Стаськове',
          bool: false
        },
        {
          answer: 'Вячеславе Алексеевиче Бочарове',
          bool: false
        },
        {
          answer: 'Валерие Александровиче Востротине',
          bool: true
        }
        ] 
      },
      {
        question: 'В юнармейском календаре Всероссийская военно-спортивная игра «Победа» считается одним из самых ярких и масштабных событий. Традиции какой легендарной игры она продолжает?',
        type: 'pick',
        answers: [{
          answer: 'Зарница',
          bool: true
        },
        {
          answer: 'Пионерская зорька',
          bool: false
        },
        {
          answer: 'Войнушка',
          bool: false
        }
        ] 
      },
      {
        question: '26 марта 2023 года Движение «ЮНАРМИЯ» дало старт полномасштабной Всероссийской акции у памятников и мемориалов своих городов. Юнармейцы провели субботники, раздали прохожим карточки с информацией о героях памятных мест, также ребята поделились историями о том, как в Движении ведется поиск архивных сведений о погибших защитниках Отечества, работа с установлением имён павших героев и увековечение их светлой памяти. Какое название носит данная акция?',
        type: 'pick',
        answers: [{
          answer: '«Граффити защитник»',
          bool: false
        },
        {
          answer: '«Верни Герою имя»',
          bool: true
        },
        {
          answer: '«Герои среди нас»',
          bool: false
        }
        ] 
      },
    ]
    },
  ]

  const data = [
    {link: 'Unavia_Render_Logo', name: '"ЮНАВИА" - это...'},
    {link: 'Верни Герою имя Старт акции 26 марта', name: 'Акция "Верни Герою имя"'},
    {link: 'Всероссийский проект «Яхтенные походы ЮНФЛОТ» в Ленинградской области', name: 'Яхтенные походы "ЮНФЛОТ" в Ленинградской области'},
    {link: 'Кто такой юнармеец', name: 'Кто такой юнармеец?'},
    {link: 'Слет ЮНАВИА ЮНФЛОТ_2023', name: 'Всероссийский слет отрядов "ЮНАВИА и ЮНФЛОТ"'},
    {link: 'Смена «ЮНТЕХ» 2023', name: 'Инновационно-техническая смена "ЮНТЕХ" в ВДЦ "Смена"'},
    {link: 'Что такое «ЮНАВИА»_', name: 'Что такое "ЮНАВИА"'},
    {link: 'Что такое ЮНАРМИЯ', name: 'Что такое "ЮНАРМИЯ"'},
    {link: 'Что такое ЮНФЛОТ', name: 'Что такое "ЮНФЛОТ"'},
    {link: 'ЮНАВИА_2022', name: 'ЮНАВИА. Общие сведения'},
    {link: 'ЮНАРМИЯ', name: '4 образа юнармейца'},
    {link: 'ЮНФЛОТ Новороссийск', name: 'Яхтенные походы "ЮНФЛОТ" в Новороссийске'},
    {link: 'ЮНФЛОТ', name: '"ЮНФЛОТ" - это...'},
    {link: 'ЮНФЛОТ_2022', name: '"ЮНФЛОТ". Общие сведения'},
    {link: 'Яхтенные походы ЮНФЛОТ_Мурманск 2023', name: 'Яхтенные походы "ЮНФЛОТ" в Мурманске'},
  ]
  
  const dataLink = [
    {title: '«ЮНАВИА»', link: 'article', id: 1},
    {title: '«ЮНФЛОТ»', link: 'article', id: 2},
    {title: '«ЮНТЕХ»', link: 'article', id: 3},
    {title: 'Инновационно-техническая смена «ЮНТЕХ»', link: 'article', id: 4},
    {title: 'Юнармейский норматив', link: 'test', id: 5},
    {title: 'Видео', link: 'video', id: 7},
  ]
  
  const [page, setPage] = useState('default');
  const [id, setId] = useState('');

  const handleClick = (el) => {
    setPage(el.link);
    setId(el.id)
  }

  const getPage = () => {
    switch (page) {
      case 'video':
        return <Video setPage={setPage} data={data} pathName='Видео Исследователь'/>
      case 'test':
        return <TestWrapper setPage={setPage} data={testData} testTitle={testTitle}/>
      case 'article':
        return <Article setPage={setPage} data={articleData} id={id}/>
    
      case 'default':
        return (
        <div className={styles.content}>
          <Link to='/panel' className={styles.back}><img src={buttonBack}/></Link>
          <div className={styles.contentImg}>
            <img src={emblemResearcher} className={styles.emblemCreater} />  
            <img src={researcherI} className={styles.creater}/>  
          </div>

          <div className={styles.buttonWrapper}>
            {dataLink.map(el =>
                <div 
                onClick={() => handleClick(el)}
                className={styles[`button${el.id}`]} >
                <span className={styles.buttonTitle}>{el.title}</span>
              </div>
              )}
          </div>
        </div>)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={header} className={styles.headerBg}/>
        <div className={styles.headerInfo}>
          <img src={logo} className={styles.logo}/>
          <div className={styles.titleBlock}>
            <div className={styles.titleH2}>ВСЕРОССИЙСКОЕ ВОЕННО-ПАТРИОТИЧЕСКОЕ <br/>ОБЩЕСТВЕННОЕ ДВИЖЕНИЕ</div>
            <div className={styles.titleH1}>"ЮНАРМИЯ"</div>
          </div>
        </div>
      </div>

      {getPage()}
    </div>
  )
}

export default Researcher