import * as types from "../actions/types";

const initialState = {
  about: null,
  loading: false,
};

export default function about(state = initialState, action) {
  switch (action.type) {
    case types.GET_ABOUT_UNARMAINS:
      return {
        ...state,
        about: action.payload,
      };
    case types.GET_ABOUT_UNARMAINS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
      default:
        return state;
    }
}