import * as types from "../../actions/types";



const initialState = {
  permissionGroups: null,
  groupUsers: null,
  groupInfo: null,
  loading: false,
};


export function permission_groups (state = initialState, action) {
  switch (action.type) {
    case types.SET_PERMISSION_GROUPS:
      return {
        ...state,
        permissionGroups: action.payload
      };
    case types.SET_PERMISSION_GROUP_USERS:
      return {
        ...state,
        groupUsers: action.payload
      };
    case types.SET_PERMISSION_GROUP_INFO:
      return {
        ...state,
        groupInfo: action.payload
      };
    case types.SET_PERMISSION_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}