import React from 'react'
import styles from './styles.module.css'
import LOGO from '../../assets/images/label.png'

const Index = () => {
    return (
        <>
        <div className={styles.header} style={{ backgroundImage: "url(/img/header.png)" }}>
            <img src={LOGO} alt="Юнармия" className={styles.logo}/>
            <h1>Всероссийское детско-юношеское военно-патриотическое общественное движение «ЮНАРМИЯ»</h1>
        </div>
        <div className={styles.container}>
            <div className={styles.content}>
                <h2>Политика конфиденциальности</h2>
                <p>
                    Настоящая Политика конфиденциальности для мобильного приложения (далее —
                    Политика) действует в отношении той информации, которую Всероссийское
                    детско-юношеское военно-патриотическое общественное движение «ЮНАРМИЯ» (ОГРН:
                    1167700061540; ИНН: 7704366170, адрес: 119160, Россия, г. Москва, ул. Знаменка,
                    д.19) (далее – Компания) может получить с устройства пользователя во время
                    использования им мобильного приложения «Цифровой дом Юнармейца» (далее –
                    Приложение).
                </p>
                <p>
                    Использование Приложения означает безоговорочное согласие пользователя с
                    настоящей Политикой и указанными в ней условиями обработки информации,
                    получаемой с устройства пользователя. В случае несогласия с Политикой
                    пользователь должен воздержаться от использования Приложения.
                </p>
                <p>
                    Приложение и услуги в рамках Приложения позволяют пользователю осуществлять
                    взаимодействие с Компанией на основании заключенных договоров и соглашений с
                    Компанией, которые в числе прочего регулируют все вопросы обработки и хранения
                    Компанией персональных данных пользователя. Настоящая Политика применима только
                    к Приложению. Компания не контролирует и не несет ответственность за информацию
                    (последствия её передачи), переданную пользователем третьей стороне, в случае
                    если такая передача была выполнена на ресурсе третьей стороны, на который
                    пользователь мог перейти по ссылкам из Приложения.
                </p>
            </div>

            <div className={styles.content}>
                <h2>1. Состав информации, которая может быть получена с устройства пользователя
                    при использовании Приложения и цели её получения (далее - Информация
                    пользователя):</h2>
                <p>
                    1.1. Информация о местоположении устройства пользователя (на основе данных сети
                    оператора сотовой связи и сигналов GPS) используется для информирования о
                    дополнительных сервисах, доступных пользователю и обусловленных его
                    местоположением.
                </p>
                <p>
                    1.2. Фотоизображения, полученные с использованием камеры устройства, а также
                    хранимые в памяти устройства, используются в рамках услуг, реализуемых в
                    Приложении, в том числе для формировании заявки на вступление.
                </p>
                <p>
                    1.3. Информация о&nbsp;ФИО, дате рождения, паспортных данных,&nbsp;телефоне,
                    адресе электронной почте, адресе регистрации. Используются&nbsp;в рамках услуг,
                    реализуемых в Приложении, в том числе для актуализации информации о
                    воспитанниках и сотрудниках Компании.
                </p>
            </div>

            <div className={styles.content}>
                <h2>2. Условия обработки Информации Пользователя&nbsp;</h2>
                <p>
                    2.1. В соответствии с настоящей Политикой Компания осуществляет обработку
                    только той информации и только для тех целей, которые определены в пункте
                    1&nbsp;..<br/>
                    2.2. Компания принимает все зависящие от&nbsp;Компании&nbsp;организационные и
                    технические меры для защиты Информации пользователя от неправомерного доступа
                    третьих лиц,&nbsp;использования, копирования и распространения.
                </p>
                <p>
                    2.3. Для целей, изложенных в настоящей Политике, Компания может привлекать к
                    обработке Информации пользователя партнеров, с которыми у Компании заключены
                    соответствующие соглашения о конфиденциальности. Передача Компанией партнерам
                    обезличенных данных об использовании Приложения для целей улучшения работы
                    Приложения осуществляется на основании договоров с партнерами.
                </p>
                <p>
                    2.4. Информация пользователя может сохраняться на ресурсах Компании и
                    его&nbsp;партнеров в течение срока действия договорных отношений между
                    Компанией и пользователем касаемо Приложения, а также в течение 5 лет после
                    расторжения таких договоров.
                </p>
                <p>
                    2.5. Информация Пользователя может быть предоставлена&nbsp;государственным
                    органам в соответствии с требованиями действующего законодательства.
                </p>
            </div>

            <div className={styles.content}>
                <h2>3. Обновление настоящей Политики.</h2>
                <p>
                    3.1. В настоящую Политику могут быть внесены изменения. Компания имеет право
                    вносить изменения по своему усмотрению, в том числе, но не ограничиваясь, в
                    случаях, когда соответствующие изменения связаны с изменениями в применимом
                    законодательстве, а также когда соответствующие изменения связаны с изменениями
                    в работе Компании
                </p>
                <p>
                    3.2. Компания обязуется не вносить существенных изменений, не налагать
                    дополнительных обременений или ограничений прав Пользователя, установленных
                    настоящей Политикой без соответствующего уведомления. Пользователи будете
                    уведомлены о таких изменениях.
                </p>
            </div>
        </div>
        </>
    )
}

export default Index