import React from 'react'
import Modal from 'react-responsive-modal'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import CustomInput from '../../components/CustomInput/CustomInput'
import { useDispatch } from 'react-redux'
import { createPermissionGroup, updatePermissionGroup } from '../../actions/permissions'


export default function GroupModal({open, onClose, data, addMode}) {
  
  const dispatch = useDispatch()

  const modalTitle = `${addMode ? 'Добавление' : 'Редактирование'} роли`
  
  const submitForm = async (values) => {
    const {id, name, displayname} = values
    const payload = {name, displayname}
    await dispatch(addMode
      ? createPermissionGroup(payload)
      : updatePermissionGroup(id, payload)
    )
    onClose()
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data || {},
    enableReinitialize: true,
    onSubmit: submitForm
  })


  return (
    <Modal
        open={open}
        onClose={onClose}
        showCloseIcon={false}
        classNames={{
            modal: styles.modal
        }}
    >
        <div className={styles.modalTitle}>{modalTitle}</div>
        <div className={styles.modalBody}>
          <div>
            <CustomInput
              title='Название'
              name='displayname'
              value={values.displayname}
              onChange={handleChange}
            />
            <CustomInput
              title='Алиас'
              name='name'
              value={values.name}
              onChange={handleChange}
              width='100px'
              margin='.5rem 0 0 0'
            />
          </div>
          <div className="settingModal__btns">
            <button
              className="newsListHeader__btn btn_grey"
              type="button"
              onClick={handleSubmit}
            >
              {addMode ? "Добавить" : "Сохранить"}
            </button>
            <button
              className="newsListHeader__btn btn_grey"
              onClick={onClose}
              type="button"
            >
              Закрыть
            </button>
          </div>
        </div>
    </Modal>
  )
}
