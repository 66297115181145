import * as types from "../actions/types";

const initialState = {
  data: null,
  event: {},
  loading: false
};

export default function eventsSchedule (state = initialState, action) {
  switch (action.type) {
    case types.SET_CHIEFS_SCHEDULE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case types.SET_CHIEFS_SCHEDULE_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case types.SET_CHIEFS_SCHEDULE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
      default:
        return state;
    }
}