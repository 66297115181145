import React from 'react'
import Button from '../../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import Li from '../../../components/Li'
import { thrash } from '../../../assets/svg/other'
import styles from '../styles.module.css'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupUsers } from '../../../actions/permissions'
import Loader from '../../../components/Loader/Loader'



export function Employees ({roleId}) {
  
  const dispatch = useDispatch()
  const {groupUsers: peoples, loading} = useSelector(state => state.roles.permission_groups)


  useEffect(() => {
    dispatch(getGroupUsers(roleId))
  }, [])

  const renderLi = ({displayname}) => {
    return (
      <Li>
        <div className={styles.liTitle}>{displayname}</div>
        {thrash()}
      </Li>
    )
  }

  return (
    <>
      {loading ? <Loader/> : <ul className={styles.peoplesList}>{peoples?.map(renderLi)}</ul>}
      <Button style={{padding: '.5rem 1.5rem'}}>
        <IoIosAdd size={30}/>
        Добавить сотрудника
      </Button>
    </>
  )
}
