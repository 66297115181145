import React, {useState} from 'react'
import Modal from 'react-responsive-modal'
import styles from'./DistributionModal.module.css'
import CustomInput from "../CustomInput/CustomInput"
import CustomTextarea from "../CustomTextarea/CustomTextarea"
import FileLabel from "../FileLabel/FileLabel"
import { InputFile } from '../../assets/common/InputFile/InputFile'
import { ButtonRed } from '../../assets/common/ButtonRed/ButtonRed'
import NoData from "../NoData/NoData"
import { RiSendPlaneFill } from "react-icons/ri";
import Loader from '../Loader/Loader'
import ReactQuill from "react-quill";
import { BACKEND_DOMAIN, BACKEND_URL_PREFIX } from '../../utils/const'


const buttonStyles = {
  margin: "10px 15px",
  borderRadius: "5px",
}

const initialQuill = '<p><br></p><p>_____________________</p><p><strong style="color: rgb(136, 136, 136);">Это письмо было сформировано автоматически, отвечать на него не нужно. Возникли вопросы? Напиши нам:</strong><strong>&nbsp;</strong><a href="mailto:support@yunarmy.ru" rel="noopener noreferrer" target="_blank" style="color: rgb(60, 120, 181);"><strong>support@yunarmy.ru</strong></a><strong>.</strong></p>'

export default function DistributionModal({
  onClose,
  item,
  allProfiles,
  modalLoading,
  massiveSendCustomDocument
}) {
  
  
  const [uploadFiles, setUploadFiles] = useState([])
  const [formState, setformState] = useState(item || {subject: "", letter: initialQuill})
  
  const addFiles = (e) => {
    const files = e.target.files
    const filesList = [...uploadFiles]
    setUploadFiles([...filesList, ...files])
  }

  const deleteCurrentFile = (ind) => () => {
    const list = [...uploadFiles]
    list.splice(ind, 1)
    setUploadFiles(list)
  }

  const fileClick = ({name, path}) => () => {
    const link = document.createElement('a')
    link.setAttribute('href', `${BACKEND_DOMAIN || ''}/${path}/${name}`);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', 'download');
    link.click()
  }

  const handleChangeForm = (e) => {
    setformState(prev => ({...prev, [e.target.name]: e.target.value}))
  }

  const submitDocument = async () => {
    const profiles = allProfiles.map(prof => prof.id).join(',')
    const payload = {...formState, profiles}
    const response = await massiveSendCustomDocument(payload, uploadFiles)
    response && onClose()
  }

  const renderFiles = () => {
    const historyMode = formState.id
    const filesList = formState.files || uploadFiles
    return (
      filesList.length
        ? filesList.map((file, ind) => {
          const fileName = file.name
          const imageMode = historyMode ? file.file_type.includes('image') : file.type.includes('image')
          const fileLink = historyMode ? `${BACKEND_DOMAIN || ''}/${file.path}/${file.name}` : URL.createObjectURL(file)
          const onDelete = historyMode ? undefined : deleteCurrentFile(ind)
          const onClick = historyMode ? fileClick(file) : undefined
          return (
            <FileLabel
              key={ind}
              fileName={fileName}
              imageMode={imageMode}
              fileLink={fileLink}
              onDelete={onDelete}
              onClick={onClick}
            />
          )
        })
        : <NoData
            style={{marginTop: "20px"}}
            text='файлы отсутствуют'
          />
    )
  }

  return (
    <Modal
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
      open
      onClose={onClose}
    >
        <div className={styles.title}>
          {item ? 'Письмо' : 'Новое письмо'}
          <RiSendPlaneFill size={26}/>
        </div>
        {modalLoading
        ? <Loader top='40%' left='45%'/>
        : <div className={styles.modalBody}>
          <CustomInput
            value={formState.subject}
            name="subject"
            title="Тема письма"
            onChange={handleChangeForm}
            />
          <div className={styles.quillBlock}>
            <div>Текст рассылки:</div>
            <ReactQuill
              className={styles.quill}
              value={formState.letter}
              onChange={val => handleChangeForm({target: {name: 'letter', value: val}})}
            />
          </div>
          <div className={styles.filesBlock}>
            <p>Файлы:</p>
            <div className={styles.filesList}>{renderFiles()}</div>
            {!item &&
            <InputFile
              title='Выберите файлы'
              onChange={addFiles}
              accept=".doc, .docx, application/msword, application/pdf, image/*"
              multiple
            />}
          </div>
          <div className={styles.buttons}>
            {!item && <ButtonRed onClick={submitDocument} style={buttonStyles}>Отправить</ButtonRed>}
            <ButtonRed onClick={onClose} style={buttonStyles}>Закрыть</ButtonRed>
          </div>
        </div>}
    </Modal>
  )
}
