import * as types from '../actions/types';

const initialState = {
    count: null,
    page_count: null,
    data: null,
    articles: null,
    steps_data: null,
    steps_count: null,
    steps_page_count: null,
    task_profiles: null,
    task_realization: null,
    loading : false,
    steps_data_load: false
  };

export default function task (state = initialState, action) {
  switch (action.type) {
    case types.SET_TASK_COUNT:
      return {
        ...state,
        count: action.payload.data,
        page_count: action.payload.pageCount
      };
      case types.SET_TASK_DATA:
      return {
        ...state,
        data: action.payload
      };
      case types.SET_TASK_ARTICLES:
      return {
        ...state,
        articles: action.payload,
      };
      case types.SET_STEPS_DATA_LOAD:
      return {
        ...state,
        steps_data_load: action.payload,
      };
      case types.SET_STEPS_COUNT:
      return {
        ...state,
        steps_count: action.payload.data,
        steps_page_count: action.payload.pageCount
      };
      case types.SET_STEPS_DATA:
        return {
          ...state,
          steps_data: action.payload,
        };
      case types.SET_TASK_PROFILES:
        return {
          ...state,
          task_profiles: action.payload,
        };
      case types.SET_TASK_REALIZATION:
        return {
          ...state,
          task_realization: action.payload,
        };
      case types.SET_TASK_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
    default:
      return state;
  }
}