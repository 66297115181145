import React, { useState, useEffect } from 'react'
import styles from './CheckMode.module.css'
import {IoIosArrowRoundBack} from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { getManualProfiles, getManualAnswers, updateManualAnswerStatus } from '../../../actions/news';
import { BACKEND_URL_PREFIX } from '../../../utils/const';
import NoData from '../../../components/NoData/NoData';
import { AiOutlineCheck } from "react-icons/ai";
import Loader from '../../../components/Loader/Loader';


const STATUSES = [
  {name: 'Не проверено', value: 'result_checking'},
  {name: 'Верно', value: 'passed'},
  {name: 'Не верно', value: 'failed'},
]

export default function CheckMode({
  goBack,
  stageId
}) {
  
  const dispatch = useDispatch()
  const { manualProfiles: profiles, loading } = useSelector(state => state.schedule)

  const [profileId, setProfileId] = useState(null)

  useEffect(() => {
    dispatch(getManualProfiles(stageId))
  }, [profileId])
  
  const renderRow = (profile, ind) => {
    
    const { profile_id, region, name, status } = profile
    const checked = status[0] === 'checked' ? 'Проверено' : 'Не проверено'
    const passed = !!checked && status[1] === 'passed'

    return (
      <tr
        key={profile_id}
        onClick={() => setProfileId(profile_id)}
      >
          <td>{ind+1}</td>
          <td>{name}</td>
          <td>{region}</td>
          <td>{checked}</td>
          <td>{passed && <AiOutlineCheck/>}</td>
      </tr>
    )
  }
  
  return (
    <>
      {profileId
        ? <ProfileAnswers
            goBack={() => setProfileId(null)}
            stageId={stageId}
            profileId={profileId}
          />
        : (
          <div className={styles.container}>
            <div onClick={goBack} className={styles.goBack}>
              <IoIosArrowRoundBack size={20} />
              назад
            </div>
            {loading
              ? <Loader top='55%' left='47%' />
              : (
                  profiles?.length
                    ?  <table>
                          <thead>
                            <th>№</th>
                            <th>ФИО</th>
                            <th>Регион</th>
                            <th>Статус</th>
                            <th>Проходит этап</th>
                          </thead>
                          <tbody>
                            {profiles?.map(renderRow)}
                          </tbody>
                        </table>
                    : <NoData text="данные отсутствуют" />
              )
            }
          </div>
        )
      }
    </>
  )
}



const ProfileAnswers = ({ goBack, stageId, profileId }) => {
  
  const dispatch = useDispatch()
  const { manualAnswers, loading } = useSelector(state => state.schedule)

  const [localData, setLocalData] = useState(null)

  useEffect(() => {
    dispatch(getManualAnswers(stageId, profileId))    
  }, [])

  useEffect(() => {
    setLocalData(manualAnswers)
  }, [manualAnswers])
  


  const changeStatusHandler = (index, resultId) => (e) => {
    const {value: status} = e.target
    const newLocalData = [...localData]
    const newProfileItem = {...newLocalData[index], status}
    newLocalData.splice(index, 1, newProfileItem)
    setLocalData(newLocalData)
    dispatch(updateManualAnswerStatus(resultId, {status}))
  }

  const renderRow = (answerData, ind) => {
    const { id, question, answer, answer_file, status } = answerData
    const fileLink = answer_file && `${BACKEND_URL_PREFIX || ''}/${answer_file.path}/${answer_file.name}`
    return (
      <tr>
        <td>{question}</td>
        <td>{answer}</td>
        <td>
          {fileLink && <a href={fileLink}>Файл</a>}
        </td>
        <td style={{width: '100px'}}>
          <select onChange={changeStatusHandler(ind, id)} value={status}>
            {STATUSES.map(({name, value}) => <option value={value}>{name}</option>)}
          </select>
        </td>
      </tr>
    )
  }

  return (
    <div className={styles.container}>
      <div onClick={goBack} className={styles.goBack}>
        <IoIosArrowRoundBack size={20} />
        назад
      </div>
      {loading
        ? <Loader top='55%' left='47%' />
        : <table>
            <thead>
              <th>Вопрос</th>
              <th>Ответ</th>
              <th>Файл</th>
              <th>Статус</th>
            </thead>
            <tbody>
              {localData?.map(renderRow)}
            </tbody>
          </table>
      }
    </div>
  )
}
