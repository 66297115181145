import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./YunarmyStat.module.css";
import { getYunarmyStat, setYunarmyStat } from "../../actions/yunarmyStat";
import { getYearsArr } from "../../utils/yunarmyStat";
import Loader from "../../components/Loader/Loader"
import { createNotification } from "../../components/Notifications/Notifications";



const periods = [1, 2, 3, 4];

function YunarmyStat({
  getYunarmyStat,
  setYunarmyStat,
  data,
  loading,
  accessRights
}) {
  
  const updateRights = accessRights.ac_update

  const [currentYear, setCurrentYear] = useState(getYearsArr(2016));
  const [currentCell, setCurrentCell] = useState(null)

  useEffect(() => {
    getYunarmyStat(currentYear);
  }, [currentYear]);

  const f = x => ( (x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0) );

  const handleChangeCell = (e, i) => {
    const value = e.target.value;
    const obj = {...currentCell}
    if (value==="") {
      createNotification("error", "Недопустимое значение для ячейки")
      obj[i] = value
      obj.changed = true
      setCurrentCell(obj)
    } else if ((i==="value" && f(value)>0) || (i==="increase" && f(value)>2) || !Number(value)) {
      createNotification("error", "Недопустимое количество знаков после '.'")
    } else {
      obj[i] = value
      obj.changed = true
      setCurrentCell(obj)
    }
  }

  const setNewValue = () => {
    if (currentCell.changed) {
        const obj = {  
            quarter: currentCell.quarter,
            param: currentCell.paramid,
            increase: currentCell.increase ? currentCell.increase : 0,
            value: currentCell.value ? currentCell.value : 0
        }  
        setYunarmyStat(obj, currentYear)
        setCurrentCell(null)
    }
  }

  return (
    <div className={styles.wrapper}>
      <h1>Юнармия в цифрах</h1>
      <div className={styles.year}>
        <div>Выбор года:</div>
        <select onChange={(e) => setCurrentYear(e.target.value.split(","))}>
          <option value={getYearsArr(2016)}>Все</option>
          {getYearsArr(2016).map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      </div>
      {loading ? <Loader top="50%" left="50%"/> : (data && 
        <div className={styles.tableWrap}>
            <table className={styles.table}>
          <thead>
            <tr>
              <th rowSpan={2} className={styles.first}>
                Параметр
              </th>
              {data[0].years.map((it) => (
                <th colSpan={8}>{it.year}</th>
              ))}
            </tr>
            <tr>
              {currentYear.map((it) =>
                periods.map((i) => <th colSpan={2}>{i}</th>)
              )}
            </tr>
          </thead>
          <tbody>
            {[...data].map((it) => (
              <tr key={`${it.param}`}>
                <th className={styles.first}>{it.param}</th>
                {it.years.map((ite) =>
                  ite.quarts.map((item) =>
                    ["value", "increase"].map((i) => {
                      return (
                        <td
                          key={`${i}_${ite.year}_${it.param}`}
                          onClick={() => updateRights && setCurrentCell({...item, inputName: i})}
                        >
                          {item && item.paraname===currentCell?.paraname && item.quarter===currentCell?.quarter && i===currentCell?.inputName
                            ? <input 
                                size={"100px"}
                                value={currentCell[i]} 
                                onChange={(e) => handleChangeCell(e, i)} 
                                onBlur={setNewValue}
                                autoFocus={true}
                              />
                            : item
                              ? item[i]
                              : item
                          }
                        </td>
                      )
                    })
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.yunarmy_stat.data,
  loading: state.yunarmy_stat.loading,
  accessRights: state.auth.accessRights.find(i => i.name==='yunarmy_digits')||{}
});

export default connect(mapStateToProps, { getYunarmyStat, setYunarmyStat })(YunarmyStat);
