import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { InputFile } from "../../assets/common/InputFile/InputFile";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import styles from "./YunarmyHouses.module.css";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomDatepicker from "../../components/CustomDatepicker/CustomDatepicker";
import { IoMdClose } from "react-icons/io";
import Loader from "../../components/Loader/Loader";
import { BACKEND_DOMAIN } from "../../utils/const";
import { createNotification } from "../../components/Notifications/Notifications";
import DatePicker from 'react-datepicker'


const YunarmyHousesModal = ({
  type,
  loading,
  itemId,
  page,
  editYunarmyHouse,
  addYunarmyHouse,
  getCurrentYunarmyHouse,
  getDepartments,
  getDepartmentsByRegion,
  removeLocalDep,
  regionDeps,
  localDeps,
  accessRights,
  currentItem,
  regions,
  byReg,
  depsByReg,
  ...props
}) => {

  const updateRights = type === "ADD" || accessRights.ac_update

  const [item, setItem] = useState({});
  const [confirmation, setConfirmation] = useState(null);
  const [filesList, setFilesList] = useState([]);
  const [addFilesList, setAddFilesList] = useState([]);
  const [removeFiles, setRemoveFiles] = useState([]);
  const [validate, setValidate] = useState(false);
  const [regionDepId, setRegionDepId] = useState('0');
  const [domDepId, setDomDepId] = useState('0');

  useEffect(() => {
    if (item.region_id) {
      setRegionDepId(item.region_id);
      getDepartmentsByRegion(item.region_id);
    }
  }, [item.region_id])
  
  useEffect(() => {
    if (item.domestic_department_id && !domDepId) {
      setDomDepId(item.domestic_department_id);
    }
  }, [item.domestic_department_id])

  
  useEffect(() => {
    removeLocalDep()
    type==="EDIT" && getCurrentYunarmyHouse(itemId)
  }, []);

  useEffect(() => {
    if (type==="ADD") {
      setItem({domestic_department_id: "", chief: {}})
      setFilesList([])
    } else {
      if (currentItem) {
        setItem(currentItem)
        setFilesList(currentItem.files)
      }
    }
  }, [type, currentItem])

  
useEffect(() => {
    if (regionDepId && regionDepId != 0) {
      getDepartmentsByRegion(regionDepId);
    }
  }, [regionDepId])

  const handleAddYunarmyHouse = async () => {
    let errorField;

    (!item?.domestic_department_id || item.domestic_department_id =='0') && depsByReg?.length && ( errorField = '`Местное отделение` является обязательным');
    (!item.region_id || item.region_id == '0') && ( errorField = '`Регион` является обязательным' );
    !item.name && ( errorField = 'Название` является обязательным');
    
    if (errorField) {
      createNotification('error', `Поле '${errorField}'`)
    } else  {
      const response = await addYunarmyHouse(item, addFilesList, page + 1, byReg);
      response && props.onClose();
    } 
  };

  const handleEditYunarmyHouse = async () => {
    let errorField;
    (!item?.domestic_department_id || item.domestic_department_id =='0') && depsByReg?.length && ( errorField = '`Местное отделение` является обязательным');
    (!item.region_id || item.region_id == '0') && ( errorField = '`Регион` является обязательным' );
    !item.name && ( errorField = 'Название` является обязательным');
    
    if (errorField) {
      createNotification('error', `Поле ${errorField}`)
    } else  {
      const response = await editYunarmyHouse(item, addFilesList, removeFiles, page + 1, byReg);
      response && props.onClose();
    }
  };


  const handleChangeInput = (e) => {
    
    const value = e.target;
    const obj = JSON.parse(JSON.stringify(item));

    if (value?.name?.split('.')[0] === 'chief') {
      const chief_value = value.name.split('.')[1];
      obj.chief[chief_value] = value.value;  
    } else {
      obj[value.name] = value.value;
    }
    setItem(obj);

  }

  const handleSetRegionDepId = (e) => {
    const regionDepId = e.target.value;
    setRegionDepId(regionDepId);
    setDomDepId(null);
    setItem(prev => ({...prev, region_id: regionDepId, domestic_department_id: ''}));
  }

  const handleSetDomesticDepId = (e) => {    
    const domDepId = e.target.value;
    const regionId = depsByReg.find(dep => dep.id==domDepId)?.id;
    setDomDepId(regionId);
    setItem(prev => ({...prev, domestic_department_id: regionId}));
  }

  const handleUploadFile = (e) => {
    if (e.target.files) {
      const files = Object.values(e.target.files).map((item) => ({
        file: item,
        description: "",
      }));
      setAddFilesList(files);
    }
  };

  const handleDeleteFile = (ind) => {
    const name = filesList[ind].name;
    setConfirmation({ name: name, index: ind });
  };

  const deleteCurrentFile = (ind) => {
    const arr = [...addFilesList];
    arr.splice(ind, 1);
    setAddFilesList(arr);
  };

  const acceptConfirmation = (name) => {
    const arr = [...filesList];
    const id = arr[confirmation.index].id
    arr.splice(confirmation.index, 1);
    setRemoveFiles((prev) => [...prev, id]);
    setFilesList(arr);
    setConfirmation(null);
  };

  const rejectConfirmation = () => {
    setConfirmation(null);
  };


  return (
    <Modal
      {...props}
      classNames={{
        modal: styles.modal,
        closeButton: styles.closeBtn,
      }}
    >
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation.name}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <div className={styles.title}>
        {type === "ADD" ? "Добавление дома" : "Редактирование дома"}
      </div>
      {item && (
        <div className={styles.modalBody}>
          {loading ?
          <div className={styles.modalMain}>
            <Loader top="30%" left="46%"/>
          </div>
           :
          <div className={styles.modalMain}>
            <div className={styles.wrapperBlock}>
            <CustomInput
              value={item.name}
              onChange={handleChangeInput}
              disabled={!updateRights}
              name="name"
              title="Название"
            />
            <CustomSelect
              selwidth="100%"
              onChange={handleSetRegionDepId}
              disabled={!updateRights}
              selectedValue={item.region_id}
              options={regions ? regions.map(it => ({name: it.regionName + ' ' + it.federalUnit, value: it.id})) : []}
              title="Регион"
              name="id"
            />
            <CustomSelect
              selwidth="100%"
              onChange={handleSetDomesticDepId}
              disabled={!updateRights}
              selectedValue={item?.domestic_department_id}
              options={(item?.region_id && depsByReg) ? depsByReg.map(dep => ({name: dep.name, value: dep.id})) : []}
              // validate={validate}
              // onFocus={() => setValidate(false)}
              title="Местное отделение"
              name="parent"
            />
            <CustomInput
              value={item.placement}
              onChange={handleChangeInput}
              disabled={!updateRights}
              name="placement"
              title="Базовая организация размещения"
            />
            
            </div>
          </div>}
          <div className={styles.wrapperDate}>
                  <div><CustomDatepicker
                    title="Дата создания"
                    value={item.created || ""}
                    name="created"
                    readOnly={!updateRights}
                    onChange={handleChangeInput}
                  />
                  </div>
                  <div><CustomDatepicker
                    title="Дата обновления"
                    value={item.updated || ""}
                    readOnly={!updateRights}
                    name="updated"
                    onChange={handleChangeInput}
                  />
                  </div>
                  <div><CustomInput
                    value={item.status_year || ""}
                    onChange={handleChangeInput}
                    onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
                    disabled={!updateRights}
                    name="status_year"
                    // type='number'
                    title="Год присвоения статуса"
                  />                
                  </div>
                  <div><CustomInput
                    value={item.protocol_gsh || ""}
                    onChange={handleChangeInput}
                    disabled={!updateRights}
                    name="protocol_gsh"
                    title="Протокол заседания ГШ"
                  />
                  </div>
            </div>
              
            <div className={styles.wrapperContact}>
              <CustomInput
                value={item.address || ""}
                onChange={handleChangeInput}
                disabled={!updateRights}
                name="address"
                title="Адрес размещения"
              />
              <div className={styles.wrapperContactInner}>
              <CustomInput
                value={item.city || ""}
                onChange={handleChangeInput}
                disabled={!updateRights}
                name="city"
                title="Город размещения"
              />
              <CustomInput
                value={item.phone || ""}
                onChange={handleChangeInput}
                disabled={!updateRights}
                onKeyPress={(event) => !/[+-. ()0-9]/.test(event.key) && event.preventDefault()}
                name="phone"
                title="Телефон"
              />
              </div>
              <div className={styles.wrapperContactInner}>
              <CustomInput
                value={item.email || ""}
                onChange={handleChangeInput}
                disabled={!updateRights}
                name="email"
                title="E-mail"
                type="e-mail"
              />
              <CustomInput
                value={item.website || ""}
                onChange={handleChangeInput}
                disabled={!updateRights}
                name="website"
                title="Сайт"
              />
              </div>
            </div>

            <div className={styles.wrapperСhief}>
              <CustomInput
                value={item.chief?.fio || ""}
                onChange={handleChangeInput}
                disabled={!updateRights}
                name="chief.fio"
                title="Фамилия Имя Отчество"
              />
              <CustomInput
                value={item.chief?.position || ""}
                onChange={handleChangeInput}
                disabled={!updateRights}
                name="chief.position"
                title="Должность"
              />
              <div className={styles.wrapperСhiefInner}>
                <CustomInput
                  value={item.chief?.email || ""}
                  onChange={handleChangeInput}
                  disabled={!updateRights}
                  name="chief.email"
                  title="E-mail"
                />
                <CustomInput
                  value={item.chief?.phone || ""}
                  onChange={handleChangeInput}
                  disabled={!updateRights}
                  onKeyPress={(event) => !/[+-. ()0-9]/.test(event.key) && event.preventDefault()}
                  name="chief.phone"
                  title="Телефон"
                />
              </div>
            </div>

            <div className={styles.wrapperCount}>
                <CustomInput
                  value={item.employees_count || ""}
                  onChange={handleChangeInput}
                  disabled={!updateRights}
                  name="employees_count"
                  title="Количество работников"
                  type="number"
                /> 
                <CustomInput
                  value={item.yunarmians_count || ""}
                  onChange={handleChangeInput}
                  disabled={!updateRights}
                  name="yunarmians_count"
                  title="Количество юнармейцев"
                  type="number"
                />
                <CustomInput
                  value={item.associations_count || ""}
                  onChange={handleChangeInput}
                  disabled={!updateRights}
                  name="associations_count"
                  title="Количество объединений"
                  type="number"
                />
            </div>

              <div className={styles.filesBlock}>
              {(type !== "ADD" && filesList && filesList.length > 0 && filesList[0].id) ? (
                <>
                  {filesList?.map((it, i) => (
                    <div key={i} className={styles.fileItem}>
                      <a
                        href={`${BACKEND_DOMAIN || ""}/${it.path}/${it.name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{width: '100%', display: 'block'}}
                      >
                        {it.name}
                      </a>
                      {updateRights && <IoMdClose onClick={() => handleDeleteFile(i)}/>}
                    </div>
                  ))}
              </>
              )
              :
                    <></>
              }
              </div>
              <div className={styles.filesBlock}>
                { (addFilesList && addFilesList.length > 0) ? 
                    addFilesList?.map((item, i) => (
                      <div key={i} className={styles.fileItem}>
                        {item.file.name}
                      <IoMdClose onClick={() => deleteCurrentFile(i)}/>
                      </div>
                  ))
                :
                <></>}
              </div>
              <div className={styles.inputBlock}>
                <InputFile
                  multiple
                  disabled={!updateRights}
                  accept='application/pdf, application/x-pdf, image/jpeg, image/png, image/svg+xml'
                  title="Выберите файл для загрузки"
                  onChange={(e) => handleUploadFile(e)}
                />
              </div>

          <div className={styles.modalButtons}>
            {updateRights &&
            <ButtonRed
              onClick={type === "ADD" ? handleAddYunarmyHouse : handleEditYunarmyHouse}
            >
              {type === "ADD" ? "Добавить" : "Сохранить"}
            </ButtonRed>}
            <ButtonRed onClick={props.onClose}>Закрыть</ButtonRed>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default YunarmyHousesModal;