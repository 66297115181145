import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styles from './Badges.module.css'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Navigation, FreeMode, Thumbs } from 'swiper';
import BadgeModal from './BadgeModal';
import { getBadges } from '../../actions/badges'
import { BACKEND_DOMAIN } from '../../utils/const';
import { IoMdCreate, IoMdAddCircleOutline } from "react-icons/io";




export default function Badges({
  badgeForPrint,
  setBadgeForPrint,
  fromUnarmians
}) {
  
  const [currentBage, setCurrentBage] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBadges())
  }, [])
  
  const { badges } = useSelector(state => state.badges)


  const slideClick = (bage) => {
    if (fromUnarmians) {
      setBadgeForPrint(bage)
    } else {
      setCurrentBage(bage)
    }
  }

  const editIconClick = (e, bage) => {
    e.stopPropagation()
    setCurrentBage(bage)
    badgeForPrint && setBadgeForPrint(null)
  } 

  const defaultBadge = {
    id: 0,
    conf: {
        components: [
        ],
        background: "/bg_white.jpg"
    }
}

  return (
    <>
      <Swiper
        // spaceBetween={200}
        slidesPerView={3}
        modules={[FreeMode, Navigation, Thumbs]}
        navigation={true}
        loop={true}
        onSwiper={(swiper) => swiper.slideTo(3)}
        style={{padding: '1.5rem 0'}}

      >
        {badges && [defaultBadge, ...badges].map(bage => {
          const withBorder = badgeForPrint?.id===bage.id
          return (
            
          <>
          {bage.id == 0
          
          ? <div className={styles.slideWrapper}>
            <SwiperSlide 
              key={bage.id}
              isActive={true} 
              >
              <div
                  onClick={(e) => editIconClick(e, bage)}
                  className={`${styles.slideWrap} ${styles.slideWrapNew} ${fromUnarmians ? styles.slidefromUnarmians : ''}`}
                  style={{border: withBorder ? '2px solid red' : null}}
              >
                <div
                  style={{
                    width: fromUnarmians ? '250px' : '350px',
                    height: fromUnarmians ? '357.1px' : '500px'
                  }}
                  className={styles.newBadge}
                >
                  <i><IoMdAddCircleOutline/></i>
                </div>
              </div>
            </SwiperSlide>
          </div>
          : <div className={styles.slideWrapper}>
              <SwiperSlide
                  key={bage.id}
                  // onClick={() => slideClick(bage)}
                >
                  <div
                      className={`${styles.slideWrap} ${fromUnarmians ? styles.slidefromUnarmians : ''}`}
                      onClick={() => slideClick(bage)}
                      style={withBorder ? {
                        border: '2px solid red',
                        boxShadow: '0 0 20px grey'
                      } : null}
                    >
                      <img
                        style={{
                          width: fromUnarmians ? "250px" : "350px",
                          margin: "0 auto"
                        }}
                        src={`${BACKEND_DOMAIN || ''}/${bage.preview?.path}/${bage.preview?.name}`}
                        alt='o_O'
                      />
                      {fromUnarmians && <IoMdCreate className={styles.createButton} onClick={(e) => editIconClick(e, bage)} />}
                  </div>
                </SwiperSlide>
                </div>
            }
          </>

            )})}
        
      </Swiper>
      {currentBage &&
      <BadgeModal
        data={currentBage}
        closeModal={() => setCurrentBage(null)}
      />}
    </>
  )
}
