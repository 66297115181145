import * as types from '../actions/types';

const initialState = {
    count: null,
    page_count: null,
    data: null,
    loading : false,
    articles: null,
    steps_data: null,
    steps_count: null,
    steps_page_count: null,
    searchData: null,
    usersList: null,
    regionList: null,
  };

export default function regulation (state = initialState, action) {
  switch (action.type) {
    case types.SET_REGULATION_COUNT:
      return {
        ...state,
        count: action.payload.data,
        page_count: action.payload.pageCount
      };
      case types.SET_REGULATION_DATA:
      return {
        ...state,
        data: action.payload.article_documents,
      };
      case types.SET_REGULATION_ARTICLES:
      return {
        ...state,
        articles: action.payload,
      };
      case types.SET_STEPS_COUNT:
      return {
        ...state,
        steps_count: action.payload.data,
        steps_page_count: action.payload.pageCount
      };
      case types.SET_STEPS_DATA:
      return {
        ...state,
        steps_data: action.payload,
      };
      case types.SET_REGULATION_SEARCH:
      return {
        ...state,
        searchData: action.payload,
      };
      case types.SET_REGIONS_LIST_REGULATION:
      return {
        ...state,
        regionList: action.payload,
      };
      case types.SET_REGULATION_USER_LIST:
      return {
        ...state,
        usersList: action.payload,
      };
    default:
      return state;
  }
}