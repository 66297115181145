import { useEffect } from "react"



export const useOnClickOutside = (elementRef, callBack) => {
  
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const path = event.path || (event.composedPath && event.composedPath());
      if (elementRef.current && !path.includes(elementRef.current)) {
        callBack()
      }
    };
    document.addEventListener('click', handleOutsideClick)
  
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [callBack])
  
}